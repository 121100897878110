import { http, HttpResponse } from "msw";
import { MOCK_GROUP_ID, MOCK_ENTITY_ID, MOCK_TRANSACTION_ID } from "./group";
import { baseURL } from "store/apis/emptyApi";

export const entityTransactions = {
  data: {
    transactions: [
      {
        transaction: {
          uuid: "8df8f484-540b-4305-b6d2-7d559f1deaea",
          merchant: "Udemy",
          merchant_uuid: "0c66fde5-9e06-49e7-a760-ddb9167ab622",
          amount: 500.0,
          category: null,
          suggested_categories: [
            {
              uuid: "c2d33473-0557-4be9-b511-0f0b846dfac6",
              name: "Other Business Expenses",
              identifier: 52800,
            },
            {
              uuid: "eeb5952f-d7fc-43cd-bfa6-b1a86c7e459b",
              name: "Dues & subscriptions",
              identifier: 520080,
            },
          ],
          description: "Tectra Inc",
          date: "2024-04-04",
          datetime: null,
          state: "ACTIVE",
          status: "FOR_REVIEW",
          memo: null,
          pending: false,
          logo: null,
          from: {
            bank_account: {
              uuid: "6fa01425-b74c-40c5-96c0-aa52027a3b5d",
              nickname: "Plaid Credit Card",
              mask: "3333",
              bank_brand: {
                name: "First National Bank (Manning, IA)",
                logo_url:
                  "https://inkle-django-files.s3.ap-south-1.amazonaws.com/first_national_bank_(manning,_ia)_logo.png",
              },
            },
            source: "PLAID",
          },
          is_duplicate: false,
          is_missing: false,
          info_requested: false,
          journal_entry: false,
          added_from_csv: false,
          ledger_entry: null,
          linked_transaction: null,
          is_credit_card: true,
          categorisation_status: "NOT_CATEGORISED",
        },
        running_balance: null,
        current_balance: null,
        txn_merchant: null,
        tags: [],
        invoices: [
          {
            uuid: "8df8f484-540b-4305-b6d2-7d559f1deaeaasd",
            file_data: {
              uuid: "alkdjalskdjj",
              invoice_status: "AUTO_MATCHED",
              invoice_uuid: "alkdjlaskjdlasjdajsdljsa",
              name: "New invoice",
              is_previewable: true,
              file_type: "PDF",
            },
          },
          {
            uuid: "8df8f484-540b-4305-b6d2-7d559f1deaed",
            file_data: {
              uuid: "alkdjalskdjj",
              invoice_status: "AUTO_MATCH_CONFIRM",
              invoice_uuid: "alkdjlaskjdlasjdajsdljlasdsa",
              name: "New invoice automatch confirmed",
              is_previewable: true,
              file_type: "PDF",
            },
          },
          {
            uuid: "8df8f484-540b--b6d2-7d559f1deaed",
            file_data: {
              uuid: "alkdjalskdjj-1",
              name: "New invoice automatch confirmed",
              is_previewable: true,
              file_type: "PDF",
            },
          },
        ],
      },
      {
        transaction: {
          uuid: "617077c0-9637-4363-8b4a-44aacfb14cef",
          merchant: "LAZ Parking",
          merchant_uuid: "104314c1-d48a-4b5c-9c90-2249794d6eee",
          amount: 2078.5,
          category: null,
          suggested_categories: [
            {
              uuid: "b0f6f7ab-272f-44dc-87d1-fddc0bad5881",
              name: "Parking and Tolls",
              identifier: 53070,
            },
            {
              uuid: "59b3c96a-48a6-4b2c-9553-33abfee589b9",
              name: "Other Expenses",
              identifier: 53000,
            },
          ],
          description: "AUTOMATIC PAYMENT - THANK",
          date: "2024-04-03",
          datetime: null,
          state: "ACTIVE",
          status: "FOR_REVIEW",
          memo: null,
          pending: false,
          logo: null,
          from: {
            bank_account: {
              uuid: "6fa01425-b74c-40c5-96c0-aa52027a3b5d",
              nickname: "Plaid Credit Card",
              mask: "3333",
              bank_brand: {
                name: "First National Bank (Manning, IA)",
                logo_url:
                  "https://inkle-django-files.s3.ap-south-1.amazonaws.com/first_national_bank_(manning,_ia)_logo.png",
              },
            },
            source: "PLAID",
          },
          is_duplicate: false,
          is_missing: false,
          info_requested: false,
          journal_entry: false,
          added_from_csv: false,
          ledger_entry: null,
          linked_transaction: null,
          is_credit_card: true,
          categorisation_status: "NOT_CATEGORISED",
        },
        running_balance: null,
        current_balance: null,
        txn_merchant: null,
        tags: [],
        invoices: [],
      },
    ],
    total_pages: 21,
    current_page: 1,
    total_count: 410,
    per_page: 20,
    channel_url: "e0952e41-b78f-46d4-83ee-ab74b4706109",
    private_channel_url: "bd8d43ac-7814-495c-85e6-7dfd15f459b0",
  },
};

export const splitTransactions = {
  data: [
    {
      uuid: "9f415d39-8228-4912-bb00-d9ffbf6a8546",
      merchant: "one plus",
      merchant_uuid: "080f09eb-4ab5-479a-877b-dc00e9a9579e",
      amount: 300,
      category: {
        uuid: "fbf2efbf-4ca1-40f4-97a8-c52c07990c95",
        name: "Accounts receivable (A/R)",
      },
      description: "Test 2",
      status: "FOR_REVIEW",
      logo: null,
      created_by: "Ankita Bansal",
      linked_transaction: null,
      invoice: null,
      entity_transaction_id: "8df8f484-540b-4305-b6d2-7d559f1deaea",
    },
    {
      uuid: "da999454-33eb-405f-bc0e-a021109cafb8",
      merchant: "one plus",
      merchant_uuid: "080f09eb-4ab5-479a-877b-dc00e9a9579e",
      amount: 200,
      category: {
        uuid: "d86c171d-e1d7-45aa-a63e-62266d0a22a4",
        name: "Accounts receivable (A/R)",
      },
      description: "Test 1",
      status: "FOR_REVIEW",
      logo: null,
      created_by: "Ankita Bansal",
      linked_transaction: null,
      invoice: null,
      entity_transaction_id: "8df8f484-540b-4305-b6d2-7d559f1deaea",
    },
  ],
};

export const exportTransaction = {
  preview_url:
    "https://s3.ap-south-1.amazonaws.com/io.inkle.documents/Inkle%20%2B%20Random%20Ladka_79b9b5fd2a164aba92a6e904f39ad29b/Unsorted/736a67e775d6450dbc325f3636b4acf6.csv?response-content-disposition=inline%3B%20filename%3DUS_Entity_2_01-January-2024_25-June-2024.csv&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2DYKEDBBVTOKXTNM%2F20240626%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240626T132319Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=807b4426316f6989636fc4487d3d9a5239a6a8363db4bd7bb65d81abc540040f",
  download_url:
    "https://s3.ap-south-1.amazonaws.com/io.inkle.documents/Inkle%20%2B%20Random%20Ladka_79b9b5fd2a164aba92a6e904f39ad29b/Unsorted/736a67e775d6450dbc325f3636b4acf6.csv?response-content-disposition=attachment%3B%20filename%3DUS_Entity_2_01-January-2024_25-June-2024.csv&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2DYKEDBBVTOKXTNM%2F20240626%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240626T132319Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=9f54fbfb8fecca7fd534d9f91212347bc215bf7fe90aba5323e308967ece1924",
  timestamp: "2024-06-26T13:23:14.638982",
};

export const transactionTableHandlers = [
  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/entity_transactions/`,
    async () => {
      return HttpResponse.json(entityTransactions);
    }
  ),
  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/entity_transaction/*`,
    async () => {
      return HttpResponse.json({
        data: entityTransactions.data.transactions[0],
      });
    }
  ),
  http.patch(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/entity_transaction/*/`,
    async () => {
      return HttpResponse.json({
        data: entityTransactions.data.transactions[0],
      });
    }
  ),
  http.delete(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/transaction/*/invoice/`,
    async () => {
      return HttpResponse.json({
        data: entityTransactions.data.transactions[0],
      });
    }
  ),
  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/transaction/${MOCK_TRANSACTION_ID}/split_transactions/`,
    async () => {
      return HttpResponse.json(splitTransactions);
    }
  ),

  http.put(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/entity_transactions/`,
    () => {
      return HttpResponse.json(entityTransactions);
    }
  ),

  http.get(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/entity_transactions/txn_export/`,
    async () => {
      return HttpResponse.json(exportTransaction);
    }
  ),
  http.put(
    `${baseURL}api/inkle/bookkeeping/group/${MOCK_GROUP_ID}/entity/${MOCK_ENTITY_ID}/refresh_transactions/`,
    async () => {
      return HttpResponse.json();
    }
  ),
];
