import { stringify } from "qs";
import { Pagination } from "types/Models/pagination";
import { ProductAndServices } from "types/Models/productAndServices";
import { emptyApi } from "./emptyApi";

const productAndServicesApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getProductAndServices: build.query<
      Pagination & {
        products: ProductAndServices[];
        revenue_category_id: string;
      },
      {
        groupId: string;
        entityId: string;
        pageNum?: number;
        searchTerm?: string | null;
        sources?: string;
        productTypes?: string;
        productCategories?: string;
        sortCol?: "PRICE" | "NAME";
        sortOrder?: "ASC" | "DSC";
      }
    >({
      query: ({
        groupId,
        entityId,
        pageNum,
        searchTerm,
        sources,
        productTypes,
        productCategories,
        sortCol,
        sortOrder,
      }) => {
        const query = stringify(
          {
            page_num: pageNum,
            search_term: searchTerm || null,
            sources: sources || null,
            product_types: productTypes || null,
            product_categories: productCategories || null,
            sort_col: sortCol || null,
            sort_order: sortOrder || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products/${query}`,
        };
      },
      providesTags: (res) => (res ? [{ type: "PRODUCTS_AND_SERVICES" }] : []),
    }),

    addProductAndService: build.mutation<
      ProductAndServices,
      {
        groupId: string;
        entityId: string;
        payload: {
          name: string;
          price: number;
          product_category: string;
          transaction_category_id: string;
          type: "PRODUCT" | "SERVICE";
        };
      }
    >({
      query: ({ groupId, entityId, payload }) => ({
        url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products/`,
        body: payload,
        method: "POST",
      }),
      invalidatesTags: (res) =>
        res ? [{ type: "PRODUCTS_AND_SERVICES" }] : [],
    }),

    getProductAndServiceById: build.query<
      ProductAndServices,
      { groupId: string; entityId: string; productId: string }
    >({
      query: ({ groupId, entityId, productId }) => ({
        url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products/${productId}/`,
      }),
      providesTags: (res) => (res ? [{ type: "PRODUCTS_AND_SERVICES" }] : []),
    }),

    updateProductAndService: build.mutation<
      ProductAndServices,
      {
        groupId: string;
        entityId: string;
        productId: string;
        payload: {
          name?: string;
          price?: number;
          product_category?: string;
          transaction_category_id?: string;
          type?: "PRODUCT" | "SERVICE";
        };
      }
    >({
      query: ({ groupId, entityId, productId, payload }) => ({
        url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products/${productId}/`,
        body: payload,
        method: "PATCH",
      }),
      invalidatesTags: (res) =>
        res ? [{ type: "PRODUCTS_AND_SERVICES" }] : [],
    }),

    deleteProductAndService: build.mutation<
      ProductAndServices,
      {
        groupId: string;
        entityId: string;
        productId: string;
      }
    >({
      query: ({ groupId, entityId, productId }) => ({
        url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products/${productId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (res) =>
        res ? [{ type: "PRODUCTS_AND_SERVICES" }] : [],
    }),

    getProductCategories: build.query<
      { name: string; type: string; uuid: string }[],
      void
    >({
      query: () => {
        return {
          url: `/api/inkle/invoicing/product_categories/`,
        };
      },
      providesTags: (res) => (res ? [{ type: "PRODUCTS_AND_SERVICES" }] : []),
    }),

    getGroupByProductsServices: build.query<
      { products: ProductAndServices[]; services: ProductAndServices[] },
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/products_by_type/`,
        };
      },
      providesTags: (res) => (res ? [{ type: "PRODUCTS_AND_SERVICES" }] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductAndServicesQuery,
  useAddProductAndServiceMutation,
  useGetProductAndServiceByIdQuery,
  useUpdateProductAndServiceMutation,
  useDeleteProductAndServiceMutation,
  useGetProductCategoriesQuery,
  useGetGroupByProductsServicesQuery,
} = productAndServicesApi;
