import Async from "components/DesignSystem/AsyncComponents/Async";
import { Form, Formik } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import isEqual from "lodash/isEqual";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import {
  useEditEntityDataAttributesMutation,
  useGetEntityDataAttributesQuery,
} from "store/apis/entity";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { BasicInfo } from "./BasicInfo";
import { EntityShareholder } from "./EntityShareholder";
import { EntityStateRegistrations } from "./EntityStateRegistrations";
import { UploadEntityDocs } from "./UploadEntityDocs";

export const OnboardingEntityDetails = ({ entityId }: { entityId: string }) => {
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const { successToast, alertToast } = useToast();
  const entity = entities.find(({ uuid }) => uuid == entityId);

  const [editEntityDataAttributes, { isLoading: isUpdating }] =
    useEditEntityDataAttributesMutation();

  const {
    data: currentEntityDetails = [],
    isLoading,
    isSuccess,
  } = useGetEntityDataAttributesQuery(
    {
      groupId: groupId!,
      entityId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const initialValues = currentEntityDetails.reduce((acc, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {} as Record<string, EntityDataAttribute["value"]>);

  const onChange = debounce(
    async ({
      name,
      value,
    }: {
      name: string;
      value: EntityDataAttribute["value"];
    }) => {
      const payload = { [name]: value };
      const initialValue = initialValues[name];

      const isSameValue = isEqual(
        JSON.stringify(value),
        JSON.stringify(initialValue)
      );

      if (isSameValue) {
        return;
      }

      try {
        await editEntityDataAttributes({
          groupId,
          entityId,
          payload,
        }).unwrap();
        successToast({ message: "Entity updated successfully" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  );

  const isEmpty = currentEntityDetails?.length === 0;

  return (
    <div className="t-mx-6">
      {/* country */}
      <div className="t-flex t-gap-2 t-items-center">
        <ReactCountryFlag
          svg
          countryCode={entity?.country_code!}
          style={{
            width: "1.5em",
            height: "1em",
          }}
        />
        <div className="t-text-h5">{entity?.name}</div>
      </div>
      <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <div className="t-w-full">
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              enableReinitialize
            >
              <Form className="t-py-6 t-space-y-6">
                <BasicInfo
                  currentEntityDetails={currentEntityDetails}
                  onChange={onChange}
                />
                <UploadEntityDocs
                  currentEntityDetails={currentEntityDetails}
                  onChange={onChange}
                />
                <EntityStateRegistrations entityId={entityId} />
                <EntityShareholder entityId={entityId} />
              </Form>
            </Formik>
          </div>
        </Async.Success>
      </Async.Root>
    </div>
  );
};
