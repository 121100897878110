import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { AddShareholder } from "components/Entity/Shareholders/AddShareholder";
import { Cross } from "components/icons/Cross";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import GrayPencil from "static/images/GrayPencil.svg";
import RedInfo from "static/images/RedFilledInfo.svg";
import { useGetAllShareholdersQuery } from "store/apis/Shareholders";
import { useUpdateTaskFormDataMutation } from "store/apis/task";
import { SectionField } from "types/Models/Filing";
import { Shareholder } from "types/Models/Shareholders";
import { BackendError } from "types/utils/error";
import { DeleteFilingAutofill } from "./DeleteFilingAutofill";
import { FilingInfo } from "./FilingReviewSectionDisplay";
import { SelectAutofillProps } from "./SelectAutofill";

type ShareholderAutofillKey = "shareholder_details";

export const ShareholderType = {
  COMPANY_ENTITY: "Entity",
  PERSONA: "Individual",
};

const ShareholderAutofill = ({
  section,
  groupId,
  entityId,
  formData,
  autofillKey,
  field,
  keysToFill,
  deleteModalTitle,
  addNewAutofillText,
  comboboxName,
  currentAutofillDetailKey,
  shouldShowDetailsCard,
  isAnyDetailsMissing,
  AutofillCreateEdit,
  autofillSuffix,
  repeatableFieldData,
  fields,
}: SelectAutofillProps) => {
  const addModal = useModal();
  const deleteModal = useModal();
  const editModal = useModal();
  const { data: shareholders = [] } = useGetAllShareholdersQuery(
    { entityId, groupId },
    { skip: !groupId || !entityId }
  );

  const { alertToast, successToast } = useToast();
  const [updateTaskForm, { isLoading: isUpdatingTask }] =
    useUpdateTaskFormDataMutation();
  const { taskId } = useParams<{ taskId: string }>();
  const [detailsForEdit, setDetailsForEdit] = useState<Shareholder | null>(
    null
  );

  const currentSelectedAutofillDetails = autofillSuffix
    ? repeatableFieldData?.selected_autofill_details?.[
        currentAutofillDetailKey as ShareholderAutofillKey
      ]
    : section.selected_autofill_details?.[
        currentAutofillDetailKey as ShareholderAutofillKey
      ];

  const getUpdatedField = (
    fieldKey: string,
    field: SectionField,
    values: any
  ) => {
    let keyToFill = keysToFill.find((key) => fieldKey?.includes(key));

    if (keyToFill) {
      if (typeof values[keyToFill] !== "object") {
        return { ...field, value: values[keyToFill] };
      }
      if (keyToFill === "address") {
        return { ...field, value: values[keyToFill].autofill_string };
      }
      if (keyToFill === "country") {
        return { ...field, value: values[keyToFill].name };
      }
    }

    return field;
  };

  const getRepeatableUpdatedField = (
    fieldKey: string,
    field: SectionField,
    values: any
  ) => {
    let keysToFillWithoutSuffix = keysToFill.map((autofillKey) =>
      autofillKey?.replace(`_${autofillSuffix}`, "")
    );
    let keyToFill = keysToFillWithoutSuffix.find((autofillKey, i) => {
      return autofillKey != "" && fieldKey.includes(autofillKey);
    });

    if (keyToFill) {
      if (typeof values[keyToFill] !== "object") {
        return { ...field, value: values[keyToFill] };
      }
      if (keyToFill === "address") {
        return { ...field, value: values[keyToFill].autofill_string };
      }
      if (keyToFill === "country") {
        return { ...field, value: values[keyToFill].name };
      }
    }

    return field;
  };

  const updateAutofillSection = (updatedFieldsWithValue: {
    section_fields: {}[];
  }) => {
    return formData?.data?.data?.section_group?.[0].sections.map(
      (currentSection: { section_key: string }) => {
        if (currentSection.section_key === section.section_key) {
          return updatedFieldsWithValue;
        }
        return currentSection;
      }
    );
  };

  const getPayload = (updatedSections: any) => {
    const payload = {
      form_data: {
        ...formData,
        data: {
          ...formData.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
    };
    return payload;
  };

  const updateTaskDetails = async (
    autofill: Shareholder | { [x: string]: string }[],
    selectedAutofillDetails?: any
  ) => {
    try {
      let updatedFieldWithValue: any = {};

      // repeatable field logic
      if (autofillSuffix) {
        updatedFieldWithValue = {
          ...section,
          repeatable_fields: section.repeatable_fields?.map(
            (repeatableField) => {
              if (repeatableField.id != Number(autofillSuffix)) {
                return repeatableField;
              }
              return {
                ...repeatableField,
                fields: repeatableField?.fields?.map((field: SectionField) => {
                  return getRepeatableUpdatedField(
                    field.field_key,
                    field,
                    autofill
                  );
                }),
                selected_autofill_details: {
                  ...repeatableFieldData.selected_autofill_details,
                  [currentAutofillDetailKey]:
                    selectedAutofillDetails || autofill,
                },
              };
            }
          ),
        };
      } else {
        updatedFieldWithValue = {
          ...section,
          selected_autofill_details: {
            ...section.selected_autofill_details,
            [currentAutofillDetailKey]: selectedAutofillDetails || autofill,
          },
          section_fields: section.section_fields?.map((field: SectionField) => {
            return getUpdatedField(field.field_key, field, autofill);
          }),
        };
      }
      const updatedSections = updateAutofillSection(updatedFieldWithValue);
      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
      successToast({ message: "Task updated successfully!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const updateTaskSection = async (
    autofillValue: SingleValue<OptionData> | MultiValue<OptionData> = []
  ) => {
    try {
      const selectedAutofill = (autofillValue as SingleValue<OptionData>)
        ?.value;
      const valuesToSet = shareholders.find(
        ({ uuid }) => uuid === selectedAutofill
      );

      await updateTaskDetails(valuesToSet!);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const valuesToSet = keysToFill.map((key) => ({ [key]: "" }));
      await updateTaskDetails(valuesToSet, {});
      deleteModal.close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      deleteModal.close();
    }
  };

  const openEdit = (autofillDetails: Shareholder) => {
    setDetailsForEdit(autofillDetails);
    editModal.open();
  };

  const onClose = () => {
    setDetailsForEdit(null);
    addModal.close();
    editModal.close();
  };

  const defaultSelected = currentSelectedAutofillDetails?.uuid
    ? {
        label: (
          <div className="t-text-body t-w-full t-truncate">
            {currentSelectedAutofillDetails?.name}
          </div>
        ),
        value: currentSelectedAutofillDetails?.uuid!,
      }
    : null;

  return (
    <div>
      <Formik
        validateOnChange={false}
        enableReinitialize
        initialValues={{}}
        onSubmit={() => {}}
      >
        <Form className="t-m-0 t-mb-6">
          <Combobox
            actions={
              <Button customType="link" onClick={addModal.open}>
                {addNewAutofillText}
              </Button>
            }
            components={{ ClearIndicator: () => null }}
            menuPortalTarget={document.body}
            onChange={updateTaskSection}
            value={defaultSelected}
            withForm
            name={comboboxName}
            label={field.field_label}
            placeholder={field.placeholder}
            options={shareholders.map((shareholder) => {
              return {
                label: (
                  <div className="t-text-body t-w-full t-truncate">
                    {shareholder.name}
                  </div>
                ),
                value: shareholder.uuid,
              };
            })}
            isLoading={isUpdatingTask}
          />
        </Form>
      </Formik>
      {shouldShowDetailsCard && (
        <div className="t-mb-3">
          <div
            className={classNames(
              "t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg",
              {
                "t-border-red-30": isAnyDetailsMissing,
              }
            )}
          >
            <div className="t-flex t-justify-between">
              <div className="t-grid t-gap-x-20 t-gap-y-4 t-grid-cols-2 t-w-4/5">
                {keysToFill
                  .filter((keyToFill) => !keyToFill.includes("autofill"))
                  ?.map((keyToFill, index) => {
                    const fieldData = autofillSuffix
                      ? repeatableFieldData?.fields?.find(
                          (field: SectionField) =>
                            field.field_key?.includes(keyToFill)
                        )
                      : fields?.find((field: SectionField) =>
                          field.field_key?.includes(keyToFill)
                        );

                    if (
                      !fieldData ||
                      fieldData?.type?.includes("autofill") ||
                      fieldData?.field_key?.includes("last_name")
                    ) {
                      return null;
                    }

                    if (fieldData?.field_key?.includes("first_name")) {
                      const lastName = autofillSuffix
                        ? repeatableFieldData?.fields?.find(
                            (field: SectionField) =>
                              field.field_key?.includes("last_name")
                          )?.value
                        : fields?.find((field: SectionField) =>
                            field.field_key?.includes("last_name")
                          )?.value;

                      return (
                        <FilingInfo
                          key={index}
                          label="Full Name"
                          value={fieldData.value + " " + lastName}
                        />
                      );
                    }

                    if (
                      fieldData?.field_key?.includes("shareholder_type") &&
                      fieldData.value
                    ) {
                      return (
                        <FilingInfo
                          key={index}
                          label={fieldData.field_label}
                          value={
                            ShareholderType[
                              fieldData.value as keyof typeof ShareholderType
                            ]
                          }
                        />
                      );
                    }

                    return (
                      <FilingInfo
                        key={index}
                        label={fieldData.field_label}
                        value={fieldData.value}
                      />
                    );
                  })}
              </div>
              <div className="t-flex t-gap-2">
                <Button
                  onClick={() => openEdit(currentSelectedAutofillDetails!)}
                  customType="transparent"
                  size="small"
                  type="button"
                >
                  <img
                    src={GrayPencil}
                    className="t-h-4 t-m-1"
                    alt="GrayPencil"
                  />
                </Button>
                <Button
                  onClick={deleteModal.open}
                  customType="transparent"
                  size="small"
                  type="button"
                >
                  <span className="t-text-text-30">
                    <Cross />
                  </span>
                </Button>
              </div>
            </div>
          </div>

          {isAnyDetailsMissing && (
            <div className="t-text-red t-text-body-sm t-mt-1 t-flex t-items-center t-gap-1">
              <img src={RedInfo} alt="RedInfo" />
              <div>
                Please update missing information. Edit to complete the required
                fields
              </div>
            </div>
          )}
        </div>
      )}
      <AddShareholder
        isOpen={addModal.isOpen || editModal.isOpen}
        close={onClose}
        shareholder={detailsForEdit}
        entityId={entityId}
        onSuccess={updateTaskDetails}
      />
      <DeleteFilingAutofill
        isOpen={deleteModal.isOpen}
        close={deleteModal.close}
        handleDeleteBank={handleDelete}
        isLoading={isUpdatingTask}
        title={deleteModalTitle}
      />
    </div>
  );
};

export default ShareholderAutofill;
