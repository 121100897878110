import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Link } from "components/DesignSystem/Link/Link";
import Table from "components/DesignSystem/Table/V2/Table";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { CalendarBlank } from "components/icons/CalendarBlank";
import { ClockCounterClockwise } from "components/icons/ClockCounterClockwise";
import { Chat } from "components/icons/LeftNav/Chat";
import { ETDUpdateReason } from "components/TaskSlider/ETDUpdateReason/ETDUpdateReason";
import { TaskSlider } from "components/TaskSlider/TaskSlider";
import {
  DropdownIndicator,
  TransactionComboboxClearIndicator,
  TransactionComboboxControl,
} from "components/Transaction/TransactionColumn";
import { NO_ASSGINEE_KEY, NO_ASSIGNEE } from "constants/agent";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import { NO_TAG, NO_TAG_KEY } from "constants/tagColors";
import { ALL, ARCHIVED, COMPLETED, ONGOING } from "constants/task";
import dayjs from "dayjs";
import {
  CRM_SUBSCRIPTION_BADGE_MAP,
  CRM_SUBSCRIPTION_TYPE,
} from "dictionaries";
import { Field, FieldProps, Form, Formik } from "formik";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useUnreadFromChannels } from "hooks/useUnreadFromChannels";
import authContext from "jwt_context&axios/authContext";
import {
  HTMLAttributes,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import "static/styles/components/tasksPage.css";
import "static/styles/components/userPage.css";
import { useGetTaskTemplatesQuery } from "store/apis/getTemplates";
import { useGetAllInkleCPATeamsQuery } from "store/apis/internalTeamSetting";
import {
  useGetAllServiceProviderTaskQuery,
  useGetServiceTeamQuery,
  useUpdateTaskAssigneeAdminMutation,
  useUpdateTaskAssigneeProviderMutation,
} from "store/apis/serviceTeam";
import {
  useGetAllTaskStatesQuery,
  useGetTaskTagQuery,
  useLazyGetTaskDataQuery,
} from "store/apis/taskTags";
import { useChatContext } from "stream-chat-react";
import { Tag } from "types/Models/FCA";
import { Task } from "types/Models/task";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { stopPropagation } from "utils/stopPropagation";
import {
  defaultSortOption,
  sortOptions,
} from "../../../constants/foreignCATask";
import {
  FORM_FILING_TYPE,
  SUBMITTED_TYPE,
} from "../../../constants/taskStates";
import { NOT_STARTED, NOT_STARTED_KEY } from "../../../constants/taskStatus";
import "../../../static/styles/components/addTeamMember.css";
import "../../../static/styles/components/consultantDashBoard.css";
import { openLink } from "../../../utils/openLink";
import Loader from "../../design/loader";
import NoDataAvailable from "../../design/noDataAvailable";
import TagCreation from "../../tagCreation/tagCreation";
import Tags from "../consultantDashboard/tags";
import TaskTabBar from "../taskTabBar";
import { ForeignCATaskFilter } from "./ForeignCATaskFilter";
import { Tag as TagComp } from "components/DesignSystem/Tag/Tag";

export const EstimdatedTimeColumn = ({
  taskData,
  isCustomTrigger = false,
}: {
  taskData: Task;
  isCustomTrigger?: boolean;
}) => {
  const {
    uuid: taskId,
    estimated_time_of_delivery = {},
    assigned_team,
  } = taskData;
  const color = estimated_time_of_delivery?.color;
  const date = estimated_time_of_delivery?.date;
  const etdUpdateReasonModal = useModal();
  const [updateTaskAssigneeForAdmin] = useUpdateTaskAssigneeAdminMutation();
  const [updateTaskAssigneeForProvider] =
    useUpdateTaskAssigneeProviderMutation();
  const { alertToast, successToast } = useToast();
  const { isAdmin } = useRoleBasedView();

  let updateTaskAssignee = isAdmin
    ? updateTaskAssigneeForAdmin
    : updateTaskAssigneeForProvider;

  const updateETD = async (date: Date) => {
    try {
      const estimatedDeliveryTime = dayjs(date).format(YYYY_MM_DD);
      if (taskId) {
        await updateTaskAssignee({
          estimatedDeliveryTime,
          taskId: taskId,
        }).unwrap();
        successToast({ message: "ETD set successfully" });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleEtdChange = (date: Date) => {
    if (estimated_time_of_delivery?.date) {
      etdUpdateReasonModal.open();
    } else {
      updateETD(date);
    }
  };

  return (
    <Formik
      onSubmit={() => {}}
      initialValues={{ estimated_delivery_time: date }}
    >
      {({ values, setFieldValue }) => (
        <Form className="t-w-full t-m-0" onClick={(e) => e.stopPropagation()}>
          <Field name="estimated_delivery_time">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  disabled={!Boolean(assigned_team)}
                  {...field}
                  portalId="estimated_delivery_time_portal"
                  {...(isCustomTrigger && {
                    customDatePickerInputTrigger: (
                      <span>
                        <Button
                          customType="ghost_icon"
                          size="small"
                          aria-label="estimatedDeliveryTime"
                          disabled={!Boolean(assigned_team)}
                        >
                          {date ? (
                            <ToolTip text={dayjs(date).format(DD_MMM_YYYY)}>
                              <span>
                                <ClockCounterClockwise color={color} />
                              </span>
                            </ToolTip>
                          ) : (
                            <CalendarBlank color="currentColor" />
                          )}
                        </Button>
                      </span>
                    ),
                  })}
                  onDateChange={(date) => {
                    setFieldValue("estimated_delivery_time", date);
                    handleEtdChange(date);
                  }}
                />
              );
            }}
          </Field>
          {taskId && etdUpdateReasonModal.isOpen && (
            <ETDUpdateReason
              isOpen={etdUpdateReasonModal.isOpen}
              close={etdUpdateReasonModal.close}
              taskId={taskId}
              selectedDate={values.estimated_delivery_time}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export const ServiceTeamColumn = ({ taskData }: { taskData: Task }) => {
  const { alertToast, successToast } = useToast();
  const { isAdmin } = useRoleBasedView();
  const { data: teamOptions = [], isLoading } = useGetAllInkleCPATeamsQuery(
    {
      accessibleTeams: "CPA_TEAM",
    },
    { skip: !isAdmin }
  );

  const [updateTaskAssigneeAdmin, { isLoading: isUpdatingTaskAdmin }] =
    useUpdateTaskAssigneeAdminMutation();

  const onServiceTeamChange = async (value?: string | null) => {
    try {
      await updateTaskAssigneeAdmin({
        taskId: taskData?.uuid || "",
        serviceTeamId: value || "",
        removeServiceTeam: value ? false : true,
        preparerId: "",
        removePreparer: true,
      }).unwrap();
      successToast({ message: "Updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <Combobox
        block
        id={taskData.uuid}
        size="small"
        menuPlacement="auto"
        onChange={(prop: SingleValue<OptionData> | MultiValue<OptionData>) =>
          onServiceTeamChange((prop as SingleValue<OptionData>)?.value)
        }
        components={{
          Control: TransactionComboboxControl,
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: TransactionComboboxClearIndicator,
        }}
        isLoading={isUpdatingTaskAdmin || isLoading}
        options={teamOptions.map((option) => {
          return {
            label: option.name,
            value: option.uuid,
            data: option.name,
          };
        })}
        {...{
          value: taskData?.assigned_team?.name
            ? {
                label: taskData?.assigned_team?.name,
                value: taskData?.assigned_team?.uuid,
              }
            : null,
        }}
        menuPortalTarget={document.body}
        styles={{
          menu: (base) => ({
            ...base,
            width: 160,
          }),
        }}
      />
    </div>
  );
};

export const PreparerColumn = ({ taskData }: { taskData: Task }) => {
  const { alertToast, successToast } = useToast();
  const { isCpa, isAdmin, isCustomer } = useRoleBasedView();
  const serviceTeamId = isAdmin ? taskData?.assigned_team?.uuid : null;
  const { data: teamMembers = [], isLoading } = useGetServiceTeamQuery(
    {
      serviceTeamId,
    },
    { skip: isCustomer }
  );

  const [updateTaskAssigneeAdmin, { isLoading: isUpdatingTaskAdmin }] =
    useUpdateTaskAssigneeAdminMutation();

  const [updateTaskAssigneeProvider, { isLoading: isUpdatingTaskProvider }] =
    useUpdateTaskAssigneeProviderMutation();

  const onPreparerChange = async (value?: string | null) => {
    try {
      if (isAdmin) {
        await updateTaskAssigneeAdmin({
          taskId: taskData?.uuid,
          serviceTeamId: taskData?.assigned_team?.uuid,
          removeServiceTeam: false,
          preparerId: value,
          removePreparer: value ? false : true,
        }).unwrap();
      } else if (isCpa) {
        await updateTaskAssigneeProvider({
          taskId: taskData?.uuid,
          preparerId: value,
          removePreparer: value ? false : true,
        }).unwrap();
      }
      successToast({ message: "Updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <Combobox
        size="small"
        menuPlacement="auto"
        creatable={false}
        isLoading={isUpdatingTaskAdmin || isLoading || isUpdatingTaskProvider}
        isDisabled={!Boolean(taskData?.assigned_team?.uuid) && isAdmin}
        onChange={(prop: SingleValue<OptionData> | MultiValue<OptionData>) =>
          onPreparerChange((prop as SingleValue<OptionData>)?.value)
        }
        components={{
          Control: TransactionComboboxControl,
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: TransactionComboboxClearIndicator,
        }}
        options={teamMembers.map(({ name, uuid }) => ({
          label: name,
          value: uuid,
          data: name,
        }))}
        {...{
          value: taskData?.assigned_to?.name
            ? {
                label: taskData?.assigned_to?.name,
                value: taskData?.assigned_to?.uuid,
              }
            : null,
        }}
        menuPortalTarget={document.body}
        styles={{
          menu: (base) => ({
            ...base,
            width: 160,
          }),
        }}
      />
    </div>
  );
};

const columnHelper = createColumnHelper<Task>();

const ChatSlider = ({
  openTaskChat,
  ...props
}: CellContext<Task, string> & {
  channelUnreadMap: Record<string, number>;
  openTaskChat: (taskId: string) => void;
}) => {
  const defaultChannelId = props.getValue();
  const defaultUnreadCount = props.channelUnreadMap[defaultChannelId];

  const [unreadCount, setUnreadCount] = useState<number>(defaultUnreadCount);
  const { client } = useChatContext();

  useEffect(() => {
    try {
      const channel = client.channel("messaging", defaultChannelId);
      const readSub = channel.on("message.read", (event) => {
        setUnreadCount(channel.countUnread());
      });

      const newSub = channel.on("message.new", (event) => {
        setUnreadCount(channel.countUnread());
      });

      return () => {
        readSub.unsubscribe();
        newSub.unsubscribe();
      };
    } catch (error) {}
  }, [defaultChannelId]);

  const onClick: HTMLAttributes<HTMLButtonElement>["onClick"] = (e) => {
    e.stopPropagation();
    openTaskChat(props.row.original.uuid);
  };

  return (
    <div>
      <Button customType="ghost_icon" size="small" onClick={onClick}>
        <div className="t-flex t-items-center t-justify-center">
          {Boolean(unreadCount) && unreadCount > 0 && (
            <span className="t-border-solid t-border t-border-surface t-absolute t-right-1 t-top-1 t-h-4 t-w-4 t-rounded-full t-z-10 t-overflow-hidden">
              <span className="t-bg-red-50 t-text-body-xs t-h-4 t-w-4 t-text-white t-flex t-items-center t-justify-center">
                {unreadCount}
              </span>
            </span>
          )}
          <Chat />
        </div>
      </Button>
    </div>
  );
};

export const taskColumns = ({
  isAdmin,
  isCpa,
  isServiceSuperAdmin,
  tags,
  channelUnreadMap,
  openTaskChat,
  isForeignCA,
}: {
  isServiceSuperAdmin: boolean;
  isAdmin: boolean;
  isCpa: boolean;
  tags: Tag[];
  channelUnreadMap: Record<string, number>;
  openTaskChat: (taskId: string) => void;
  isForeignCA: boolean;
}) => {
  const serviceTeamColumn = isAdmin
    ? [
        columnHelper.accessor("uuid", {
          cell: ({ row }) => <ServiceTeamColumn taskData={row.original} />,
          header: "Service Team",
          size: 16,
        }),
      ]
    : [];

  const preparerColumn =
    isAdmin || isServiceSuperAdmin
      ? [
          columnHelper.accessor("uuid", {
            cell: ({ row }) => <PreparerColumn taskData={row.original} />,
            header: "Preparer",
            size: 15,
          }),
        ]
      : [];

  const deadlineColumn = isCpa
    ? [
        columnHelper.accessor("deadline", {
          cell: (info) => {
            const deadline = info.getValue();
            const daysDifference = deadline
              ? dayjs(deadline).diff(dayjs(), "day")
              : "";

            return (
              <div className="t-flex t-flex-col">
                <div className="t-text-body">
                  {deadline ? formatDate(deadline) : "-"}
                </div>
                {Number(daysDifference) > 0 && (
                  <div className="t-text-body-sm t-text-red">
                    In {daysDifference} days
                  </div>
                )}
              </div>
            );
          },
          header: "Deadline",
          size: 15,
        }),
      ]
    : [];

  const estimatedTimeForDelivery = isCpa
    ? [
        columnHelper.accessor("estimated_time_of_delivery", {
          cell: ({ row }) => (
            <EstimdatedTimeColumn
              taskData={row.original}
              isCustomTrigger={true}
            />
          ),
          header: () => (
            <ToolTip text="Estimated time of delivery">
              <span className="t-pl-1">ETD</span>
            </ToolTip>
          ),
          size: 5,
        }),
      ]
    : [];

  const columns = [
    columnHelper.accessor("group_name", {
      cell: (info) => {
        const rowData = info.row?.original;
        const groupDetailsRoute = isForeignCA
          ? `/practice/crm/${rowData?.group_uuid}`
          : `/crm/${rowData?.group_uuid}`;

        return (
          <div className="t-flex t-gap-2 t-items-center">
            <Button
              customType={rowData?.has_group_perm ? "text" : "transparent"}
              onClick={() =>
                rowData?.has_group_perm &&
                openLink(groupDetailsRoute, rowData?.group_uuid)
              }
            >
              <div
                className={classNames("t-truncate t-max-w-44 t-text-body", {})}
              >
                {rowData.group_name}
              </div>
            </Button>
            {Boolean(rowData?.subscription_name) &&
              rowData?.subscription_name !== "NOT_STARTED" && (
                <Badge
                  size="small"
                  color={
                    CRM_SUBSCRIPTION_TYPE[
                      rowData?.subscription_name as
                        | "STANDARD"
                        | "PRO"
                        | "PAYG"
                        | "BYOA"
                    ]
                  }
                >
                  {
                    CRM_SUBSCRIPTION_BADGE_MAP[
                      rowData?.subscription_name as
                        | "STANDARD"
                        | "PRO"
                        | "PAYG"
                        | "BYOA"
                    ]
                  }
                </Badge>
              )}
          </div>
        );
      },
      header: "Group Name",
      size: 7,
    }),

    columnHelper.accessor("title", {
      cell: (info) => {
        const rowData = info.row?.original;
        const isCompleted = rowData?.state_details?.type === SUBMITTED_TYPE;

        const notStarted =
          (rowData?.state_details?.type === FORM_FILING_TYPE ||
            rowData?.state === null) &&
          rowData?.state_details?.type !== SUBMITTED_TYPE;

        const inProgress =
          rowData?.state_details?.type !== FORM_FILING_TYPE &&
          rowData?.state !== null &&
          rowData?.state_details?.type !== SUBMITTED_TYPE;

        return (
          <div className="t-flex t-flex-col t-gap-0.5">
            <Link
              to={`/tax/filings/${rowData.uuid}`}
              className="t-truncate t-max-w-64 t-text-body hover:!t-underline"
              target={rowData.uuid}
              onClick={stopPropagation}
            >
              {rowData.title}
            </Link>
            <div className="t-flex t-gap-1 t-items-center">
              <div className="t-text-text-30 t-text-body-sm">
                {rowData.season}
              </div>
              <span className="t-text-neutral-10">|</span>
              <span className="t-flex t-gap-1 t-items-center">
                <span
                  className={classNames(
                    "t-w-2 t-h-2 t-rounded-full t-text-body-sm",
                    {
                      "t-bg-dark_green": isCompleted,
                      "t-bg-red": notStarted,
                      "t-bg-yellow": inProgress,
                    }
                  )}
                ></span>
                <span className="t-text-body-sm t-text-text-30">
                  {(rowData?.state as string) || NOT_STARTED}
                </span>
              </span>
              {rowData.is_archived && (
                <>
                  <span className="t-text-neutral-10">|</span>
                  <TagComp icon={false} size="small" tagType="gray">
                    A
                  </TagComp>
                </>
              )}
            </div>
          </div>
        );
      },
      header: "Filing",
      size: 18,
    }),
    ...serviceTeamColumn,
    ...preparerColumn,
    columnHelper.accessor("tags", {
      cell: (info) => {
        const rowData = info.row?.original;
        return (
          <Tags
            taskId={rowData.uuid}
            currentTags={rowData?.tags}
            tags={tags}
            size="small"
          />
        );
      },
      header: "Tags",
      size: 10,
    }),
    ...deadlineColumn,
    ...estimatedTimeForDelivery,
    columnHelper.accessor("assigned_at", {
      cell: (info) => (info.getValue() ? formatDate(info.getValue()) : " _ "),
      header: "Assigned On",
      size: 10,
    }),
    columnHelper.accessor("channel", {
      header: "",
      //TODO:  Currently task list api and task api gives two diffrent response but uses same type, change this
      // @ts-ignore
      cell: memo((info: CellContext<Task, string>) => (
        <ChatSlider
          {...info}
          channelUnreadMap={channelUnreadMap}
          openTaskChat={openTaskChat}
        />
      )),
      size: 4,
    }),
  ];

  return columns;
};

export const ForeignCATask = ({ hideTabBar }: { hideTabBar?: boolean }) => {
  const { isCustomer, isAdmin, isServiceSuperAdmin, isCpa, isForeignCA } =
    useRoleBasedView();
  const { data: serviceTeam = [] } = useGetServiceTeamQuery(
    {},
    {
      skip: isCustomer,
    }
  );
  const assignees = [
    ...serviceTeam,
    { name: NO_ASSIGNEE, uuid: NO_ASSGINEE_KEY },
  ];
  usePageTitle("Filings");
  const { update } = useUpdateQuery();
  const { data } = useGetTaskTemplatesQuery();
  const { templates = [] } = data || {};
  const query = useQuery();
  const [showTagModal, setShowTagModal] = useState(false);
  const pageNumber = Number(query.get("page") || 1);
  const searchTerm = query.get("search_term") || null;
  const selectedTag = query.get("task_tag_filter_uuids") || null;
  const selectedCompanyTag = query.get("company_tag_filter_uuids") || null;
  const selectedSubscription = query.get("subscription_filter") || null;
  const stateFilter = query.get("task_state_filter_uuids") || null;
  const selectedTemplate = query.get("task_template_filter_uuids") || null;
  const assigneeFilter = query.get("assignee_uuids") || null;
  const selectedTeams = query.get("service_team_uuids") || null;
  const selectedMembers = query.get("team_member_uuids") || null;
  const selectedETD = query.get("etd_filter") || null;
  const status = query.get("status");
  const showUnassignedTeam = query.get("show_unassigned_team");
  const showUnassignedPreparer = query.get("show_unassigned_preparer");
  const [generateReport, setGenerateReport] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions.find(
      (option) => option.sortKey === query.get("task_sort_by")
    ) || defaultSortOption
  );
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const { data: tagsData = [] } = useGetTaskTagQuery();
  const { data: taskStatesData = [] } = useGetAllTaskStatesQuery();
  const { authtoken } = useContext(authContext);
  const { practicecrmId } = useParams<{ practicecrmId?: string }>();
  const taskChatSlider = useModal();
  const taskSlider = useModal();
  const [taskId, setTaskId] = useState<string | null>(null);

  const serviceTeamId = practicecrmId || authtoken.service_team_id;

  const taskStates = [
    ...taskStatesData,
    { name: NOT_STARTED, uuid: NOT_STARTED_KEY },
  ];

  const tags = useMemo(
    () => [
      ...tagsData,
      {
        title: NO_TAG,
        uuid: NO_TAG_KEY,
        bg_color_hex: "",
        color_hex: "",
        tag_category: 0,
      },
    ],
    [tagsData]
  );

  const { values, updateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  const { END_DATE, START_DATE } = values;

  const {
    data: allServiceProviders,
    isLoading,
    isFetching,
  } = useGetAllServiceProviderTaskQuery(
    {
      page_num: pageNumber,
      search_term: searchTerm,
      task_tag_filter_uuids: selectedTag,
      company_tag_filter_uuids: selectedCompanyTag,
      task_state_filter_uuids: stateFilter,
      task_template_filter_uuids: selectedTemplate,
      assignee_uuids: assigneeFilter,
      task_sort_by: selectedSortOption.sortKey,
      subscriptionFilter: selectedSubscription,
      generate_report: generateReport,
      task_list_type: status,
      service_team_ids: (serviceTeamId ? serviceTeamId : selectedTeams) || null,
      etd_type: selectedETD,
      preparer_ids: selectedMembers,
      completed_start_date: START_DATE,
      completed_end_date: END_DATE,
      show_unassigned_team: showUnassignedTeam,
      show_unassigned_preparer: showUnassignedPreparer,
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    tasks = [],
    total_pages,
    total_count,
    per_page = 20,
  } = allServiceProviders || {};

  const channelUrls = tasks.map((t) => t.channel as unknown as string);

  const channelUnreadMap = useUnreadFromChannels({
    channelUrls,
    queryOptions: { limit: per_page },
  });

  const tableData = useMemo(() => tasks, [tasks]);

  const paginationData = {
    totalPage: total_pages || 0,
    currentPage: pageNumber,
    itemsPerPage: per_page,
    totalItemCount: total_count || 0,
  };

  useEffect(() => {
    if (!isFetching) {
      setIsTabSwitched(false);
    }
  }, [isTabSwitched, isFetching]);

  useEffect(() => {
    if (allServiceProviders?.download_url) {
      window.open(allServiceProviders?.download_url);
    }
  }, [allServiceProviders]);

  const onTaskChatClick = useCallback((taskId: string) => {
    taskChatSlider.open();
    setTaskId(taskId);
  }, []);

  const columns = useMemo(
    () =>
      taskColumns({
        isAdmin,
        isCpa,
        tags,
        channelUnreadMap,
        isServiceSuperAdmin,
        openTaskChat: onTaskChatClick,
        isForeignCA,
      }),
    [
      JSON.stringify(channelUnreadMap),
      isAdmin,
      isCpa,
      tags,
      isServiceSuperAdmin,
      onTaskChatClick,
      isForeignCA,
    ]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const handleClose = () => {
    setShowTagModal(false);
  };

  const onTaskRowClick = (taskId: string) => {
    taskSlider.open();
    setTaskId(taskId);
  };

  const onTaskSliderClose = () => {
    taskSlider.close();
    taskChatSlider.close();
    setTaskId(null);
  };

  return (
    <div className="t-w-full t-h-full">
      {!hideTabBar && <TaskTabBar defaultValue="admin/filings" />}
      <div className="t-p-4">
        <ToggleGroup
          onValueChange={(value) => {
            if (value) {
              update({
                query: "status",
                value: value !== ALL ? value : null,
              });
              setIsTabSwitched(true);
            }
          }}
          value={status || ALL}
        >
          <ToggleGroupItem value={ALL}>All</ToggleGroupItem>
          <ToggleGroupItem value={ONGOING}>Ongoing</ToggleGroupItem>
          <ToggleGroupItem value={COMPLETED}>Completed</ToggleGroupItem>
          <ToggleGroupItem value={ARCHIVED}>Archived</ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div>
        <ForeignCATaskFilter
          searchedTerm={searchTerm}
          selectedTag={selectedTag}
          selectedCompanyTag={selectedCompanyTag}
          tags={tags}
          selectedStateFilter={stateFilter}
          taskStates={taskStates}
          templates={templates}
          selectedTemplateFilter={selectedTemplate}
          selectedAssignee={assigneeFilter}
          assignees={assignees}
          selectedSortOption={selectedSortOption}
          setSelectedSortOption={setSelectedSortOption}
          showSortFeature={true}
          update={update}
          totalCount={total_count!}
          totalPages={total_pages!}
          selectedSubscription={selectedSubscription}
          download={setGenerateReport}
          pageNum={pageNumber}
          paginationData={paginationData}
          isLoading={isLoading}
          selectedETD={selectedETD}
          selectedTeams={selectedTeams}
          selectedMembers={selectedMembers}
          values={values}
          updateFilter={updateFilter}
          serviceTeamId={serviceTeamId}
          showUnassignedTeam={showUnassignedTeam}
          showUnassignedPreparer={showUnassignedPreparer}
        />
      </div>
      {isLoading || isTabSwitched ? (
        <Loader />
      ) : tableData.length === 0 ? (
        <NoDataAvailable noDataText="Tax & compliance filings started for your clients will show up here" />
      ) : (
        <div className="t-mx-5 !t-h-full">
          <Table.Container size="regular">
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        key={header.id}
                        className="t-text-subtext-sm t-uppercase t-p-2"
                        style={{ width: `${header.getSize()}%` }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row
                    onRowClick={() => onTaskRowClick(row.original.uuid)}
                    key={row.id}
                    className="hover:t-bg-surface-lighter-grey"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        className="t-p-2 t-text-nowrap"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </div>
      )}

      <TaskSlider
        taskId={taskId}
        isOpen={taskSlider.isOpen || taskChatSlider.isOpen}
        onClose={onTaskSliderClose}
        defaultTab={taskSlider.isOpen ? "DETAILS" : "CHAT"}
      />
      <TagCreation show={showTagModal} handleClose={handleClose} />
    </div>
  );
};
