/* eslint-disable no-unused-vars */
import { groupStage } from "apis/groupStage";
import classNames from "classnames";
import { CrmTableDropdown } from "components/CrmTableDropdown/CrmTableDropdown";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Badge } from "components/design/badge";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { DynamicStar } from "components/DesignSystem/Star/DynamicStar";
import Table from "components/DesignSystem/Table/V2/Table";
import OpsStage from "components/salesCrm/OpsStage";
import ReferrerModal from "components/salesCrm/ReferrerModal";
import SalesCrmStage from "components/salesCrm/salesCrmStage";
import { Badge as TaskBadge } from "components/Task/Badge";
import { NOT_STARTED } from "constants/taskStatus";
import {
  CRM_SUBSCRIPTION_TYPE,
  CRM_SUBSCRIPTION_BADGE_MAP,
} from "dictionaries";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import ArrowLineUp from "static/images/ArrowLineUp.svg";
import BankWarningCircle from "static/images/BankWarningCircle.svg";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { useGetTaskTemplatesQuery } from "store/apis/getTemplates";
import {
  useGetAllSalesCRMGroupsQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { TASK_TEMPLATE_FILTER } from "types/contants/crm";
import { getTags } from "../../apis/getGroupTags";
import "../../static/styles/components/addTeamMember.css";
import "../../static/styles/components/serviceTeam.css";
import Loader from "../design/loader";
import NoDataAvailable from "../design/noDataAvailable";
import TagCreation from "../tagCreation/tagCreation";
import { AssignAgent } from "./AssignAgent";
import CrmTabBar from "./crmTabs/crmTabBar";
import ServiceTeamFilter from "./serviceTeamFilter";
import { OPSSlider } from "./Slider/OPSSlider";
import Tags from "./Tags";
import { SalesPerson } from "./SalesPerson";
import BankCount from "static/images/BankCount.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import authContext from "jwt_context&axios/authContext";
import { useGetCountryStatesQuery } from "store/apis/teamSetting";
import { US_UUID } from "constants/regAgent";
import Modal from "components/DesignSystem/Modal/Modal";

export default function ServiceTeam({ hideTabBar }) {
  usePageTitle("CRM");
  const { alertToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const { isAdmin, isCpa, isForeignCA, isUserTypeForeignCA } =
    useRoleBasedView();
  const { data: assignMembers = [] } = useGetAssignAgentsQuery(
    {},
    {
      skip: !isAdmin,
    }
  );
  const { data: usStates } = useGetCountryStatesQuery({ countryId: US_UUID });
  const usIncorporationMonths = [
    { name: "January", month_id: "1" },
    { name: "February", month_id: "2" },
    { name: "March", month_id: "3" },
    { name: "April", month_id: "4" },
    { name: "May", month_id: "5" },
    { name: "June", month_id: "6" },
    { name: "July", month_id: "7" },
    { name: "August", month_id: "8" },
    { name: "September", month_id: "9" },
    { name: "October", month_id: "10" },
    { name: "November", month_id: "11" },
    { name: "December", month_id: "12" },
  ];
  const [groups, setGroups] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const pageNumber = Number(query.get("page") || 1);
  const searchTerm = query.get("search_term") || null;
  const selectedTag = query.get("tag_filter") || null;
  const selectedSubscription = query.get("subscription_type") || null;
  const agentFilter = query.get("agent_filter") || null;
  const salesPersonFilter = query.get("salesperson_filter") || null;
  const usStateFilter = query.get("us_state_uuids") || null;
  const incorporationMonthsFilter = query.get("us_incorp_months") || null;
  const selectedTemplate = query.get("not_launched_task_filter") || null;
  const selectedSubscriberType = query.get("subscriber_filter") || null;
  const sortDueBy = query.get("sort_order") || null;
  const selectedTask1120Filter = query.get(TASK_TEMPLATE_FILTER) || null;
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [tags, setTags] = useState([]);
  const [stages, setStages] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [userGroupId, setUserGroupId] = useState("");
  const [showMoveToSalesModal, setShowMoveToSalesModal] = useState(false);
  const currentPageItemCount = groups?.length || 0;
  const viewFilter = "OPS_CRM";
  const [payWall, setPayWall] = useState(null);
  const [attributionType, setAttributionType] = useState("");
  const [openSlider, setOpenSlider] = useState();
  const [selectedRowData, setSelectedRowData] = useState();
  const [showAddAttributionModal, setShowAddAttributionModal] = useState(false);
  const [generateReport, setGenerateReport] = useState(false);
  const { authtoken } = useContext(authContext);
  const { practicecrmId } = useParams();
  const isFCA = isUserTypeForeignCA || isForeignCA;

  const serviceTeamId = practicecrmId || authtoken.service_team_id;

  const task1120Filter = selectedTask1120Filter
    ? {
        task_season: "2023",
        taskState: selectedTask1120Filter,
      }
    : {};
  const {
    data: group,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetAllSalesCRMGroupsQuery(
    {
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      viewFilter: viewFilter,
      subscriptionFilter: selectedSubscription,
      agentFilter: agentFilter,
      salespersonFilter: salesPersonFilter,
      usStateFilter: usStateFilter,
      incorporationMonthsFilter: incorporationMonthsFilter,
      selectedTag: selectedTag,
      sortDueBy: sortDueBy,
      notLaunchedTaskFilter: selectedTemplate,
      subscriberTypeFilter: selectedSubscriberType,
      base_task_template_id: process.env.REACT_APP_BASE_TASK_TEMPLATE_ID_1120,
      generate_report: generateReport,
      service_team_id: serviceTeamId,
      ...task1120Filter,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (generateReport && group?.download_url) {
      window.open(group.download_url);
      setGenerateReport(false);
    }
  }, [group?.download_url]);

  useEffect(() => {
    if (sortDueBy) {
      update({ query: "search_term", value: null });
      update({ query: "tag_filter", value: null });
      update({ query: "subscription_type", value: null });
      update({ query: "agent_filter", value: null });
      update({ query: "salesperson_filter", value: null });
      update({ query: "not_launched_task_filter", value: null });
      update({ query: "subscriber_filter", value: null });
      update({ query: TASK_TEMPLATE_FILTER, value: null });
    }
  }, [sortDueBy]);

  useEffect(() => {
    if (sortDueBy) {
      update({ query: "sort_order", value: null });
    }
  }, [
    searchTerm,
    selectedTag,
    selectedSubscription,
    agentFilter,
    selectedTemplate,
    selectedSubscriberType,
    selectedTask1120Filter,
  ]);

  const { data: subscriptions = [] } = useGetSubscriptionsQuery({
    get_inactive: true,
    subscription_types: "PLATFORM",
  });

  const [updateGroup, { data: patchedResp, isLoading: isPatchBtnLoading }] =
    useUpdateGroupDataMutation();

  const { data: tasktemplates } = useGetTaskTemplatesQuery();

  const { templates = [] } = tasktemplates || {};

  const {
    company_groups = [],
    total_pages = 1,
    total_count = 1,
    current_page = 1,
    per_page = 25,
  } = group || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage =
      pageNumber < total_pages ? pageNumber : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNumber < total_pages) {
      update({ query: "page", value: pageNumber + 1 });
    }
  };
  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  useEffect(() => {
    setGroups(company_groups);
    setTotalPageCount(total_pages);
    setTotalItemCount(total_count);
  }, [total_pages, total_count, isFetching]);

  useEffect(() => {
    refetch();
  }, [pageNumber]);

  useEffect(() => {
    getTags().then((res) => {
      setTags(res.data.data);
    });
  }, [showTagModal]);

  useEffect(() => {
    const getStages = async () => {
      let viewFilter = "OPS_CRM";
      const { data } = await groupStage({ viewFilter });
      setStages(data);
    };
    const getAllStages = async () => {
      const { data } = await groupStage({});
      setAllStages(data);
    };
    if (isAdmin) {
      getStages();
      getAllStages();
    }
  }, []);

  const handleMoveToSales = (groupId) => {
    setUserGroupId(groupId);
    setShowMoveToSalesModal(true);
  };

  const moveToSales = async () => {
    try {
      const response = await updateGroup({
        payLoad: { crm_type: "SALES_CRM" },
        groupId: userGroupId,
      }).unwrap();
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowMoveToSalesModal(false);
  };

  useEffect(() => {
    if (payWall !== null) {
      const updatePayWall = async () => {
        try {
          const resp = await updateGroup(
            {
              payLoad: { is_subscription_required: payWall },
              groupId: userGroupId,
            },
            {
              skip: payWall,
            }
          );
          setPayWall(null);
        } catch (err) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updatePayWall();
    }
  }, [payWall]);

  const columnHelper = createColumnHelper();

  const Sales = isAdmin
    ? [
        columnHelper.accessor("sales_stage", {
          id: "sales_stage",
          header: "SALES PERSON",
          size: 15,
          cell: ({
            row: {
              original: { sales_person, uuid },
            },
          }) => {
            return <SalesPerson assignedAgent={sales_person} groupId={uuid} />;
          },
        }),
      ]
    : [];

  const Ops = isAdmin
    ? [
        columnHelper.accessor("ops_stage", {
          id: "ops_stage",
          header: "Ops",
          size: 5,
          cell: ({ row }) => {
            const { ops_stage, uuid } = row.original;
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <OpsStage
                  currentStage={ops_stage}
                  groupId={uuid}
                  updateSalesStage={updateGroup}
                />
              </div>
            );
          },
        }),
      ]
    : [];

  const Filings = isFCA
    ? [
        columnHelper.accessor("ongoing_tasks", {
          id: "ongoing_tasks",
          header: isFCA ? "Filings" : "Pending Filings",
          size: isFCA ? 35 : isCpa ? 35 : 5,
          cell: ({ row }) => {
            const { ongoing_tasks, ongoing_tasks_count } = row.original;
            return (
              <ConditionalToolTip condition={ongoing_tasks && ongoing_tasks}>
                <div>{ongoing_tasks_count ? ongoing_tasks_count : "-"}</div>
              </ConditionalToolTip>
            );
          },
        }),
      ]
    : [];

  const AssignedAgent = isAdmin
    ? [
        columnHelper.accessor("", {
          header: "Agent",
          size: 15,
          cell: ({
            row: {
              original: { assigned_agent, uuid },
            },
          }) => <AssignAgent assignedAgent={assigned_agent} groupId={uuid} />,
        }),
      ]
    : [];

  const Task1120 =
    isCpa && !isFCA
      ? [
          columnHelper.accessor("", {
            header: `Task ${process.env.REACT_APP_OPS_TASK_TEMPLATE_FILTER_NAME}`,
            size: isAdmin ? 10 : 20,
            cell: ({ row }) => {
              const { task } = row.original;
              if (task?.current_state_name) {
                return (
                  <TaskBadge
                    children={task.current_state_name}
                    type="FORM_FILING"
                  />
                );
              }
              if (task?.uuid) {
                return (
                  <TaskBadge type="ACTION_REQUIRED" children={NOT_STARTED} />
                );
              }
              return (
                <TaskBadge type="ACTION_REQUIRED" children="Not Created" />
              );
            },
          }),
        ]
      : [];

  const Bank = !isFCA
    ? [
        columnHelper.accessor("connected_banks", {
          header: "Banks",
          size: isAdmin ? 5 : isCpa ? 20 : 5,
          cell: (info) => {
            const totalCount = info.getValue()?.length;
            const failedCount = info
              .getValue()
              ?.filter(({ in_error_state }) => in_error_state).length;

            if (totalCount === 0) {
              return (
                <div className="t-flex t-justify-center">
                  <ToolTip side="top" text={"No accounts connected"}>
                    <img src={CrossRed} alt="no accounts" />
                  </ToolTip>
                </div>
              );
            }
            if (failedCount) {
              return (
                <div className="t-flex t-justify-center">
                  <ToolTip side="top" text={failedCount + " accounts failed"}>
                    <img src={BankWarningCircle} alt="failed" />
                  </ToolTip>
                </div>
              );
            } else {
              return (
                <div>
                  <img src={BankCount} alt="BankCount" />
                </div>
              );
            }
          },
        }),
      ]
    : [];

  const DueAmount = isAdmin
    ? [
        columnHelper.accessor("due_amount", {
          header: () => (
            <div className="t-w-full t-flex t-justify-end">Due Amount</div>
          ),
          size: isCpa ? 25 : 20,
          cell: (info) => {
            return (
              <div className="t-w-full t-flex t-justify-end t-pr-4">
                {info.getValue() ? (
                  <AmountSuperScript
                    currencyCode="USD"
                    amount={info.getValue() || 0}
                  />
                ) : (
                  <>-</>
                )}
              </div>
            );
          },
        }),
      ]
    : [];

  const tagColumn = !isFCA
    ? [
        columnHelper.accessor("tags", {
          id: "tags",
          header: "Tags",
          size: isFCA ? 30 : isCpa ? 20 : 10,
          cell: ({ row }) => (
            <Tags
              taskId={row.original.uuid}
              currentTags={row.original?.tags}
              tags={tags}
              updateTags={updateGroup}
            />
          ),
        }),
      ]
    : [];

  const Action = !isFCA
    ? [
        columnHelper.accessor("", {
          id: "threedots",
          size: 5,
          cell: (info) => {
            const { uuid, name } = info.row.original;
            return (
              <CrmTableDropdown
                actionOn="group"
                showMoveToSalesOption
                uuid={uuid}
              />
            );
          },
        }),
      ]
    : [];

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      size: isFCA ? 25 : isCpa ? 25 : 7,
      header: "Group Names",
      cell: ({ row }) => {
        return (
          <div className="t-flex t-gap-2 t-truncate t-items-center">
            <ToolTip side="top" text={row.original?.name}>
              <Link
                className="startTask"
                to={
                  isForeignCA
                    ? `/practice/crm/${row.original.uuid}`
                    : `/admin/crm/${row.original.uuid}`
                }
                target="_blank"
                aria-hidden={true}
              >
                {row.original?.name}
              </Link>
            </ToolTip>
            {row.original.subscription_name &&
              row.original.subscription_name !== "NOT_STARTED" && (
                <Badge
                  size="small"
                  color={CRM_SUBSCRIPTION_TYPE[row.original.subscription_name]}
                >
                  {CRM_SUBSCRIPTION_BADGE_MAP[row.original.subscription_name]}
                </Badge>
              )}
            {row.original.is_vip && <DynamicStar isActive />}
          </div>
        );
      },
    }),
    ...Sales,
    ...AssignedAgent,
    ...Ops,
    ...Filings,
    ...tagColumn,
    ...Task1120,
    ...Bank,
    ...DueAmount,
    ...Action,
  ];

  const data = useMemo(() => groups, [groups]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const handleClose = () => {
    setShowTagModal(false);
  };

  const handleRowClick = (uuid) => {
    setUserGroupId(uuid);
  };

  const onRowClick = (e, uuid) => {
    e.stopPropagation();
    if (!isAdmin) {
      return;
    }
    if (openSlider && uuid === userGroupId) {
      setOpenSlider(false);
      setUserGroupId("");
      return;
    }
    handleRowClick(uuid);
    setOpenSlider(true);
  };

  const noGroup = groups.length === 0;

  return (
    <div
      className="addTeamMemberContent serviceTeamContainer t-relative"
      onClick={() => setOpenSlider(false)}
    >
      {(isAdmin || isCpa) && !isFCA && !hideTabBar && (
        <CrmTabBar selectedTabIs="admin/crm" />
      )}
      <div className="serviceTeamFilter">
        <ServiceTeamFilter
          searchedTerm={searchTerm}
          templates={templates}
          selectedTag={selectedTag}
          tags={tags}
          selectedSubscription={selectedSubscription}
          subscriptions={subscriptions}
          setShowTagModal={setShowTagModal}
          currentPageItemCount={currentPageItemCount}
          selectedSubscriberType={selectedSubscriberType}
          totalPageCount={totalPageCount}
          totalItemCount={totalItemCount}
          selectedAgent={agentFilter}
          selectedSalesperson={salesPersonFilter}
          selectedStates={usStateFilter}
          assignMembers={assignMembers}
          usStates={usStates}
          selectedIncorporationMonths={incorporationMonthsFilter}
          usIncorporationMonths={usIncorporationMonths}
          sortDueBy={sortDueBy}
          selectedTemplate={selectedTemplate}
          update={update}
          paginationData={paginationData}
          goToFirstPage={goToFirstPage}
          goToLastPage={goToLastPage}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
          downloadCSV={() => setGenerateReport(true)}
          isDownloadingCsv={isFetching}
        />
      </div>

      {isLoading || !isSuccess ? (
        <Loader />
      ) : (
        <>
          {noGroup ? (
            <NoDataAvailable
              noDataText={
                isFCA
                  ? "Add clients here for starting US tax & accounting services"
                  : undefined
              }
            />
          ) : (
            <div className="t-overflow-auto t-py-4 t-px-6">
              <Table.Container>
                <Table.Content>
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.Row key={headerGroup.id}>
                        {headerGroup?.headers?.map((header) => (
                          <Table.HeadCell
                            key={header.id}
                            style={{ width: `${header.getSize()}%` }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Table.HeadCell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => {
                      const {
                        original: { uuid },
                      } = row;

                      return (
                        <Table.Row
                          key={row.id}
                          className={classNames(
                            "hover:t-bg-surface-lighter-grey t-cursor-pointer",
                            {
                              "t-bg-surface-lighter-grey": uuid === userGroupId,
                            }
                          )}
                          onClick={(e) => onRowClick(e, uuid)}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <Table.Cell
                              key={cell.id}
                              style={{
                                width: `${cell.column.getSize()}%`,
                                height: "60px",
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table.Content>
              </Table.Container>
              <Slider
                open={openSlider}
                overlay
                width={450}
                style={{
                  width: "450px",
                }}
                className="t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-b-0 t-border-r-0 t-border-l t-shadow-card !t-p-0 !t-fixed t-top-[100px]"
              >
                <OPSSlider
                  groupId={userGroupId}
                  setOpenSlider={setOpenSlider}
                />
              </Slider>
            </div>
          )}
        </>
      )}
      <TagCreation show={showTagModal} handleClose={handleClose} />
      <Modal.Root
        open={showMoveToSalesModal}
        onOpenChange={() => setShowMoveToSalesModal(false)}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Move the group to Sales?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-black">
              Are you sure that you want to move the group to Sales CRM?
            </div>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button onClick={() => setShowMoveToSalesModal(false)}>No</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              isLoading={isPatchBtnLoading}
              onClick={moveToSales}
            >
              Yes
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <ReferrerModal
        groups={groups}
        pageNumber={pageNumber}
        searchTerm={searchTerm}
        viewFilter={viewFilter}
        showAddAttributionModal={showAddAttributionModal}
        setShowAddAttributionModal={setShowAddAttributionModal}
        updateGroup={updateGroup}
        groupId={userGroupId}
        attributionType={attributionType}
      />
    </div>
  );
}
