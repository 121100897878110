import { AutofillHome } from "components/AutoFill/AutofillHome";
import { DashboardLayout } from "components/DashboardLayout";
import Tab from "components/DesignSystem/Tab/Tab";
import { RegisterAgent } from "components/RegisteredAgent/RegisterAgent";
import { TaskList } from "components/Task/TaskList";
import BillingTabBar from "components/billing/BillingTabBar";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Mailbox } from "components/mailbox/mailbox";
import { TaxAndCompliance } from "components/taxCompliance/TaxAndCompliance";
import AddTeamMember from "components/teamMembers/addTeamMember";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { Raise } from "pages/Resources/Raise";
import { Invoices } from "pages/TPPayments/Invoices";
import { ReactNode, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useGetGroupDataQuery } from "store/apis/teamSetting";
import { Group } from "types/Models/group";
import NotesIcon from "../../static/images/Notes.svg";
import "../../static/styles/components/groupData.css";
import ToolTip from "../design/toolTip";
import Documents from "../documents/documents";
import { CrmNotes } from "../serviceTeam/crmNotes";
import { CrmPerks } from "./CrmPerks";
import { CrmQuotes } from "./CrmQuotes";
import Entities from "./entities";
import { Messages } from "./messages";
import { InternalTeam } from "components/teamMembers/InternalTeam";
import { Header } from "components/DesignSystem/Header/Header";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import Ticket from "components/Ticket/Tickets";
import { DeadlineAlarm } from "components/DeadlineAlarm/DeadlineAlarm";
import { AuditLogs } from "pages/AuditLogs/AuditLogs";
import Async from "components/DesignSystem/AsyncComponents/Async";

const CrmTabList = ({ data }: { data: Group | null }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();

  const internalTeam = isAdmin
    ? [
        {
          url: "/internal-team-settings",
          name: "Internal Team Settings",
          exact: false,
          omitUrl: false,
        },
      ]
    : [];

  const companyTickets = isAdmin
    ? [
        {
          url: "/company-tickets",
          name: "Tickets",
          exact: false,
          omitUrl: false,
        },
      ]
    : [];

  const billing =
    isAdmin || (isCpa && !isForeignCA)
      ? [{ url: "/billing", name: "Billing", exact: false, omitUrl: false }]
      : [];

  let crmTabs = [
    { url: "", name: "Home", exact: true, omitUrl: false },
    { url: "/filings", name: "Filings", exact: false, omitUrl: false },
    ...companyTickets,
    {
      url: "/team-settings",
      name: "Team Settings",
      exact: false,
      omitUrl: false,
    },
    ...billing,
    ...internalTeam,
    { url: "/autofills", name: "Auto-Fill", exact: false, omitUrl: false },
    { url: "/chat", name: "Chat", exact: false, omitUrl: false },
    {
      url: `/documents/${data?.documents_group_id}`,
      name: "Documents",
      exact: false,
      omitUrl: false,
    },
    { url: "/tax", name: "Tax", exact: false, omitUrl: false },
    { url: "/deadlines", name: "Deadlines", exact: false, omitUrl: false },
  ];

  if (isAdmin) {
    crmTabs = [
      ...crmTabs,
      { url: "/registeredagent", name: "RA", exact: false, omitUrl: false },
      { url: "/mail", name: "Mailroom", exact: false, omitUrl: false },
    ];
  }

  if (isForeignCA) {
    crmTabs = [...crmTabs];
  }

  if (isAdmin) {
    crmTabs = [
      ...crmTabs,
      {
        url: "/quotes",
        name: "Deadlines & Quote",
        exact: false,
        omitUrl: false,
      },
    ];
  }

  if (!isForeignCA) {
    crmTabs = [
      ...crmTabs,
      {
        url: `/books?company=${groupId}`,
        name: "Books",
        exact: false,
        omitUrl: true,
      },
    ];
  }

  if (isAdmin) {
    crmTabs = [
      ...crmTabs,
      { url: "/raise", name: "Raise", exact: false, omitUrl: false },
      { url: "/perks", name: "Perks", exact: false, omitUrl: false },
      {
        url: "/auditlog",
        name: "Audit logs",
        exact: false,
        omitUrl: false,
      },
    ];
  }

  return (
    <>
      {crmTabs.map(({ url, name, exact, omitUrl }) => (
        <CrmTab tabUrl={url} exact={exact} omitUrl={omitUrl} key={url}>
          {name}
        </CrmTab>
      ))}
    </>
  );
};

const CrmTab = ({
  tabUrl = "",
  children,
  exact,
  target,
  omitUrl,
}: {
  tabUrl: string;
  children: ReactNode;
  exact: boolean;
  target?: string;
  omitUrl: boolean;
}) => {
  const { url } = useRouteMatch();
  let to = `${url}${tabUrl}`;

  if (omitUrl) {
    to = tabUrl;
  }

  return (
    <Tab.NavTrigger exact={Boolean(exact)} value={to} target={target}>
      {children}
    </Tab.NavTrigger>
  );
};

export const GroupData = () => {
  const { pathname } = useLocation();
  const { groupId } = useParams<{ groupId: string }>();
  const { path, url } = useRouteMatch();
  const [showNotes, setShowNotes] = useState(false);
  const [clickedNote, setClickedNote] = useState<Group | null>();
  const { isAdmin, isForeignCA } = useRoleBasedView();

  const { dispatch } = useCurrentGroupContext();
  let { data, refetch, isLoading, isSuccess } = useGetGroupDataQuery(
    { groupId },
    { skip: !groupId }
  );

  usePageTitle(`${data?.name || "Group"}`);
  const closeNotesModal = () => setShowNotes(false);
  const openNotesModal = () => setShowNotes(true);

  useEffect(() => {
    setClickedNote(data);

    return () => setClickedNote(null);
  }, [data]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_GROUP", payload: data });
    }
  }, [groupId, data?.uuid]);

  const closeWindow = () => window.close();
  const isEmpty = !data;

  return (
    <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={isEmpty}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <DashboardContainer className="groupCRMPage t-h-full">
          <DashboardContainer.Header>
            <div className="backBar t-shrink-0 t-flex-grow-0 t-basis-auto">
              <div className="leftHeadBack">
                <i
                  role="article"
                  className="fas fa-arrow-left"
                  onClick={closeWindow}
                  aria-hidden={true}
                ></i>
                <span>{data?.name}</span>
                <ToolTip text="Notes">
                  <img
                    className="notesIcon"
                    onClick={openNotesModal}
                    src={NotesIcon}
                    alt="NotesIcon"
                  />
                </ToolTip>
              </div>
            </div>
            <div className="t-box-border t-w-full t-shrink-0 t-flex-grow-0 t-basis-auto t-border t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-20 t-pl-6 t-whitespace-nowrap">
              <Tab.Root defaultValue={pathname} value={pathname}>
                <Tab.List>
                  <CrmTabList data={data!} />
                </Tab.List>
              </Tab.Root>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content className="t-mt-5">
            <Switch>
              <Route path={`${path}/perks`}>
                <CrmPerks />
              </Route>

              <Route path={`${path}/filings`}>
                <TaskList />
              </Route>
              <Route exact path={`${path}/team-settings`}>
                <AddTeamMember />
              </Route>
              <Route exact path={`${path}/internal-team-settings`}>
                <InternalTeam />
              </Route>
              <Route exact path={`${path}/company-tickets`}>
                <Ticket companyGroupUUID={data?.uuid} />
              </Route>
              <Route path={`${path}/billing`}>
                <BillingTabBar crmId={data?.uuid} />
              </Route>
              <Route path={`${path}/autofills`}>
                <DashboardLayout>
                  <AutofillHome />
                </DashboardLayout>
              </Route>
              <Route
                exact
                path={[
                  `${path}/chat`,
                  `${path}/chat/:channelId/`,
                  `${path}/chat/:channelId/:messageId`,
                ]}
              >
                <Messages path={url} />
              </Route>

              <Route exact path={path}>
                <Entities
                  entities={data?.entities}
                  group_id={groupId}
                  refetch={refetch}
                />
              </Route>
              <Route exact path={`${path}/registeredagent`}>
                <RegisterAgent />
              </Route>
              <Route exact path={`${path}/mail/:mailId`}>
                <Redirect
                  to={
                    isForeignCA
                      ? `/practice/crm/${groupId}/mail`
                      : `/admin/crm/${groupId}/mail`
                  }
                />
              </Route>
              <Route path={`${path}/mail`}>
                <DashboardLayout
                  header={
                    <Header
                      v2
                      title="Inbox"
                      right={
                        <>
                          <EntitySelector />
                        </>
                      }
                    />
                  }
                >
                  <Mailbox
                    userGroupId={data?.uuid}
                    hasMailroomSubscription={data?.has_mailroom_subscription}
                  />
                </DashboardLayout>
              </Route>
              <Route path={`${path}/documents/:docId`}>
                <Documents
                  userGroupId={data?.uuid}
                  groupDocID={data?.documents_group_id}
                  appUrl={url}
                />
              </Route>

              <Route path={`${path}/deadlines`}>
                <DeadlineAlarm />
              </Route>

              <Route exact path={`${path}/tax`}>
                <TaxAndCompliance />
              </Route>

              {!isForeignCA && (
                <Route path={`${path}/quotes`}>
                  <CrmQuotes />
                </Route>
              )}

              {!isForeignCA && (
                <Route path={`${path}/raise`}>
                  <Raise />
                </Route>
              )}

              <Route path={`${path}/auditlog`}>
                <AuditLogs />
              </Route>

              <Route path={`${path}/transfers`}>
                <Invoices />
              </Route>
            </Switch>
            {showNotes && (
              <CrmNotes
                needDateInput
                setLoad={refetch}
                showModal={showNotes}
                closeModal={closeNotesModal}
                clickedNote={clickedNote}
                refetch={refetch}
              />
            )}
          </DashboardContainer.Content>
        </DashboardContainer>
      </Async.Success>
    </Async.Root>
  );
};
