import { AppLayout } from "components/AppLayout/AppLayout";
import { StartFinancialClosingModal } from "components/FinancialClosing/StartFinancialClosingModal";
import { Accounting } from "components/icons/LeftNav/Books/Accounting";
import { DataSources as DataSourcesIcon } from "components/icons/LeftNav/Books/DataSources";
import { Customers as CustomersIcon } from "components/icons/LeftNav/Books/Customers";
import { Invoicing } from "components/icons/LeftNav/Books/Invoicing";
import { MonthlyClosing } from "components/icons/LeftNav/Books/MonthlyClosing";
import { Reports as ReportsIcon } from "components/icons/LeftNav/Books/Reports";
import { Transactions as TransactionsIcon } from "components/icons/LeftNav/Books/Transactions";
import { Vendors as VendorsIcon } from "components/icons/LeftNav/Books/Vendors";
import { Chat } from "components/icons/LeftNav/Chat";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { ProductAndServices as ProductAndServicesIcon } from "components/icons/LeftNav/Books/ProductAndServices";
import { Home } from "components/icons/LeftNav/Home";
import { Transfers } from "components/icons/LeftNav/Intragroup/Transfers";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { InkleBooks } from "components/icons/Logos/InkleBooks";
import { InkleBooksSymbol } from "components/icons/Logos/InkleBooksSymbol";
import { InkleTaxSymbolSmall } from "components/icons/Logos/InkleTaxSymbolSmall";
import { ReconciliationTxn } from "components/Reconciliation/ReconciliationTxn";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarApps,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import InitiateTransferModal from "components/TPPayments/TransferModal/InitiateTransferModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { Invoices } from "pages/TPPayments/Invoices";
import { parse, stringify } from "qs";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { BooksHome } from "./BooksDashboard/BooksHome";
import { DataSources } from "./DataSources/DataSources";
import { EntitySelector } from "../../components/EntitySelector/EntitySelector";
import { FinancialClosing } from "./FinancialClosing/FinancialClosing";
import { GeneralLedgerApp } from "./GeneralLedger/GeneralLedger";

import { InvoicesHome } from "./Invoicing/InvoicesHome";
import { Reports } from "./Reports/Reports";
import { Services } from "./Services/Services";
import { Transactions } from "./Transactions/Transactions";
import { Vendors } from "./Vendors/Vendors";
import { BookkeepingServices } from "components/icons/LeftNav/Books/BookkeepingServices";
import { ReportViewWithId } from "components/Reports/ReportViewById";
import { FixedAsset as FixedAssetIcon } from "components/icons/LeftNav/Books/FixedAsset";
import { FixedAsset } from "./FixedAsset/FixedAsset";
import { FOREIGN_CA } from "constants/onBoarding";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { useAuth } from "hooks/useAuth";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { GroupSelectorWithDefaultGroup } from "components/GroupSelector/GroupSelectorWithDefaultGroup";
import { BOOKS } from "constants/taskCategoryType";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { useAnalytics } from "hooks/useAnalytics";
import { CLICKED_INVOICE_ON_LEFT_NAV } from "constants/analyticsEvents";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { Upgrade } from "components/TopBar/Upgrade";
import { BOOKS_PLUS_PLAN } from "constants/addons";
import { MONTHLY } from "constants/billingCycles";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { useHistory } from "react-router-dom";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { InvoiceCustomerInfo } from "./Customers/InvoiceCustomerInfo";
import { Customers } from "./Customers/Customers";
import { ProductsAndServices } from "./ProductAndServices/ProductsAndServices";

const BooksNav = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { type } = useAuth();
  const { trackEvent } = useAnalytics();

  const { isCpa } = useRoleBasedView();
  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <>
      {type === FOREIGN_CA && (
        <div className="t-m-2">
          <GroupSelect />
        </div>
      )}

      <LeftBarItem exact to={search} icon={Home}>
        Home
      </LeftBarItem>

      <LeftBarItem to={`/transactions${search}`} icon={TransactionsIcon}>
        Transactions
      </LeftBarItem>
      <LeftBarItem to={`/data-sources${search}`} icon={DataSourcesIcon}>
        Data Sources
      </LeftBarItem>
      <LeftBarItem
        to={`/categories${search}&hideZero=${true}`}
        icon={Accounting}
      >
        Categories
      </LeftBarItem>

      <LeftBarItem to={`/customers${search}`} icon={CustomersIcon}>
        Customers
      </LeftBarItem>
      <LeftBarItem
        to={`/products-and-services${search}`}
        icon={ProductAndServicesIcon}
      >
        Products & Services
      </LeftBarItem>
      <LeftBarItem
        to={`/live-financials${
          search ? `${search}&` : "?"
        }reportType=BALANCE_SHEET`}
        icon={ReportsIcon}
      >
        Live Financials
      </LeftBarItem>
      <LeftBarItem to={`/services${search}`} icon={BookkeepingServices}>
        Bookkeeping Services
      </LeftBarItem>
      <LeftBarItem to={`/monthly-closing${search}`} icon={MonthlyClosing}>
        Monthly Closing
      </LeftBarItem>
      <LeftBarItem to={`/vendors${search}`} icon={VendorsIcon}>
        Vendors
      </LeftBarItem>
      <LeftBarItem to={`/invoicing${search}`} icon={Invoicing}>
        <div onClick={() => trackEvent(CLICKED_INVOICE_ON_LEFT_NAV)}>
          Invoicing
        </div>
      </LeftBarItem>
      <LeftBarItem to={`/fixed-assets${search}`} icon={FixedAssetIcon}>
        Fixed Assets
      </LeftBarItem>

      {isCpa && (
        <LeftBarItem to={`/transfers${search}`} icon={Transfers}>
          Transfers
        </LeftBarItem>
      )}

      {isCpa && (
        <>
          <div className="t-py-3">
            <LeftBarSeparator />
          </div>
          <LeftBarItem to="/tickets" icon={Ticket}>
            Tickets
          </LeftBarItem>
          <LeftBarItem to="/chat/unreplied" icon={Chat}>
            Chat
          </LeftBarItem>
        </>
      )}

      {!isCpa && (
        <>
          <div className="t-py-3">
            <LeftBarSeparator />
          </div>
          <ChatLeftNav to={`/chat${search}`} />
          <LeftBarItem to={`/action-items${search}`} icon={Ticket}>
            Action Items
          </LeftBarItem>
          <LeftBarItem to={`/documents${search}`} icon={DocumentsIcon}>
            Documents
          </LeftBarItem>
        </>
      )}

      <div className="t-mt-auto">
        <div className="t-py-3">
          <LeftBarSeparator name="Other Apps" />
        </div>
        {isCpa ? (
          <LeftBarItem
            useParentPath={false}
            to="/admin"
            icon={InkleTaxSymbolSmall}
          >
            Tax Admin
          </LeftBarItem>
        ) : (
          <LeftBarApps search={search} useExistingSearch current={url} />
        )}
      </div>
    </>
  );
};

export const BooksDashboard = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { isCpa, isCustomer } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { upgrade } = useUpgradePlan();
  const history = useHistory();

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const { data: bookkeepingSubscriptionPlans = [] } = useGetSubscriptionsQuery(
    {
      subscription_types: BOOKS_PLUS_PLAN,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const activeBooksPlusPlan = bookkeepingSubscriptionPlans?.find(
    ({ group_subscription = [] }) => group_subscription?.length > 0
  );

  const booksMonthlyPlusPlan = bookkeepingSubscriptionPlans?.find(
    (ele) => ele.billing_cycle === MONTHLY
  );

  const onUpgrade = () => {
    upgrade({ addon: booksMonthlyPlusPlan! });
    history.push(`/settings/billing?entity=${entityId}`);
  };
  return (
    <AppLayout
      sidebar={
        <LeftBar
          useInternalLink
          logo={
            <>
              <LeftBarLogo logo={InkleBooks} symbol={InkleBooksSymbol} />

              {isCpa && (
                <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                  <GroupSelectorWithDefaultGroup />
                  <EntitySelector />
                </div>
              )}
            </>
          }
        >
          <BooksNav />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={
            isCustomer && (
              <>
                {Boolean(activeBooksPlusPlan) ? undefined : (
                  <Upgrade onUpgrade={onUpgrade} />
                )}
              </>
            )
          }
          logo={
            <>
              <LeftBarLogo logo={InkleBooks} symbol={InkleBooksSymbol} />

              <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                {isCpa && <GroupSelectorWithDefaultGroup />}
                <EntitySelector right={0} />
              </div>
            </>
          }
          nav={
            <LeftBar logo={<></>}>
              <BooksNav />
            </LeftBar>
          }
        >
          {!isCpa && (
            <EntitySelector
              toolTipPlacement="bottom"
              right={0}
              disableNonUsEntities
              showGoToEntity
            />
          )}
        </TopBar>
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <Route
          path={`${path}/transactions/reconciliation/start/:bankAccountId`}
        >
          {(props) => (
            <Redirect
              to={`${path}/data-sources/reconciliation/start/${props.match?.params.bankAccountId}${search}`}
            />
          )}
        </Route>
        <Redirect
          from={`${path}/transactions/reconciliation`}
          to={`${path}/data-sources/reconciliation${search}`}
        />
        <Redirect
          from={`${path}/connections`}
          to={`${path}/data-sources${search}`}
        />
        <RestrictedRoute path={`${path}/transactions`}>
          <Transactions />
        </RestrictedRoute>
        <RestrictedRoute exact path={`${path}/services/addtask`}>
          <TemplateMenu
            to={`/books/services${search}`}
            taskCategoryType={BOOKS}
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/services/:taskId`}>
          <FilingDetails
            isFromServices
            parentRoute="/books/services"
            addEntityInUrl
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/services`}>
          <Services />
        </RestrictedRoute>
        <RestrictedRoute exact path={`${path}/reconciliation/:reconcilationId`}>
          <ReconciliationTxn />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/monthly-closing`}>
          <FinancialClosing />
        </RestrictedRoute>
        <RestrictedRoute exact path={`${path}/merchants`}>
          <Redirect to={`${url}/vendors/${search}`} />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/invoicing`}>
          <InvoicesHome />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/categories`}>
          <GeneralLedgerApp />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/data-sources`}>
          <DataSources />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/customers/:customerId`}>
          <InvoiceCustomerInfo
            breadcrumbs={[
              { name: "Customers", link: `${url}/customers${search}` },
            ]}
          />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/customers`}>
          <Customers />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/vendors`}>
          <Vendors />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/products-and-services`}>
          <ProductsAndServices />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/live-financial/:reportId`}>
          <ReportViewWithId />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/live-financials`}>
          <Reports />
        </RestrictedRoute>
        <Route path={`${path}/fixed-assets`}>
          <FixedAsset />
        </Route>
        <Redirect
          from={`${path}/accounting`}
          to={`${path}/categories${search}`}
        />
        <Redirect
          from={`${path}/reports`}
          to={`${path}/live-financials${search}`}
        />{" "}
        <Redirect from={`${path}/metrics`} to={`${path}${search}`} />
        <Redirect
          from={`${path}/report`}
          to={`${path}/live-financial${search}`}
        />
        <Redirect
          from={`${path}/report/:reportId`}
          to={`${path}/live-financial/:reportId${search}`}
        />
        {isCpa && (
          <RestrictedRoute path={`${path}/transfers/transfer/`}>
            <InitiateTransferModal to={`${path}/transfers/`} />
          </RestrictedRoute>
        )}
        {isCpa && (
          <RestrictedRoute path={`${path}/transfers/`}>
            <Invoices />
          </RestrictedRoute>
        )}
        <RestrictedRoute exact path={`${path}/dashboard`}>
          <Redirect to={path} />
        </RestrictedRoute>
        {isCustomer && (
          <RestrictedRoute exact path={`${path}/tickets`}>
            <Redirect to={`${path}/action-items`} />
          </RestrictedRoute>
        )}
        <RestrictedRoute exact path={`${path}/financial-closing`}>
          <Redirect to={`${path}/monthly-closing`} />
        </RestrictedRoute>
        <RestrictedRoute exact path={path} component={BooksHome} />
        <RoutesAccrossApps />
      </Switch>
      <StartFinancialClosingModal />
    </AppLayout>
  );
};
