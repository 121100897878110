import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ArrowDown } from "components/icons/ArrowDown";
import { Cross } from "components/icons/Cross";
import { Field, FieldProps, getIn } from "formik";
import React, { ReactNode } from "react";
import Select, {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  InputProps,
  MenuProps,
  MultiValueGenericProps,
  NoticeProps,
  OptionProps,
  PlaceholderProps,
  Props,
  SingleValueProps,
  ValueContainerProps,
  MenuListProps,
  GroupHeadingProps,
  MultiValueProps,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { Checkbox } from "../Checkbox/Checkbox";
import { Label } from "../TextInput/TextInput";
import ToolTip from "components/design/toolTip";

export type OptionData = {
  value: string;
  label: ReactNode;
  index?: number;
  data?: string;
  indent?: number;
};

const SingleValue = ({ children, ...props }: SingleValueProps<OptionData>) => (
  <components.SingleValue {...props}>
    <span className="t-text-body">{children}</span>
  </components.SingleValue>
);

const ToolTipElement = ({
  tooltip,
  label,
  children,
}: {
  tooltip: boolean;
  label: ReactNode;
  children: ReactNode;
}) => {
  if (tooltip) {
    return (
      <ConditionalToolTip condition={label}>{children}</ConditionalToolTip>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const GroupHeading = <T,>(props: GroupHeadingProps<T>) => (
  <components.GroupHeading
    {...props}
    className="t-bg-neutral-0 t-p-2 t-text-text-60 t-text-body"
  />
);

const Option = ({ children, ...props }: OptionProps<OptionData>) => {
  // @ts-ignore

  return (
    <components.Option
      {...props}
      isDisabled={props.isDisabled}
      className="[&.select\_\_option--is-focused]:t-bg-surface-lighter-grey [&.select\_\_option--is-selected]:t-bg-i-surface-light-purple [&.select\_\_option--is-disabled]:t-bg-neutral-0 [&.select\_\_option--is-disabled]:t-text-subtitle-sm [&.select\_\_option--is-disabled]:t-text-text-100 [&.select\_\_option--is-disabled]:t-cursor-not-allowed [&.select\_\_option--is-disabled]:t-opacity-50"
      getStyles={() => ({
        paddingLeft: props.data.indent ? `${props.data.indent * 1.5}rem` : "0",
      })}
    >
      <ToolTipElement
        label={props.data.data}
        //@ts-ignore
        tooltip={props.selectProps?.tooltip}
      >
        <div
          className={classNames(
            "t-truncate !t-border-none t-px-3 t-py-2.5 t-font-medium t-flex t-gap-2.5",
            {
              "t-cursor-not-allowed": props.isDisabled,
              "t-cursor-pointer ": !props.isDisabled,
            }
          )}
          aria-label={props.data.value}
          aria-selected={props.isSelected}
          role="option"
        >
          {props.isMulti && (
            <Checkbox
              name={props.data.value}
              checked={props.isSelected}
              disabled={props.isDisabled}
            />
          )}
          {children}
        </div>
      </ToolTipElement>
    </components.Option>
  );
};

const MultiValueContainer = ({
  children,
  ...props
}: MultiValueGenericProps<OptionData, boolean, GroupBase<OptionData>> & {
  isDisabled?: boolean;
}) => (
  <components.MultiValueContainer {...props}>
    <button
      type="button"
      disabled={props.data.isDisabled}
      className={classNames(
        "all:unset t-flex t-gap-1.5 t-items-center t-text-body-sm t-bg-neutral-0 t-rounded t-text-text-60 t-px-2 t-select-none t-h-6",
        {
          "t-cursor-not-allowed": props.data.isDisabled,
          "t-cursor-pointer": !props.data.isDisabled,
          "t-w-max": !(props.selectProps as SelectProps).multiValueShowLimit,
          "t-w-full": (props.selectProps as SelectProps).multiValueShowLimit,
        }
      )}
    >
      {children}
    </button>
  </components.MultiValueContainer>
);

const MenuList = ({ children, ...props }: MenuListProps<OptionData>) => {
  return (
    <components.MenuList {...props}>
      {children}

      {/* @ts-ignore // This is a custom prop sent to Select component as directed in documentation ref: https://react-select.com/components#defining-components */}
      {props.selectProps?.actions && (
        <div className="t-py-2 t-px-3 t-sticky t-bottom-0 t-bg-white">
          {/* @ts-ignore */}
          {props.selectProps?.actions}
        </div>
      )}
    </components.MenuList>
  );
};

const Menu = ({ children, ...props }: MenuProps<OptionData>) => {
  return (
    <components.Menu
      className="primary-border t-rounded-md t-bg-surface t-text-body t-drop-shadow-i-dropdown t-mt-2 t-overflow-auto"
      {...props}
    >
      {children}
    </components.Menu>
  );
};

const Input = (props: InputProps<OptionData>) => {
  return (
    <components.Input
      {...props}
      name={props.selectProps.name}
      id={props.selectProps.name}
      data-testid={props.selectProps.name}
    />
  );
};

const Control = ({ children, ...props }: ControlProps<OptionData>) => (
  <components.Control
    {...props}
    className={classNames(
      String.raw`[&.select\_\_control--is-focused]:t-border-purple-50 [&.select\_\_control--is-focused]:t-bg-surface t-border t-border-solid t-border-neutral-10 focus:t-border-purple t-px-3 t-rounded t-flex t-items-center t-bg-surface-lighter-grey t-justify-center [&.select\_\_control--is-disabled]:t-text-neutral-30 [&.select\_\_control--is-disabled]:t-bg-neutral-0`,
      {
        // @ts-ignore // Custom prop and important to override library
        "!t-min-h-8": props.selectProps.size === "small",
        // @ts-ignore
        "!t-min-h-10": props.selectProps.size === "regular",
        // @ts-ignore
        "!t-min-h-12": props.selectProps.size === "large",
        // @ts-ignore
        "t-border-red-50": props.selectProps.type === "error",
        "!t-cursor-pointer": !props.isDisabled && !props.isMulti,
      }
    )}
  >
    {children}
  </components.Control>
);

const ValueContainer = ({
  children,
  ...props
}: ValueContainerProps<OptionData>) => (
  <components.ValueContainer
    {...props}
    className={classNames("t-flex t-gap-1", {
      // @ts-ignore
      "t-py-0": props.selectProps.size === "small",
      // @ts-ignore
      "t-py-1.5": props.selectProps.size !== "small",
      // @ts-ignore
      "!t-flex-nowrap": props.selectProps.multiValueShowLimit,
    })}
  >
    {children}
  </components.ValueContainer>
);

const Placeholder = ({ children, ...props }: PlaceholderProps<OptionData>) => (
  <components.Placeholder {...props} className="t-text-body t-text-text-30">
    {children}
  </components.Placeholder>
);

const NoOptionsMessage = ({ children, ...props }: NoticeProps<OptionData>) => (
  <components.NoOptionsMessage {...props} className="t-p-3 t-px-2">
    {children}
  </components.NoOptionsMessage>
);

export const ClearIndicator = (props: ClearIndicatorProps<OptionData>) => (
  <components.ClearIndicator {...props}>
    <button type="button" className="all:unset t-mr-1" tabIndex={-1}>
      <Cross color="currentColor" />
    </button>
  </components.ClearIndicator>
);

const DropdownIndicator = (props: DropdownIndicatorProps<OptionData>) => (
  <components.DropdownIndicator {...props}>
    <button
      type="button"
      className={classNames(
        "all:unset t-transform t-transition t-duration-300 t-ease-in-out",
        {
          "-t-rotate-180": props.selectProps.menuIsOpen,
        }
      )}
      tabIndex={-1}
    >
      <ArrowDown strokeWidth={"1.5"} color="currentColor" />
    </button>
  </components.DropdownIndicator>
);

const MultiValueCustom = (props: MultiValueProps<OptionData>) => {
  const selectProps = props.selectProps as SelectProps;
  const value = props.getValue();

  const overflow = value
    .slice(selectProps.multiValueShowLimit)
    .map((x) => x.label);

  const length = overflow.length;
  const label = `+ ${length}`;

  if (!selectProps.multiValueShowLimit) {
    return <components.MultiValue className="t-overflow-hidden" {...props} />;
  }

  if (props.index < selectProps.multiValueShowLimit) {
    return <components.MultiValue className="t-overflow-hidden" {...props} />;
  }

  if (props.index === selectProps.multiValueShowLimit) {
    return (
      <ToolTip
        disableHoverableContent
        text={
          <div className="t-flex t-flex-col t-gap-1">
            {value.map((x, index) => (
              <div key={index} className="t-text-left">
                {x.label}
              </div>
            ))}
          </div>
        }
      >
        <span
          className={classNames(
            "all:unset t-flex t-gap-1.5 t-items-center t-text-body-sm t-bg-neutral-0 t-rounded t-text-text-60 t-w-max t-px-2 t-select-none t-h-6 t-shrink-0"
          )}
        >
          {label}
        </span>
      </ToolTip>
    );
  }

  return <></>;
};

type SelectProps =
  | ({
      creatable?: false;
      size?: "small" | "regular" | "large";
      actions?: ReactNode;
      type?: "error";
      label?: string;
      note?: string;
      fullWidth?: boolean;
      multiValueShowLimit?: number;
    } & Props<OptionData>)
  | ({
      creatable?: true;
      size?: "small" | "regular" | "large";
      actions?: ReactNode;
      type?: "error";
      label?: string;
      note?: string;
      fullWidth?: boolean;
      multiValueShowLimit?: number;
    } & CreatableProps<OptionData, boolean, GroupBase<OptionData>>);

type ComboboxWithFormProps = SelectProps & {
  block?: boolean;
  children?: ReactNode;
  name: string;
  hideError?: boolean;
  tooltip?: boolean;
  onChange?: SelectProps["onChange"];
};

const ComboboxWithForm = ({
  label,
  children,
  ...props
}: ComboboxWithFormProps) => (
  <div
    className={classNames("t-relative", {
      "t-block t-w-full": props.block,
    })}
  >
    {label && (
      <Label
        className="t-block t-truncate"
        htmlFor={props.name}
        required={props?.required}
      >
        {label}
      </Label>
    )}
    <Field {...props}>
      {({
        field,
        form: { errors, setFieldValue, values, touched, setFieldTouched },
      }: FieldProps<string[] | string, Record<string, string>>) => {
        const onChange: Props<OptionData>["onChange"] = (option, meta) => {
          setFieldTouched(props.name, true, true);
          props.onChange?.(option, meta);
          if (option instanceof Array) {
            setFieldValue(
              props.name,
              option.map((v) => v.value)
            );
            return;
          }

          setFieldValue(props.name, option?.value);
        };

        const error = getIn(errors, field.name);

        const isTouched = getIn(touched, field.name);

        return (
          <>
            <BareCombobox
              {...props}
              onChange={onChange}
              type={error && isTouched && "error"}
            />

            {!props.hideError && error && isTouched && (
              <div className="t-mt-1.5 t-text-caption t-text-red">{error}</div>
            )}
          </>
        );
      }}
    </Field>
  </div>
);

const BareCombobox = ({
  size = "regular",
  isClearable = true,
  label,
  note,
  ...props
}: SelectProps) => {
  const SelectComponent = props.creatable ? CreatableSelect : Select;

  return (
    <div
      className={classNames(`t-group/${size}`, {
        "t-cursor-not-allowed": props.isDisabled,
        "t-w-full": props.fullWidth,
      })}
    >
      {label && (
        <Label
          className="t-block t-truncate"
          htmlFor={props.name}
          required={props?.required}
        >
          {label}
        </Label>
      )}

      <SelectComponent
        {...props}
        placeholder={props.placeholder || "Select"}
        // @ts-ignore
        size={size}
        classNames={{
          menuPortal: () => "!t-z-dropdown t-pointer-events-auto",
          ...props.classNames,
        }}
        components={{
          SingleValue,
          Option,
          MenuList,
          MultiValueContainer,
          Control,
          Input,
          Menu,
          ValueContainer,
          Placeholder,
          ClearIndicator,
          DropdownIndicator,
          NoOptionsMessage,
          GroupHeading,
          MultiValue: MultiValueCustom,
          ...props.components,
        }}
        isClearable={isClearable}
        closeMenuOnSelect={!props.isMulti}
        hideSelectedOptions={false}
        unstyled
        classNamePrefix="select"
        backspaceRemovesValue={false}
        escapeClearsValue={false}
      />
      {note && (
        <span className="t-text-body-sm t-text-i-neutral-50">{note}</span>
      )}
    </div>
  );
};

export type ComboboxProps =
  | (SelectProps & {
      withForm?: false;
    })
  | (SelectProps &
      ComboboxWithFormProps & {
        withForm: true;
      });

export const Combobox = ({
  size = "regular",
  creatable = false,
  ...props
}: ComboboxProps) => {
  if (props.withForm) {
    return <ComboboxWithForm creatable={creatable} {...props} size={size} />;
  }

  return <BareCombobox creatable={creatable} {...props} size={size} />;
};
