import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import { NoProductAndServices } from "components/Illustrations/NoProductAndServices";
import { AddProductAndServicesModal } from "components/ProductAndServices/AddProductAndServicesModal";
import { ProductAndServicesList } from "components/ProductAndServices/ProductAndServicesList";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptyScreen } from "../EmptyScreen";
import { useGetProductAndServicesQuery } from "store/apis/productAndServices";

export const ProductsAndServices = () => {
  usePageTitle("Products And Services");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCpa } = useRoleBasedView();
  const addProductAndServicesModal = useModal();

  const { data, ...productAndServicesState } = useGetProductAndServicesQuery(
    {
      groupId,
      entityId,
      pageNum: 1,
    },
    {
      skip: !entityId || !groupId,
    }
  );

  const { onConnect, isLoading: isConnectBankLoading } = useBankConnect();
  const { stripeConnection, ...connectionState } = useGetAllConnections({
    groupId,
    entityId,
  });

  const isLoading =
    connectionState.isLoading || productAndServicesState.isLoading;
  const isSuccess =
    connectionState.isSuccess && productAndServicesState.isSuccess;

  return (
    <DashboardLayout header={<Header v2 title="Products & Services" />}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={data?.products.length === 0}
      >
        <Async.Empty>
          <EmptyScreen
            className="t-h-[70dvh]"
            text=""
            cta={
              <div className="t-flex t-gap-3">
                {stripeConnection &&
                  !stripeConnection?.is_connection_available && (
                    <Button
                      size="small"
                      isLoading={isConnectBankLoading}
                      disabled={isConnectBankLoading}
                      onClick={() =>
                        onConnect({
                          connectionId: stripeConnection?.uuid!,
                          invokedFrom: isCpa
                            ? `/books/products-and-services?company=${groupId}&entity=${entityId}`
                            : `/books/products-and-services?entity=${entityId}`,
                        })
                      }
                    >
                      Connect Stripe
                    </Button>
                  )}
                <Modal.Root
                  open={addProductAndServicesModal.isOpen}
                  onOpenChange={addProductAndServicesModal.toggle}
                  modal={false}
                >
                  <Modal.Trigger asChild>
                    <Button size="small" customType="primary">
                      Add Product/Service
                    </Button>
                  </Modal.Trigger>
                  <Modal.Content useCustomOverlay>
                    <AddProductAndServicesModal
                      {...addProductAndServicesModal}
                    />
                  </Modal.Content>
                </Modal.Root>
              </div>
            }
          >
            <div className="t-text-center t-flex t-gap-2 t-flex-col t-items-center">
              <NoProductAndServices />
              <div className="t-text-subtitle-sm t-text-text-60">
                No Products or Services yet
              </div>
              <div className="t-text-body-sm t-text-text-30">
                Get started by adding them here directly.
              </div>
            </div>
          </EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <ProductAndServicesList />
        </Async.Success>
      </Async.Root>
    </DashboardLayout>
  );
};
