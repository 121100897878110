import React, { ReactNode } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

const ToolTip = ({
  text,
  children,
  propClass = "t-max-w-[500px]",
  delayDuration = 0,
  disableHoverableContent = false,
  ...props
}: Tooltip.TooltipContentProps & {
  children: ReactNode;
  text?: string | ReactNode;
  delayDuration?: number;
  propClass?: string;
  disableHoverableContent?: boolean;
}) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
      >
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            {...props}
            className={`t-bg-neutral-100 t-text-surface t-p-3 t-rounded t-text-body-sm t-z-tooltip t-text-center ${propClass} t-break-all t-shadow-dark-30`}
          >
            {text}
            <Tooltip.Arrow />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
