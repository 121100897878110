import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Filter } from "components/DesignSystem/Filter/Filter";
import { TransactionsForWrapper } from "components/GeneralLedger/GeneralLedgerFilters";
import { Capsule } from "components/Transaction/Filter";
import { Divider } from "components/design/Divider";
import {
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  datePeriod,
} from "constants/bookkeeping";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { parse } from "qs";
import { ChangeEvent, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFilterStatus } from "store/selector/reportFilter";
import { FilterName, setReportFilters } from "store/slices/reportFilter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { ReportsType } from "pages/Books/Reports/Reports";
import { viewByOption } from "constants/reports";

const CapsuleFilters = ({ reportType }: { reportType: ReportsType }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { update, updateMultiple } = useUpdateQuery();
  const { capsuleFilters, getFilterName } = useSelector(getFilterStatus);
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const hideZero = query.get("hideZero");

  const prevReportTypeRef = useRef<string | null>(null);
  const prevReportType = prevReportTypeRef.current;

  const handleClick = ({ name }: { name: FilterName }) => {
    dispatch(setReportFilters({ [name]: undefined }));
    update({ query: name, value: null });
  };

  useEffect(() => {
    if (!endDate && !startDate && !hideZero) {
      updateMultiple([
        {
          query: "startDate",
          value: dayjs().startOf("year").format(YYYY_MM_DD),
        },
        {
          query: "endDate",
          value: dayjs().format(YYYY_MM_DD),
        },
        { query: "hideZero", value: true },
        {
          query: "accountingMethod",
          value: reportType === CASH_FLOW_STATEMENT ? "ACCRUAL" : "CASH",
        },
      ]);
    } else if (prevReportType === null || reportType !== prevReportType) {
      updateMultiple([
        {
          query: "accountingMethod",
          value: reportType === CASH_FLOW_STATEMENT ? "ACCRUAL" : "CASH",
        },
      ]);
    }
    prevReportTypeRef.current = reportType;
  }, [endDate, startDate, hideZero, reportType]);

  return (
    <>
      {capsuleFilters.map(({ name, value, type }) => {
        let filterValue = value;
        if (type === "reportDate" && typeof value === "string") {
          filterValue = dayjs(value).format(DD_MMM_YYYY);
        } else if (Array.isArray(value)) {
          filterValue = `(${value.length})`;
        }

        return (
          <Capsule
            key={name}
            value={filterValue}
            onCapsuleClick={() => {
              if (
                !(
                  (type === "reportDate" || type === "accounting") &&
                  typeof value === "string"
                )
              ) {
                handleClick({ name: getFilterName(name)! as FilterName });
              }
            }}
            isRemovable={
              type === "reportDate" || type === "accounting" ? false : true
            }
            type={type}
          >
            {name}
          </Capsule>
        );
      })}
    </>
  );
};

const DateFilter = () => {
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const initialValues = {
    startDate: startDate.value ? new Date(startDate.value as string) : "",
    endDate: endDate.value ? new Date(endDate.value as string) : "",
  };

  return (
    <div className="t-flex t-flex-col t-gap-3 t-w-full">
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        <TransactionsForWrapper queryUpdate>
          {({ handleChange, handleDateChange }) => (
            <Form className="all:unset t-flex t-flex-col t-gap-4">
              <Combobox
                withForm
                name="transactionsFor"
                label="Show financials for"
                placeholder="Select period"
                onChange={handleChange}
                components={{ ClearIndicator: () => null }}
                options={datePeriod}
                block
              />
              <Divider />
              <Field name="startDate">
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      {...field}
                      maxDate={new Date()}
                      label="From"
                      placeholder={DD_MMM_YYYY}
                      onDateChange={(date) => {
                        handleDateChange({ date, name: field.name });
                      }}
                      portalId="report-start"
                    />
                  );
                }}
              </Field>
              <Field name="endDate">
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      {...field}
                      maxDate={new Date()}
                      label="To"
                      placeholder={DD_MMM_YYYY}
                      onDateChange={(date) => {
                        handleDateChange({ date, name: field.name });
                      }}
                      portalId="report-end"
                    />
                  );
                }}
              </Field>
            </Form>
          )}
        </TransactionsForWrapper>
      </Formik>
    </div>
  );
};

const AccountingMethod = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = parse(search, { ignoreQueryPrefix: true });
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [accountingMethod] = filters.accountingMethod;

  const option = [
    { value: "CASH", label: "Cash method" },
    { value: "ACCRUAL", label: "Accrual method" },
  ];

  const onChange = (value: string) => {
    dispatch(setReportFilters({ accountingMethod: value }));
    update({
      query: "accountingMethod",
      value: value,
    });
  };

  return (
    <Radio.Root
      disabled={query.reportType === CASH_FLOW_STATEMENT ? true : false}
      defaultValue={accountingMethod?.value?.toString().toUpperCase() as string}
      onValueChange={onChange}
    >
      <Radio.Content className="t-gap-3 t-flex t-flex-col">
        {option.map((item) => (
          <Radio.Item key={item.value} value={item.value}>
            {item.label}
          </Radio.Item>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

const Others = () => {
  const { filters } = useSelector(getFilterStatus);
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const [hideZero] = filters.others;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      update({ query: "hideZero", value: true });
    } else {
      update({ query: "hideZero", value: null });
      dispatch(setReportFilters({ hideZero: undefined }));
    }
  };

  return (
    <>
      <Checkbox
        name="hideZero"
        label="Hide zero balance accounts"
        onChange={onChange}
        checked={Boolean(hideZero?.value)}
      />
    </>
  );
};

const ViewBy = () => {
  const dispatch = useDispatch();
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [view] = filters.view;

  const onChange = (value: string) => {
    dispatch(setReportFilters({ view: value }));
    update({
      query: "view",
      value: value,
    });
  };

  return (
    <Radio.Root
      defaultValue={view?.value?.toString().toUpperCase() as string}
      onValueChange={onChange}
    >
      <Radio.Content className="t-gap-3 t-flex t-flex-col">
        {viewByOption.map((item) => (
          <Radio.Item key={item.value} value={item.value}>
            {item.label}
          </Radio.Item>
        ))}
      </Radio.Content>
    </Radio.Root>
  );
};

export const ReportFilter = ({ reportType }: { reportType: ReportsType }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { appliedFilterCount } = useSelector(getFilterStatus);

  useEffect(() => {
    const editQuery = { ...query };
    delete editQuery.company;
    delete editQuery.entity;
    delete editQuery.page;
    delete editQuery.reportType;
    delete editQuery.category;
    dispatch(setReportFilters(editQuery));
  }, [search]);

  return (
    <div className="t-flex t-flex-wrap t-gap-2">
      <Filter.Root
        defaultValue="reportDate"
        title={
          <span className="t-text-body t-font-medium t-leading-none">
            Filters {appliedFilterCount ? <>({appliedFilterCount})</> : ""}
          </span>
        }
        capsule={<CapsuleFilters reportType={reportType} />}
      >
        <Filter.Portal>
          <Filter.List>
            <Filter.ListItem value="reportDate">Date</Filter.ListItem>
            <Filter.ListItem value="view">View</Filter.ListItem>
            <Filter.ListItem value="accounting">
              Accounting method
            </Filter.ListItem>
            <Filter.ListItem value="others">Others</Filter.ListItem>
          </Filter.List>
          <Filter.Body value="reportDate" block>
            <DateFilter />
          </Filter.Body>
          <Filter.Body value="view" block>
            <ViewBy />
          </Filter.Body>
          <Filter.Body value="accounting" block>
            <AccountingMethod />
          </Filter.Body>
          <Filter.Body value="others" block>
            <Others />
          </Filter.Body>
        </Filter.Portal>
      </Filter.Root>
    </div>
  );
};
