import cx from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import PaymentEngine from "components/fileTax/step2";
import AddTaskEntityModal from "components/tasks/modal/AddTaskEntityModal";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useUpdateDeadlineFilingMutation } from "store/apis/calendar";
import { useGetTaskQuery } from "store/apis/task";
import { setShowPricingModal } from "store/slices/task";
import { ComplianceFilingStatus } from "types/Models/calendar";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { openLink } from "utils/openLink";
import { DeadlineBadge } from "./DeadlineBadge";

type DeadlineCompProps = {
  date?: string;
  title?: string;
  deadlineStatus?: {
    displayName: string;
    type: ComplianceFilingStatus;
  };
  description?: string;
  extension?: string | null;
  plan?: string;
  taskId?: string;
  taskTemplateId?: string;
  deadlinePassed: boolean;
  uuid: string;
};

export const NewDeadlineCard = ({
  date,
  title,
  deadlineStatus,
  extension,
  taskId,
  description,
  taskTemplateId,
  deadlinePassed,
  uuid,
}: DeadlineCompProps) => {
  const [taskIdForDetail, setTaskIdForDetail] = useState("");
  const addTaskModal = useModal();
  const dispatch = useDispatch();
  const {
    data: task,
    isLoading,
    refetch,
    isSuccess,
  } = useGetTaskQuery(
    { taskId: taskIdForDetail! },
    { skip: !taskIdForDetail, refetchOnMountOrArgChange: true }
  );
  const [updateDeadline, { isLoading: isUpdating }] =
    useUpdateDeadlineFilingMutation();
  const { alertToast, successToast } = useToast();
  const isFiledOutsideInkle = deadlineStatus?.type === "OUTSIDE_INKLE";

  const mainId = !Boolean((task?.state as { uuid: string })?.uuid)
    ? task?.task_states?.[0].uuid
    : (task?.state as { uuid: string })?.uuid;

  const updateDeadlineFile = async () => {
    try {
      await updateDeadline({ alarmId: uuid });
      successToast({ title: "Filing marked  successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const refetchTask = async () => await refetch().unwrap();

  return (
    <div className="t-border t-border-solid t-border-neutral-10 t-rounded-lg">
      <div className="t-group t-relative t-flex t-items-start t-gap-3 t-bg-white t-p-4 md:t-items-center md:t-gap-5 t-rounded-t-lg">
        {date && (
          <>
            <div className="t-hidden t-h-[70px] t-shrink-0 t-grow-0 t-basis-[67px] md:t-block" />
            <AnimatePresence>
              <motion.div
                key={date}
                transition={{ duration: 0.45, delay: -0.1 }}
                initial={{ x: 30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -30, opacity: 0 }}
                className={cx(
                  "t-absolute t-hidden t-h-[70px] t-w-[67px] t-shrink-0 t-grow-0 t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-neutral-0 md:t-flex"
                )}
              >
                <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                  {dayjs(date).format("DD MMM")}
                </span>
                <span className="t-text-body-sm t-uppercase">
                  {dayjs(date).format("YYYY")}
                </span>
              </motion.div>
            </AnimatePresence>
          </>
        )}

        <div className="t-grow t-overflow-hidden">
          <div className="t-flex md:t-items-center md:t-gap-5 t-justify-between">
            <div className="t-w-2/3 t-flex-col t-gap-1 t-space-y-2 md:t-flex md:t-space-y-0">
              <div className="t-text-subtitle t-text-text-60">{title}</div>

              {date && (
                <div className="t-flex t-items-start t-gap-2">
                  <div className="t-inline-flex t-hidden t-rounded t-bg-purple-0 t-px-2 t-py-0.5 t-text-body-sm t-text-purple-50 sm:t-block ">
                    {formatDate(date)}
                  </div>
                </div>
              )}
              <div className="t-flex t-items-baseline t-gap-3">
                {description && (
                  <p
                    className="t-m-0 t-max-w-[320px] t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-line-clamp-1 t-text-body-sm t-tracking-normal md:t-text-body"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                )}

                {description && (
                  <Button
                    customType="link"
                    onClick={() => {
                      if (taskId) {
                        setTaskIdForDetail(taskId);
                        dispatch(setShowPricingModal(true));
                      } else {
                        addTaskModal.open();
                      }
                    }}
                    size="small"
                    disabled={
                      (!taskId && !taskTemplateId) || isFiledOutsideInkle
                    }
                  >
                    <div className="!t-w-max">View more</div>
                  </Button>
                )}
              </div>
            </div>
            <div className="t-block t-text-body sm:t-hidden">
              {deadlineStatus && deadlineStatus?.displayName && (
                <DeadlineBadge type={deadlineStatus?.type}>
                  {deadlineStatus?.displayName}
                </DeadlineBadge>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="t-p-4 t-rounded-b-lg t-bg-surface-lighter-grey t-flex t-justify-between t-items-center">
        {extension && (
          <div
            className="t-text-body-sm t-text-red-50"
            dangerouslySetInnerHTML={{ __html: extension }}
          />
        )}
        <div className="t-flex t-gap-2 t-ml-auto">
          {taskId ? (
            <Button size="small" onClick={() => openLink(`/filings/${taskId}`)}>
              Go to filing
            </Button>
          ) : (
            <>
              {!deadlinePassed && (
                <Button
                  size="small"
                  onClick={updateDeadlineFile}
                  isLoading={isUpdating}
                  disabled={isUpdating || isFiledOutsideInkle}
                >
                  Filed externally
                </Button>
              )}
              {taskTemplateId && !isFiledOutsideInkle && (
                <Button
                  disabled={deadlinePassed}
                  size="small"
                  customType="primary"
                  onClick={() => addTaskModal.open()}
                >
                  Launch
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {addTaskModal.isOpen && (
        <AddTaskEntityModal
          to="/new-deadlines"
          taskTemplateId={taskTemplateId}
          handleClose={addTaskModal.close}
          disableLaunch={deadlinePassed}
        />
      )}
      {isSuccess && !isLoading && taskId === taskIdForDetail && (
        <PaymentEngine
          pricingData={task?.subtitle}
          pricingAmount={task?.payment_amount}
          next={undefined}
          title={title}
          mainId={mainId}
          groupId={task?.group_uuid}
          task={task}
          isArchived={task?.is_archived}
          updateAssignee={refetchTask}
          refetchTask={refetchTask}
          isFromServices={false}
          onClose={setTaskIdForDetail}
        />
      )}
    </div>
  );
};
