import { Button } from "components/DesignSystem/Button/Button";
import { useBillingtag } from "hooks/useBillingtag";
import { useState } from "react";
import MapPinLine from "static/images/MapPinLine.svg";
import WarningCircle from "static/images/WarningCircle.svg";
import { AddressAutofill } from "types/Models/addressAutofill";
import EditBillingAddress from "./EditBillingAddress";

const PaymentBillingAddress = ({
  defaultAddress,
  entityId,
}: {
  defaultAddress?: AddressAutofill;
  entityId: string;
}) => {
  const { uuid: tagId } = useBillingtag();
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  return (
    <>
      {Boolean(defaultAddress?.autofill_string) ? (
        <div className="t-flex t-justify-between t-border-solid t-border-surface-grey t-bg-surface-lighter-grey t-rounded t-pl-3 t-py-2 t-pr-5 t-border">
          <div className="t-flex t-gap-2 t-items-center t-w-3/5">
            <img src={MapPinLine} alt="MapPinLine" />
            <div className="t-w-full">
              <div className="t-text-subtext t-text-text-60 t-truncate">
                {defaultAddress?.autofill_string}
              </div>
              <div className="t-text-body-sm t-text-neutral t-truncate">
                {defaultAddress?.country}
              </div>
            </div>
          </div>
          <Button
            size="small"
            customType="link"
            onClick={() => setShowBillingAddress(true)}
          >
            Change
          </Button>
        </div>
      ) : (
        <div className="t-py-2 t-flex t-justify-between t-items-center t-bg-red-10 t-border t-border-solid t-border-red-10 t-px-3 t-rounded">
          <div className="t-flex t-gap-2 t-items-center">
            <img src={WarningCircle} alt="info" />
            <div className="t-text-body t-text-text-100">
              Billing address missing
            </div>
          </div>
          <Button size="small" onClick={() => setShowBillingAddress(true)}>
            Add
          </Button>
        </div>
      )}
      {showBillingAddress && (
        <EditBillingAddress
          entityId={entityId}
          show={showBillingAddress}
          closeModal={() => setShowBillingAddress(false)}
          currentBillingAddress={
            defaultAddress || {
              country: "",
              street_address: "",
              state: "",
              city: "",
              zipcode: "",
              entity_id: "",
            }
          }
          tagId={tagId}
        />
      )}
    </>
  );
};

export default PaymentBillingAddress;
