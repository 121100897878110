import React from "react";

export const Customers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3665_8212)">
        <path
          d="M5.25 10C7.04493 10 8.5 8.54493 8.5 6.75C8.5 4.95507 7.04493 3.5 5.25 3.5C3.45507 3.5 2 4.95507 2 6.75C2 8.54493 3.45507 10 5.25 10Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.639404 12.5C1.13884 11.7321 1.82215 11.1011 2.62732 10.6643C3.43249 10.2274 4.33401 9.99866 5.25003 9.99866C6.16605 9.99866 7.06757 10.2274 7.87274 10.6643C8.67791 11.1011 9.36122 11.7321 9.86065 12.5"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.75 10C11.666 9.99946 12.5676 10.2279 13.3728 10.6645C14.178 11.1011 14.8613 11.7321 15.3606 12.5"
          fill="currentColor"
        />
        <path
          d="M10.75 10C11.666 9.99946 12.5676 10.2279 13.3728 10.6645C14.178 11.1011 14.8613 11.7321 15.3606 12.5"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.54321 3.73125C9.98779 3.55393 10.466 3.477 10.9438 3.50595C11.4215 3.5349 11.887 3.66901 12.3069 3.89871C12.7268 4.1284 13.0908 4.44801 13.3729 4.83469C13.655 5.22138 13.8481 5.66559 13.9386 6.13559C14.0291 6.60558 14.0147 7.08977 13.8963 7.55353C13.778 8.0173 13.5587 8.44921 13.2541 8.81839C12.9494 9.18758 12.5671 9.48492 12.1342 9.68918C11.7013 9.89344 11.2287 9.99958 10.7501 10"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3665_8212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
