import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Button } from "components/DesignSystem/Button/Button";
import { REGISTERED_AGENT } from "constants/addons";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import {
  setAddonData,
  setAddonType,
  setCloseCheckoutModal,
} from "store/slices/addons";
import { Subscription } from "types/Models/subscription";
import { AddonsModal } from "components/Addons/AddonsModal";
import { RootState } from "store/store";
import { LargeCard } from "components/home/Home";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

export const RAHomeCard = ({ isRASubscribed }: { isRASubscribed: boolean }) => {
  const { showCheckoutModal, addonPayload, addonType } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();

  const selectedSubscription = allSubscriptions?.find(
    ({ uuid }) => uuid === addonPayload?.uuid
  );

  const registeredAgents = allSubscriptions.filter(
    (ele) => ele.subscription_type === REGISTERED_AGENT
  );

  const registeredAgentMinAmount = registeredAgents?.reduce((min, obj) => {
    return Number(obj?.amount) < Number(min)
      ? Number(obj?.amount)
      : Number(min);
  }, Number(registeredAgents?.[0]?.amount));

  const registeredAgentStateMappings =
    registeredAgents
      ?.filter((ele) => ele.group_subscription)
      ?.map((ele) => {
        return {
          amount: ele?.amount,
          selectedStates: ele?.group_subscription,
          type: ele?.metadata?.ra_state,
          uuid: ele?.uuid,
        };
      }) || [];

  const registeredAgentUnselectedStates = registeredAgents
    ?.filter((ele) => !ele.group_subscription)
    ?.map((ele) => {
      return {
        amount: ele?.amount,
        type: ele?.metadata?.ra_state,
        uuid: ele?.uuid,
      };
    });

  const registeredAgent = {
    amount: registeredAgentMinAmount,
    billing_cycle: "MONTHLY",
    description:
      "Registered Agent service for all states of the United States of America",
    subscription_name: "Registered Agent",
    subscription_type: REGISTERED_AGENT,
    ...(registeredAgentStateMappings?.length > 0
      ? { group_subscription: registeredAgentStateMappings }
      : {}),
    unselectedStates: registeredAgentUnselectedStates,
  };

  const { link } = useConstructInternalLink();

  const openRAAddon = () => {
    if (isRASubscribed) {
      history.push(link(`${url}/registered-agent`));
      return;
    }

    dispatch(setAddonData(registeredAgent));
    dispatch(setAddonType(REGISTERED_AGENT));
  };

  const closeCheckoutModal = () => {
    dispatch(setCloseCheckoutModal());
  };

  const btnText = isRASubscribed ? "View more" : "Get started";

  return (
    <>
      <div className="t-pb-8 t-grid t-gap-6 t-flex-wrap md:t-grid-cols-[repeat(auto-fill,minmax(462px,1fr))] t-grid-cols-[repeat(auto-fill,1fr)]">
        <LargeCard>
          <div
            role="button"
            onClick={openRAAddon}
            className="t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-0 t-h-full t-w-full t-bg-[url('static/images/RegisteredAgentCardBG.svg')] t-bg-no-repeat t-bg-center"
          >
            <div className="t-h-full t-w-1/2 t-flex t-flex-col t-justify-between">
              <div className="t-w-full">
                <div className="t-text-subtitle t-text-text-100">
                  Inkle Registered Agent
                </div>
                <span className="t-text-body t-text-text-30 t-mb-10">
                  Manage your registered agent services for all states.
                </span>
              </div>
              <div>
                <Button size="small">{btnText}</Button>
              </div>
            </div>
          </div>
        </LargeCard>
      </div>

      {(addonType === REGISTERED_AGENT ||
        addonPayload?.type === REGISTERED_AGENT) && <AddonsModal />}

      {showCheckoutModal &&
        (addonType === REGISTERED_AGENT ||
          addonPayload?.type === REGISTERED_AGENT) && (
          <CheckoutModal
            entityId={entityId}
            type="subscription"
            title="Subscription"
            onClose={closeCheckoutModal}
            open={showCheckoutModal}
            subscription={selectedSubscription as Subscription}
            onSubscribed={closeCheckoutModal}
          />
        )}
    </>
  );
};
