import React from "react";

//components
import SavedCardItem from "./SavedCardItem";

const SavedCards = ({ savedCards, selectedCard, setSelectedCard }) => {
  return (
    <div className="t-mt-4">
      <ul className="savedCardsList t-mb-0 t-p-0 t-list-none t-max-h-40 t-overflow-auto">
        {savedCards.map((savedCard) => (
          <SavedCardItem
            key={savedCard.payment_method_id}
            card={savedCard}
            isSelected={selectedCard === savedCard.payment_method_id}
            setSelectedCard={setSelectedCard}
            selectedCard={selectedCard}
          />
        ))}
      </ul>
    </div>
  );
};

export default SavedCards;
