import { Loader } from "components/DesignSystem/Loader/Loader";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useConfirmConnectionMutation } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

export const ConnectionConfirmation = () => {
  const [confirmConnection, { isLoading: isConfirming }] =
    useConfirmConnectionMutation();
  const history = useHistory();
  const query = useQuery();
  const { bank } = useParams<{ bank: string }>();
  const { alertToast, successToast } = useToast();

  const code = query.get("code");
  const state = query.get("state");

  const authenticateCode = useCallback(
    async ({
      groupId,
      entityId,
      connectionId,
      code,
    }: {
      groupId: string;
      entityId: string;
      connectionId: string;
      code: string;
    }) => {
      if (isConfirming) {
        return;
      }

      try {
        return await confirmConnection({
          groupId,
          connectionId,
          entityId,
          code,
        }).unwrap();
      } catch (error) {
        throw new Error((error as BackendError).data?.error?.message);
      }
    },
    []
  );

  useEffect(() => {
    const authenticate = async (code: string) => {
      const stateKey = localStorage.getItem("connection_oauth_state_key");
      try {
        if (stateKey && state === stateKey) {
          const localState = localStorage.getItem(stateKey);

          if (localState) {
            const localStateObj = JSON.parse(localState) as {
              entityId: string;
              groupId: string;
              connectionId: string;
              invokedFrom: string;
            };

            await authenticateCode({
              ...localStateObj,
              code,
            });
            successToast({
              message: `${capitalizeFirstLetter(bank)} is Connected`,
            });

            localStorage.removeItem("connection_oauth_state_key");

            if (localStateObj?.invokedFrom) {
              history.replace(localStateObj?.invokedFrom);
              localStorage.removeItem(stateKey);
              return;
            }

            window.opener.postMessage("CONNECTION_AUTHENTICATION_SUCCESS");
          }
        }

        throw new Error("Failed to authenticate, please contact support!");
      } catch (error: any) {
        alertToast({ message: error.message });
        if (stateKey && state === stateKey) {
          const localState = localStorage.getItem(stateKey);

          if (localState) {
            const localStateObj = JSON.parse(localState) as {
              entityId: string;
              groupId: string;
              connectionId: string;
              invokedFrom?: string;
            };

            if (localStateObj?.invokedFrom) {
              history.replace(localStateObj?.invokedFrom);
            }
          }

          window.opener.postMessage("CONNECTION_AUTHENTICATION_FAILED");
        }
      }
    };

    if (code) {
      authenticate(code);
    }
  }, [authenticateCode, code, state]);

  return (
    <div className="t-h-screen t-w-screen t-flex t-justify-center t-items-center">
      <div className="t-animate-spin">
        <Loader />
      </div>

      <p className="t-m-5">Confiming integration with {bank}...</p>
    </div>
  );
};
