import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Link } from "components/DesignSystem/Link/Link";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { Search } from "components/DesignSystem/Search/Search";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Chat } from "components/icons/LeftNav/Chat";
import { Redirect } from "components/icons/Redirect";
import { SolidCaretDown } from "components/icons/SolidCaretDown";
import TaskDetailArchive from "components/icons/TaskDetailArchive";
import TaskDetailUnarchive from "components/icons/TaskDetailUnarchive";
import { TaskSlider } from "components/TaskSlider/TaskSlider";
import { TaskStatus } from "components/TaskStatus/TaskStatus";
import { TASK_LIST_YEAR_ACCORDION_OPENED } from "constants/analyticsEvents";
import { ARCHIVED, COMPLETED, ONGOING } from "constants/task";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { MouseEvent, useContext, useMemo, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import SearchIllustration from "static/images/Search.svg";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
  useTaskYearWiseCountsQuery,
  useUpdateTaskArchivalMutation,
} from "store/apis/taskList";
import { Task } from "types/Models/task";

const FilingCard = ({
  filing,
  onClick,
  onChatClick,
}: {
  filing: Task & { subRows?: Task[] };
  onClick: (taskId: string) => void;
  onChatClick: (taskId: string) => void;
}) => {
  const { isCustomer } = useRoleBasedView();
  const [updateTaskArchival, { isLoading: isArchiveUpdating }] =
    useUpdateTaskArchivalMutation();
  const { alertToast, successToast } = useToast();
  const { uuid, display_text, title, deadline } = filing;

  const updateTaskArchivalStatus = async ({
    e,
    uuid,
  }: {
    e: MouseEvent<HTMLButtonElement>;
    uuid: string;
  }) => {
    try {
      e.stopPropagation();
      const { is_archived } = await updateTaskArchival({
        taskId: uuid,
      }).unwrap();
      successToast({
        message: is_archived ? "Filing Archived" : "Filing Unarchived",
      });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <div>
      <div
        className="t-border t-border-solid t-border-neutral-10 t-rounded-lg t-cursor-pointer t-transition-shadow hover:t-shadow-light-30 t-z-10 t-relative"
        role="button"
        onClick={() => onClick(uuid)}
      >
        <div className="t-group t-relative t-flex t-items-start t-gap-3 t-bg-white t-p-4 md:t-items-center md:t-gap-5 t-rounded-t-lg">
          <div className="t-hidden t-h-[70px] t-shrink-0 t-grow-0 t-basis-[67px] md:t-block" />
          <div
            className={classNames(
              "t-absolute t-hidden t-h-[70px] t-w-[67px] t-shrink-0 t-grow-0 t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-neutral-0 md:t-flex"
            )}
          >
            {deadline ? (
              <>
                <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                  {dayjs(deadline).format("DD MMM")}
                </span>
                <span className="t-text-body-sm t-uppercase">
                  {dayjs(deadline).format("YYYY")}
                </span>
              </>
            ) : (
              <span className="t-block t-whitespace-nowrap t-text-body t-font-bold">
                -
              </span>
            )}
          </div>

          <div className="t-grow t-overflow-hidden">
            <div className="t-flex md:t-items-center md:t-gap-5 t-justify-between">
              <div className="t-w-2/3 t-flex-col t-gap-1 t-space-y-2 md:t-flex md:t-space-y-0">
                <div className="t-text-subtitle-sm t-text-text-60">{title}</div>
                <div className="t-flex t-items-baseline t-gap-3">
                  {display_text && (
                    <p
                      className="t-m-0 t-overflow-ellipsis t-line-clamp-1 t-text-body-sm t-tracking-normal md:t-text-body"
                      dangerouslySetInnerHTML={{ __html: display_text }}
                      title={display_text}
                    />
                  )}
                </div>
              </div>
              <div className="t-text-body sm:t-hidden t-flex t-gap-1">
                <TaskStatus
                  status={filing.status}
                  state={filing.state}
                  statusType={filing?.state_details?.type}
                />

                {filing.is_archived && !isCustomer && (
                  <Tag icon={false} tagType="gray">
                    Archived
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="t-p-4 t-rounded-b-lg t-bg-surface-lighter-grey t-flex t-justify-between t-items-center">
          <div className="t-flex t-gap-2 t-ml-auto">
            {uuid && (
              <>
                {!isCustomer && (
                  <ToolTip text={filing.is_archived ? "Unarchive" : "Archive"}>
                    <Button
                      isLoading={isArchiveUpdating}
                      size="small"
                      disabled={isArchiveUpdating}
                      customType="icon"
                      onClick={(e) => updateTaskArchivalStatus({ e, uuid })}
                    >
                      {filing.is_archived ? (
                        <span className="t-text-text-30">
                          <TaskDetailUnarchive color="currentColor" />
                        </span>
                      ) : (
                        <span className="t-text-text-30">
                          <TaskDetailArchive color="currentColor" />
                        </span>
                      )}
                    </Button>
                  </ToolTip>
                )}

                <Button
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChatClick(uuid);
                  }}
                >
                  <span className="t-flex t-gap-1 t-items-center t-text-text-60">
                    <span>Chat</span>
                    <span className="t-flex t-text-text-30">
                      <Chat />
                    </span>
                  </span>
                </Button>

                <Link
                  to={`/tax/filings/${uuid}`}
                  target={uuid}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button size="small">
                    <span className="t-flex t-gap-1 t-items-center t-text-text-60">
                      <span>Go to filing</span>
                      <span className="t-flex t-text-text-30">
                        <Redirect size={16} color="currentColor" />
                      </span>
                    </span>
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      {filing.subRows && filing.subRows.length > 0 && (
        <div className="t-ml-5">
          {filing.subRows.map((subFiling, index) => (
            <div className="t-flex t-gap-2.5" key={subFiling.uuid}>
              <div className="t-w-8 t-shrink-0">
                <div className="t-h-[110%] t-relative t-bottom-[60%] t-border-solid t-border t-border-neutral-10 t-border-t-0 t-border-r-0 t-rounded-bl"></div>
              </div>
              <div className="t-grow t-pt-2">
                <FilingCard
                  filing={subFiling}
                  key={subFiling.uuid}
                  onClick={onClick}
                  onChatClick={onChatClick}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const STATUS_QUERY_MAP = {
  [ARCHIVED]: "showArchive",
  [ONGOING]: "showOngoing",
  [COMPLETED]: "showFinished",
};

type STATUS_TYPES = keyof typeof STATUS_QUERY_MAP;

const getQueryFilter = (status: STATUS_TYPES[]) => {
  return status.reduce((acc, status) => {
    return {
      ...acc,
      [STATUS_QUERY_MAP[status]]: true,
    };
  }, {});
};

const FilingsAccordion = ({
  filters,
  season,
}: {
  filters: {
    STATUS: STATUS_TYPES[];
    SEARCH: string;
  };
  season: string;
}) => {
  const [openedBy, setOpenedBy] = useState<"CHAT" | "DETAILS" | null>(null);
  const [taskId, setTaskId] = useState<string | null>(null);
  const group = useCurrentGroupContext();

  const { isAnyServiceUser, isCustomer } = useRoleBasedView();

  const queryFilter = getQueryFilter(filters.STATUS);

  const { data: userTaskData, isLoading: isUserTaskLoading } =
    useGetTaskListForUserQuery(
      {
        ...queryFilter,
        searchTerm: filters.SEARCH,
        seasons: season,
      },
      { skip: isAnyServiceUser, refetchOnMountOrArgChange: true }
    );

  const { data: cpaTaskList, isLoading: isCPATaskLoading } =
    useGetTaskListForServiceTeamQuery(
      {
        ...queryFilter,
        searchTerm: filters.SEARCH,
        groupId: group.uuid,
        seasons: season,
      },
      { skip: isCustomer || !group.uuid, refetchOnMountOrArgChange: true }
    );

  const { tasks: taskList = [] } = userTaskData || cpaTaskList || {};

  const onFilingClick = (fillinId: string) => {
    setTaskId(fillinId);
    setOpenedBy("DETAILS");
  };

  const onFilingChatClick = (fillinId: string) => {
    setTaskId(fillinId);
    setOpenedBy("CHAT");
  };

  const onTaskSliderClose = () => {
    setTaskId(null);
    setOpenedBy(null);
  };

  const tasks: (Task & { subRows?: Task[] })[] = useMemo(() => {
    const templateMap = new Map<string, Array<any>>();

    taskList.forEach((task: any) => {
      if (!templateMap.get(task?.template?.uuid)) {
        templateMap.set(task?.template?.uuid, []);
      }

      templateMap.get(task?.template?.uuid)?.push(task);
    });

    const subTaskTemplateMap = taskList.reduce((acc, task) => {
      if (
        task.parent_task_template_id &&
        templateMap.get(task.parent_task_template_id)?.length
      ) {
        return {
          [task.parent_task_template_id]: [
            ...(acc[task.parent_task_template_id] || []),
            task,
          ],
        };
      }

      return acc;
    }, {} as Record<string, Task[]>);

    const tasksAndTheirSubtasks = taskList.map((parent) => {
      const subTasks = subTaskTemplateMap[parent?.template?.uuid]
        ?.filter((subTask) => subTask.entity.uuid === parent.entity.uuid)
        ?.map((task, i, arr) => {
          const isLast = i === arr.length - 1;

          return {
            ...task,
            isSubTask: true,
            isLast,
          };
        });

      return {
        ...parent,
        subRows: subTasks || [],
        toggleAllRowsExpanded: true,
      };
    });

    const subTaskIds = tasksAndTheirSubtasks.flatMap((t) =>
      t.subRows.map((st) => st.uuid)
    );

    return tasksAndTheirSubtasks.filter(
      (task) => !subTaskIds.includes(task.uuid)
    );
  }, [taskList]);

  if (isUserTaskLoading || isCPATaskLoading) {
    return (
      <div className="t-w-full t-h-[300px] t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="t-flex t-flex-col t-gap-3 t-border-solid t-border-neutral-10 t-border t-p-3 t-text-body t-font-normal group-data-state-open:t-hidden t-rounded-lg">
        {tasks.length === 0 && (
          <div className="t-flex t-h-full t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
            <img src={SearchIllustration} alt="No task found" />
            Look's like you don't have any filings
          </div>
        )}
        {tasks.map((filing) => (
          <FilingCard
            key={filing.uuid}
            filing={filing}
            onClick={onFilingClick}
            onChatClick={onFilingChatClick}
          />
        ))}
      </div>

      {openedBy && taskId && (
        <TaskSlider
          taskId={taskId}
          onClose={onTaskSliderClose}
          isOpen={Boolean(taskId)}
          defaultTab={openedBy}
        />
      )}
    </>
  );
};

export const Filings = () => {
  const group = useCurrentGroupContext();

  const {
    values: filters,
    updateFilter,
    getUpdateFilter,
  } = useFilters({
    initialValue: {
      STATUS: [ONGOING] as STATUS_TYPES[],
      SEARCH: "",
    },
    useQueryParams: true,
  });

  const queryFilter = getQueryFilter(filters.STATUS);
  const { isAnyServiceUser, isCustomer } = useRoleBasedView();

  const { data: userTaskData } = useGetTaskListForUserQuery(
    {
      ...queryFilter,
      searchTerm: filters.SEARCH,
    },
    { skip: isAnyServiceUser, refetchOnMountOrArgChange: true }
  );

  const { data: cpaTaskList } = useGetTaskListForServiceTeamQuery(
    {
      ...queryFilter,
      searchTerm: filters.SEARCH,
      groupId: group.uuid,
    },
    { skip: isCustomer || !group.uuid, refetchOnMountOrArgChange: true }
  );

  const { ongoing_count, completed_count, archived_count } =
    userTaskData || cpaTaskList || {};

  const {
    data: taskYears = [],
    isLoading,
    isSuccess: taskYearsLoaded,
  } = useTaskYearWiseCountsQuery(
    {
      ...queryFilter,
      searchTerm: filters.SEARCH,
      groupId: group?.uuid!,
    },
    { skip: !group.uuid, refetchOnMountOrArgChange: true }
  );

  const toggleStatus = (status: STATUS_TYPES) => () => {
    const statusPresent = filters.STATUS.includes(status);
    if (statusPresent) {
      return updateFilter(
        "STATUS",
        filters.STATUS.filter((v) => v !== status)
      );
    }

    updateFilter("STATUS", [...filters.STATUS, status]);
  };

  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const { authtoken } = useContext(authContext);
  const { email, name } = authtoken;

  const onAccordionClicked = (value: string[]) => {
    if (Boolean(value.length)) {
      trackEvent(TASK_LIST_YEAR_ACCORDION_OPENED, {
        screen: location.pathname,
        task_year_list: value,
        email,
        name,
      });
    }
  };

  const allYears = taskYears.map(({ task__season }) => task__season);
  const { url } = useRouteMatch();

  return (
    <div>
      <div className="t-flex t-justify-between t-items-center t-mb-4">
        <div className="t-text-h5">Filings</div>
        <div className="t-flex t-gap-2">
          <ConditionalLink to={`${url}/addtask`}>
            <Button size="small" customType="primary">
              <i className="fas fa-plus t-mr-2" />
              Add Filing
            </Button>
          </ConditionalLink>
        </div>
      </div>
      <div className="t-h-full t-flex t-flex-col t-gap-6">
        <div className="t-sticky t-top-0 t-flex t-gap-4 t-flex-col t-z-filter-head t-bg-white t-pb-2">
          <div className="t-w-1/3">
            <Search
              defaultValue={filters.SEARCH}
              placeholder="Search..."
              block
              onChange={(e) => updateFilter("SEARCH", e.target.value)}
            />
          </div>

          <Filter.Root
            defaultValue="STATUS"
            capsule={
              <>
                <Chip
                  isFixedFilter
                  onFixedFilterClick={toggleStatus("COMPLETED")}
                  onClose={toggleStatus("COMPLETED")}
                  isActive={filters.STATUS.includes("COMPLETED")}
                >
                  Completed {completed_count ? `(${completed_count})` : ""}
                </Chip>

                <Chip
                  isFixedFilter
                  onFixedFilterClick={toggleStatus("ONGOING")}
                  onClose={toggleStatus("ONGOING")}
                  isActive={filters.STATUS.includes("ONGOING")}
                >
                  Ongoing {ongoing_count ? `(${ongoing_count})` : ""}
                </Chip>
                <Chip
                  isFixedFilter
                  onFixedFilterClick={toggleStatus("ARCHIVED")}
                  onClose={toggleStatus("ARCHIVED")}
                  isActive={filters.STATUS.includes("ARCHIVED")}
                >
                  Archived {archived_count ? `(${archived_count})` : ""}
                </Chip>
              </>
            }
          >
            <Filter.Portal>
              <Filter.List>
                <Filter.ListItem value="STATUS">Status</Filter.ListItem>
              </Filter.List>
              <Filter.Body value="STATUS">
                <MultiSelectFilter
                  onChange={(newValue) =>
                    getUpdateFilter("STATUS")(newValue as STATUS_TYPES[])
                  }
                  options={[
                    { label: "Completed", value: "COMPLETED" },
                    { label: "Ongoing", value: "ONGOING" },
                    { label: "Archived", value: "ARCHIVED" },
                  ]}
                  selected={filters.STATUS}
                />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
        </div>

        {isLoading && (
          <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
            <Loader />
          </div>
        )}

        {taskYearsLoaded && taskYears.length === 0 && (
          <div className="t-flex t-h-full t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
            <img src={SearchIllustration} alt="No task found" />
            Look's like you don't have any filings
          </div>
        )}

        {taskYearsLoaded && (
          <Accordion.Root
            type="multiple"
            className="t-relative t-flex t-w-full t-flex-col t-gap-4"
            defaultValue={allYears}
            onValueChange={onAccordionClicked}
          >
            {taskYears.map(({ task__season, count }: any) => (
              <Accordion.Item
                value={task__season}
                key={task__season}
                className="t-flex t-flex-col t-gap-3"
              >
                <Accordion.Trigger asChild>
                  <div className="t-group t-sticky t-z-10 t-flex t-flex-col t-gap-3 t-bg-surface">
                    <button className="all:unset t-flex t-w-full t-items-center t-gap-2 t-text-subtitle t-text-text-30">
                      Tax year {task__season}
                      <span className="group-data-state-open:t-rotate-0 -t-rotate-90">
                        <SolidCaretDown />
                      </span>
                    </button>
                    <p className="t-border-solid t-m-0 t-border-neutral-10 t-border t-px-4 t-py-3 t-text-body t-font-normal group-data-state-open:t-hidden t-rounded-lg">
                      {count} Filings
                    </p>
                  </div>
                </Accordion.Trigger>

                <Accordion.Content>
                  <FilingsAccordion filters={filters} season={task__season} />
                </Accordion.Content>
              </Accordion.Item>
            ))}
          </Accordion.Root>
        )}
      </div>
    </div>
  );
};
