import React from "react";
import classNames from "classnames";

const SavedCardItem = ({
  card: {
    payment_method_id,
    card: { brand, last_four, exp_month, exp_year },
  },
  isSelected,
  setSelectedCard,
  selectedCard,
}) => {
  const changeCardHandler = (e) => {
    if (e.target.value === selectedCard) {
      setSelectedCard();
    } else {
      setSelectedCard(e.target.value);
    }
  };

  return (
    <label
      htmlFor={payment_method_id}
      key={payment_method_id}
      className={classNames(
        "t-rounded t-p-4 t-flex t-justify-between t-border t-border-solid t-border-surface-transparent t-cursor-pointer",
        {
          "t-bg-purple-0 t-border-purple-10": isSelected,
        }
      )}
    >
      <div>
        <input
          id={payment_method_id}
          type="radio"
          name="card_details"
          className="savedCardInputRadio t-h-4 t-w-4 t-mt-1 t-accent-purple"
          value={payment_method_id}
          checked={isSelected}
          onClick={changeCardHandler}
        />
      </div>
      <div>
        <span className="cardBrand me-1">{brand}</span>
      </div>
      <div>
        &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;
        &#8226;&#8226;&#8226;&#8226; {last_four}
      </div>
      <div>
        <div>
          {exp_month}/{exp_year}
        </div>
      </div>
    </label>
  );
};

export default SavedCardItem;
