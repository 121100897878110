import { RestrictedRoute } from "RestrictedRoute";
import { AppLayout } from "components/AppLayout/AppLayout";
import { BoardHome } from "components/Board/BoardHome";
import { CapTableHome } from "components/CapTable/CapTableHome";
import { Entities } from "components/Entity/Entity";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarApps,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { Captable } from "components/icons/LeftNav/Board/Captable";
import { Home } from "components/icons/LeftNav/Home";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { InkleTaxCredits } from "components/icons/Logos/InkleTaxCredits";
import { InkleBoardSymbol } from "components/icons/Logos/InkleBoardSymbol";
import { parse, stringify } from "qs";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { RandDServices } from "./RandD/RandDServices";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { RANDDIcon } from "components/icons/LeftNav/R&DIcon";
import { DashboardLayout } from "components/DashboardLayout";
import { LargeCard } from "components/home/Home";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { useAuth } from "hooks/useAuth";
import { ActionCard } from "components/ActionCard/ActionCard";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";

const Navbar = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <>
      <LeftBarItem exact to="" icon={Home}>
        Home
      </LeftBarItem>

      <LeftBarItem to="/services" icon={RANDDIcon}>
        R&D Tax Credits
      </LeftBarItem>

      <div className="t-py-3">
        <LeftBarSeparator />
      </div>

      <NavAcrossApps />

      <div className="t-mt-auto">
        <div className="t-py-3">
          <LeftBarSeparator name="Other Apps" />
        </div>
        <LeftBarApps current={url} />
      </div>
    </>
  );
};

export const RandDDashboard = () => {
  const { path, url } = useRouteMatch();

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { first_name } = useAuth();
  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo logo={InkleTaxCredits} symbol={InkleBoardSymbol} />
          }
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo logo={InkleTaxCredits} symbol={InkleBoardSymbol} />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        />
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute exact path={`${path}/services/addtask`}>
          <TemplateMenu
            to={`/credits/services${search}`}
            taskCategoryType="RND"
          />
        </RestrictedRoute>

        <RestrictedRoute path={`${path}/services/:taskId`}>
          <FilingDetails isFromServices parentRoute="/credits/services" />
        </RestrictedRoute>

        <RestrictedRoute path={`${path}/services`}>
          <RandDServices />
        </RestrictedRoute>

        <RestrictedRoute exact path={path}>
          <DashboardLayout
            header={<Header v2 title={`Welcome ${first_name}`} />}
          >
            <div className="t-pb-8 t-grid t-gap-6 t-flex-wrap md:t-grid-cols-[repeat(auto-fill,minmax(462px,1fr))] t-grid-cols-[repeat(auto-fill,1fr)]">
              <LargeCard>
                <ActionCard
                  className="t-bg-[url('static/images/LightMetallic.svg')] t-bg-no-repeat t-bg-right-bottom"
                  name="R&D Tax Credits"
                  content={
                    <span className="t-text-body t-text-text-30 t-mb-10">
                      View and manage your federal R&D Tax Credits.
                    </span>
                  }
                  action={
                    <ConditionalLink to={`${url}/services`}>
                      <Button size="small">View services</Button>
                    </ConditionalLink>
                  }
                />
              </LargeCard>
            </div>
          </DashboardLayout>
        </RestrictedRoute>
        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
