import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { NoCustomerAdded } from "components/Illustrations/NoCustomerAdded";
import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useGetAllInvoiceCustomersListQuery } from "store/apis/invoices";
import { EmptyScreen } from "../EmptyScreen";
import { InvoiceCustomers } from "./InvoiceCustomers";

export const Customers = () => {
  usePageTitle("Customers");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCpa } = useRoleBasedView();
  const addCustomerModal = useModal();

  const { data: invoiceCustomers, ...invoiceCustomersState } =
    useGetAllInvoiceCustomersListQuery(
      {
        groupId,
        entityId,
        pageNum: 1,
      },
      { skip: !groupId || !entityId }
    );

  const { client_customers = [] } = invoiceCustomers || {};

  const { onConnect, isLoading: isConnectBankLoading } = useBankConnect();
  const { stripeConnection, ...connectionState } = useGetAllConnections({
    groupId,
    entityId,
  });

  const isLoading =
    connectionState.isLoading || invoiceCustomersState.isLoading;
  const isSuccess =
    connectionState.isSuccess && invoiceCustomersState.isSuccess;

  return (
    <DashboardLayout header={<Header v2 title="Customers" />}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={client_customers.length === 0}
      >
        <Async.Empty>
          <EmptyScreen
            className="t-h-[70dvh]"
            text=""
            cta={
              <div className="t-flex t-gap-3">
                {stripeConnection &&
                  !stripeConnection?.is_connection_available && (
                    <Button
                      size="small"
                      isLoading={isConnectBankLoading}
                      disabled={isConnectBankLoading}
                      onClick={() =>
                        onConnect({
                          connectionId: stripeConnection?.uuid!,
                          invokedFrom: isCpa
                            ? `/books/customers?company=${groupId}&entity=${entityId}`
                            : `/books/customers?entity=${entityId}`,
                        })
                      }
                    >
                      Connect Stripe
                    </Button>
                  )}

                <Button
                  size="small"
                  customType="primary"
                  onClick={() => addCustomerModal.open()}
                >
                  Add customer
                </Button>
              </div>
            }
          >
            <>
              <div className="t-text-center t-flex t-gap-2 t-flex-col t-items-center">
                <NoCustomerAdded />
                <div className="t-text-subtitle-sm t-text-text-60">
                  Add customers
                </div>
                <div className="t-text-body-sm t-text-text-30">
                  Get started by loading data from Stripe or add
                  <br />
                  them here directly.
                </div>
              </div>
            </>
          </EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <InvoiceCustomers />
        </Async.Success>
      </Async.Root>

      <InvoiceCustomerModal
        customerId={addCustomerModal.isOpen ? "NEW" : null}
        setCustomerId={() => addCustomerModal.close()}
      />
    </DashboardLayout>
  );
};
