import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { AddJournalEntryModal } from "components/JournalEntry/AddJournalEntryModal";
import { DeleteJournalEntryTable } from "components/JournalEntry/DeleteJournalEntryTable";
import { EditJournalEntry } from "components/JournalEntry/EditJournalEntry";
import { ViewJournalEntryModal } from "components/JournalEntry/ViewJournalEntry";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { MouseEvent, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetAllJournalEntriesQuery } from "store/apis/journalEntry";
import { JournalEntry as JournalEntryType } from "types/Models/journalEntry";
import { debounce } from "utils/debouncing";
import { DuplicateJournalEntryModal } from "./DuplicateJournalEntry";
import { JournalEntryFilter } from "./JournalEntryFilter";
import { Recurring } from "components/icons/Recurring";
import ToolTip from "components/design/toolTip";

const Action = ({ rowData }: { rowData: JournalEntryType }) => {
  const { isOpen: isOpenView, open: openView, close: closeView } = useModal();
  const {
    isOpen: isOpenEdit,
    showConfirmation: showEditConfirmation,
    dismissConfirmation: dismissEditConfirmation,
    open: openEdit,
    close: closeEdit,
  } = useModal();
  const {
    isOpen: isOpenDuplicate,
    open: openDuplicate,
    close: closeDuplicate,
  } = useModal();

  const transactionId = rowData.transaction_uuids?.[0] || "";

  const {
    isOpen: isDeleteJournalEntryModalOpen,
    open: openDeleteJournalEntryModal,
    close: closeDeleteJournalEntryModal,
  } = useModal();

  const onEdit = () => {
    openEdit();
  };

  const onDelete = () => {
    openDeleteJournalEntryModal();
  };

  const onDuplicate = () => {
    openDuplicate();
  };

  return (
    <div
      className="t-text-subtext t-text-text-30"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="t-flex t-justify-center t-items-center t-h-full">
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <div>
              <Button
                size="small"
                customType="ghost_icon"
                title={`Action for ${rowData.entry_number}`}
              >
                <img src={ThreeDots} alt="Action" className="t-select-none" />
              </Button>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content sideOffset={8} side="bottom" className="t-mr-16">
              <Dropdown.Item onSelect={onDuplicate}>Duplicate</Dropdown.Item>
              <Dropdown.Item onSelect={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item onSelect={onDelete}>Delete</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>
      {isOpenView && (
        <ViewJournalEntryModal
          isOpen={isOpenView}
          close={closeView}
          transactionId={transactionId!}
        />
      )}
      {isOpenEdit && (
        <EditJournalEntry
          showConfirmation={showEditConfirmation}
          dismissConfirmation={dismissEditConfirmation}
          close={closeEdit}
          isOpen={isOpenEdit}
          transactionId={transactionId}
        />
      )}
      {isOpenDuplicate && (
        <DuplicateJournalEntryModal
          close={closeDuplicate}
          isOpen={isOpenDuplicate}
          currentRowData={rowData!}
          transactionId={transactionId}
        />
      )}

      <DeleteJournalEntryTable
        transactionId={transactionId}
        close={closeDeleteJournalEntryModal}
        isOpen={isDeleteJournalEntryModalOpen}
      />
    </div>
  );
};

export const JournalEntry = () => {
  usePageTitle("Journal Entry");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const query = useQuery();
  const pageNum = Number(query.get("page")) || 1;
  const searchTerm = query.get("search_term") || null;
  const { update, updateMultiple } = useUpdateQuery();
  const [currentRowData, setCurrentRowData] = useState<JournalEntryType>();

  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "AMOUNT" | "DATE";
    }[]
  >([
    {
      id: "DATE",
      desc: true,
    },
  ]);

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  const { values, updateFilter, getUpdateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  const { data, isLoading, isSuccess } = useGetAllJournalEntriesQuery(
    {
      groupId,
      entityId,
      pageNum,
      searchTerm,
      startDate: values.START_DATE
        ? dayjs(values.START_DATE).format(YYYY_MM_DD)
        : null,
      endDate: values.END_DATE
        ? dayjs(values.END_DATE).format(YYYY_MM_DD)
        : null,
      sortCol: sortCol,
      sortOrder: sortOrder ? ("DSC" as const) : ("ASC" as const),
    },
    { skip: !groupId || !entityId }
  );

  const {
    journal_entries = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const {
    isOpen: isAddJournalEntryModalOpen,
    close: closeAddJournalEntryModal,
    open: openAddJournalEntryModal,
  } = useModal();

  const {
    isOpen: isViewJournalEntryModalOpen,
    close: closeViewJournalEntryModal,
    open: openViewJournalEntryModal,
  } = useModal();

  const columnHelper = createColumnHelper<JournalEntryType>();

  const columns = [
    columnHelper.accessor("date", {
      id: "DATE",
      size: 25,
      header: "DATE",
      cell: (info) => (
        <div className="t-text-subtext t-text-text-60">
          {info.getValue() ? dayjs(info.getValue()).format(DD_MMM_YYYY) : "-"}
        </div>
      ),
    }),

    columnHelper.accessor("entry_name", {
      id: "TITLE",
      size: 40,
      header: "TITLE",
      enableSorting: false,
      cell: (info) => (
        <div className="t-flex t-gap-2">
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
          {info.row.original.settings?.is_recurring_enabled && (
            <span className="t-text-text-30 t-flex t-items-center">
              <ToolTip text="Recurring journal entry" disableHoverableContent>
                <span>
                  <Recurring />
                </span>
              </ToolTip>
            </span>
          )}
        </div>
      ),
    }),

    columnHelper.accessor("entries_count", {
      id: "entries",
      size: 25,
      header: "TOTAL ENTRIES",
      enableSorting: false,
      cell: (info) => (
        <div className="t-flex t-w-full t-justify-between">
          <div className="t-text-subtext t-text-text-60">{info.getValue()}</div>
        </div>
      ),
    }),

    columnHelper.display({
      id: "action",
      size: 10,
      cell: (info) => {
        const {
          row: { original },
        } = info;
        return <Action rowData={original} />;
      },
    }),
  ];

  const table = useReactTable({
    data: journal_entries || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    // @ts-ignore
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const goToFirstPage = () => {
    update({ query: "page", value: 1 });
  };

  const goToPrevPage = () => {
    const localCurrentPage = pageNum < total_pages ? pageNum : total_pages;
    update({ query: "page", value: localCurrentPage - 1 });
  };

  const goToNextPage = () => {
    if (pageNum < total_pages) {
      update({ query: "page", value: pageNum + 1 });
    }
  };

  const goToLastPage = () => {
    update({ query: "page", value: total_pages });
  };

  const handleChange = debounce((e) => {
    const { value } = e.target;
    updateMultiple([
      { query: "search_term", value: value || null },
      { query: "page", value: 1 },
    ]);
  });

  const onRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: { original: JournalEntryType }
  ) => {
    e.stopPropagation();
    setCurrentRowData(row.original);
    openViewJournalEntryModal();
  };

  const isEmpty = journal_entries.length === 0;

  return (
    <div className="t-flex t-flex-col t-gap-5 t-pb-16">
      <div className="t-flex t-flex-col t-gap-5 t-sticky t-top-0 t-z-header t-bg-white">
        <div className="t-w-full t-flex t-justify-between">
          <div className="t-w-2/5">
            <Search
              placeholder="Search..."
              defaultValue={searchTerm || ""}
              onChange={handleChange}
              block
            />
          </div>
          <Button
            customType="primary"
            size="small"
            onClick={openAddJournalEntryModal}
          >
            Add journal entry
          </Button>
        </div>
        <div className="t-w-full t-flex t-justify-between">
          <JournalEntryFilter
            values={values}
            updateFilter={updateFilter}
            getUpdateFilter={getUpdateFilter}
          />

          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyScreen text="No journal entry found">
            <span className="t-text-i-neutral-10">
              <MagnifyingGlass size="149" />
            </span>
          </EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <Table.Container className="t-h-full">
            <Table.Content>
              <Table.Head>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Row key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.HeadCell
                        className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                        onClick={header.column.getToggleSortingHandler()}
                        key={header.id}
                        style={{ width: `${header.getSize()}%` }}
                        {...(header.column.getCanSort()
                          ? { role: "button" }
                          : {})}
                      >
                        <span className="t-flex t-gap-1 t-items-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {header.column.getCanSort() && (
                            <Table.HeadCellSort
                              nextSortOrder={header.column.getNextSortingOrder()}
                              isSorted={header.column.getIsSorted()}
                            />
                          )}
                        </span>
                      </Table.HeadCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body>
                {table.getRowModel().rows.map((row) => (
                  <Table.Row
                    key={row.id}
                    onClick={(e) => onRowClick(e, row)}
                    className="hover:t-bg-surface-lighter-grey t-cursor-pointer t-group"
                    data-testid={`journal-entry-row-${row.id}`}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          id={cell.id}
                          key={cell.id}
                          style={{
                            width: `${cell.column.getSize()}%`,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Content>
          </Table.Container>
        </Async.Success>
      </Async.Root>
      {isAddJournalEntryModalOpen && (
        <AddJournalEntryModal
          close={closeAddJournalEntryModal}
          isOpen={isAddJournalEntryModalOpen}
        />
      )}
      {isViewJournalEntryModalOpen && (
        <ViewJournalEntryModal
          isOpen={isViewJournalEntryModalOpen}
          close={closeViewJournalEntryModal}
          transactionId={currentRowData?.transaction_uuids?.[0]!}
        />
      )}
    </div>
  );
};
