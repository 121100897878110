import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReportsType } from "pages/Books/Reports/Reports";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  useByViewInteractiveReportQuery,
  ViewByOption,
} from "store/apis/reports";
import { getFilterStatus } from "store/selector/reportFilter";
import { Account, ViewReportData } from "types/Models/report";

export const useViewByReportDataFormat = ({
  reportType,
}: {
  reportType: ReportsType;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { filters } = useSelector(getFilterStatus);
  const [startDate, endDate] = filters.reportDate;

  const comparisonReportType = filters.view[0].value as ViewByOption;
  const { data, ...apiStates } = useByViewInteractiveReportQuery(
    {
      groupId,
      entityId,
      reportType,
      accountingMethod: filters.accountingMethod?.[0].value as string,
      startDate: startDate.value ? (startDate.value as string) : null,
      endDate: endDate.value ? (endDate.value as string) : null,
      showNonZeroAccounts: filters.others?.[0].value as string,
      comparison_report_type: comparisonReportType,
    },
    {
      skip:
        !groupId ||
        !entityId ||
        !reportType ||
        !startDate.value ||
        !endDate.value ||
        !filters.accountingMethod?.[0].value ||
        !comparisonReportType,
    }
  );

  const { report_data = {} } = data || {};

  const formatViewReportData = useCallback((report_data: ViewReportData) => {
    // Maps view names to their accounts and recursively maps sub-accounts.
    const mapViewNameToCOA = ({
      name,
      accounts,
      parent_uuid,
    }: {
      name: string;
      accounts: Account[];
      parent_uuid: string;
    }): Account[] => {
      return accounts.map(({ types = [], ...rest }) => ({
        ...rest,
        types: mapViewNameToCOA({
          name,
          accounts: types,
          parent_uuid: rest.uuid,
        }),
        view: name,
        parent_uuid,
      }));
    };

    // Initial mapping of view names to accounts.
    const accountsWithMappedViewNames = Object.entries(report_data).flatMap(
      ([name, account]) =>
        mapViewNameToCOA({
          name,
          accounts: account.accounts,
          parent_uuid: "top-level",
        })
    );

    // Flattens accounts including sub-accounts recursively.
    const flattenCOA = (data: Account[]): Account[] =>
      data.reduce((acc, account) => {
        acc.push(account);
        if (account.types) {
          acc.push(...flattenCOA(account.types));
        }
        return acc;
      }, [] as Account[]);

    // Adds summary amount by view to each account.
    const addSummaryAmountByView = (items: Account[]) => {
      return items.map((item) => {
        const summary_amount_by_view = flattenCOA(accountsWithMappedViewNames)
          .filter(({ name }) => name === item.name)
          .reduce((acc, d) => {
            acc[d.view!] = d.summary_amount;
            return acc;
          }, {} as Record<string, number>);

        let newItem = { ...item, summary_amount_by_view };

        if (newItem.types) {
          newItem.types = addSummaryAmountByView(newItem.types);
        }

        return newItem;
      });
    };

    const reportWithSummaryAmountByView = addSummaryAmountByView(
      accountsWithMappedViewNames
    );

    const flattenAccounts = flattenCOA(reportWithSummaryAmountByView);

    // Removes duplicate accounts based on name.
    const removeDuplicates = (list: Account[]): Account[] => {
      const seen = new Set();
      return list.reduce((acc, curr) => {
        if (!seen.has(curr.name)) {
          seen.add(curr.name);
          acc.push({
            ...curr,
            types: curr.types ? removeDuplicates(curr.types) : [],
          });
        }
        return acc;
      }, [] as Account[]);
    };

    // Get possible children recursively for each account.
    const getPossibleChildren: (arg: Account) => Account[] = (arg) => {
      return flattenAccounts
        .filter((i) => i.parent_uuid === arg.uuid)
        .map((item) => ({
          ...item,
          types: getPossibleChildren(item),
        }));
    };

    // Apply summary addition and uniqueness filters.
    const finalData = removeDuplicates(reportWithSummaryAmountByView).map(
      (item) => ({
        ...item,
        types: removeDuplicates(getPossibleChildren(item)),
      })
    );

    return finalData;
  }, []);

  return {
    originalData: data,
    formatViewReportData: data ? formatViewReportData(report_data) : [],
    ...apiStates,
  };
};
