import { emptyApi } from "./emptyApi";
import qs from "qs";
import { BillingInvoice, RefundDetails } from "types/Models/billing";
import { IncompletePaymentIntent } from "./subscriptions";

type Args = {
  groupId: string;
  pageNumber: number;
  searchTerm?: string | null;
  selectedSeasons?: string;
  productTypes?: string;
  entityId: string;
  paymentStatuses?: string;
};

type ListReponse = {
  group_invoices: BillingInvoice[];
  total_pages: number;
  current_page: number;
  total_count: number;
  per_page: number;
  due_amount: number;
};

export const billingInvoiceApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getBillingInvoices: build.query<ListReponse, Args>({
      query: ({
        groupId,
        pageNumber,
        searchTerm,
        selectedSeasons,
        productTypes,
        paymentStatuses,
        entityId,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNumber,
            search_term: searchTerm,
            seasons: selectedSeasons,
            product_types: productTypes,
            payment_statuses: paymentStatuses,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/invoices/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? [{ type: "BillingInvoices" }] : []),
    }),

    getBillingInvoice: build.query<
      BillingInvoice,
      { groupId: string; entityId: string; invoiceId: string }
    >({
      query: ({ groupId, entityId, invoiceId }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/invoice/${invoiceId}/`,
        };
      },
      providesTags: (result) => (result ? [{ type: "BillingInvoices" }] : []),
    }),

    createBillingInvoice: build.mutation<
      BillingInvoice,
      {
        groupId: string;
        task_id: string;
        invoice_amount: string;
        task_short_name: string;
        invoice_type: "ONE_TIME" | "RECURRING" | "BALANCING_PAYMENT";
        description?: string;
        season: string;
        entityId: string;
      }
    >({
      query: ({
        groupId,
        task_id,
        invoice_amount,
        task_short_name,
        invoice_type,
        description,
        season,
        entityId,
      }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/invoice/`,
          method: "POST",
          body: {
            task_id,
            invoice_amount,
            task_short_name,
            invoice_type,
            description,
            season,
          },
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "BillingInvoices" }] : [],
    }),

    payMultipleInvoice: build.mutation<
      IncompletePaymentIntent,
      {
        groupId: string;
        entityId: string;
        payload: {
          invoices: string[];
          credits_used?: number | null;
          pending_credit_ledger_record?: string | null;
          balancing_payment_message_id?: string;
          brex_points_used?: boolean;
          brex_quote_id?: string;
        };
      }
    >({
      query: ({ payload, groupId, entityId }) => ({
        url: `/api/inkle/payments/${groupId}/entity/${entityId}/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "BillingInvoices" }] : [],
    }),

    deleteBillingInvoice: build.mutation<
      BillingInvoice,
      {
        groupId: string;
        invoiceId: string;
        entityId: string;
      }
    >({
      query: ({ groupId, invoiceId, entityId }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/invoice/`,
          method: "DELETE",
          body: { invoice_id: invoiceId },
        };
      },
      invalidatesTags: () => [{ type: "BillingInvoices" }],
    }),

    createCreditInvoice: build.mutation<
      BillingInvoice,
      {
        groupId: string;
        entityId: string;
        invoice_amount: number;
      }
    >({
      query: ({ groupId, invoice_amount, entityId }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/entity/${entityId}/purchased_credits_invoice/`,
          method: "POST",
          body: {
            invoice_amount,
          },
        };
      },
      invalidatesTags: (result) =>
        result ? [{ type: "BillingInvoices" }] : [],
    }),
    initiateRefund: build.mutation<
      RefundDetails,
      {
        refund_amount: number;
        invoice_id: string;
        refund_reason: string;
        refund_destination: string;
        refund_type: string;
        groupId: string;
      }
    >({
      query: ({
        refund_amount,
        invoice_id,
        refund_reason,
        refund_destination,
        refund_type,
        groupId,
      }) => {
        return {
          url: `/api/inkle/billing/group/${groupId}/refunds/`,
          method: "POST",
          body: {
            refund_amount,
            invoice_id,
            refund_reason,
            refund_destination,
            refund_type,
          },
        };
      },
      invalidatesTags: ["BillingInvoices", "CREDITS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBillingInvoicesQuery,
  useCreateBillingInvoiceMutation,
  useDeleteBillingInvoiceMutation,
  useCreateCreditInvoiceMutation,
  useLazyGetBillingInvoicesQuery,
  useGetBillingInvoiceQuery,
  usePayMultipleInvoiceMutation,
  useLazyGetBillingInvoiceQuery,
  useInitiateRefundMutation,
} = billingInvoiceApis;
