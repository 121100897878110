import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import QueryString from "qs";

export type FiltersValues = {
  name: string;
  value: boolean | string | null | string[];
  type: string;
  fixed?: boolean;
};

export interface ReportFilterState {
  filters: {
    startDate: FiltersValues;
    endDate: FiltersValues;
    hideZero: FiltersValues;
    view: FiltersValues;
    accountingMethod: FiltersValues;
  };
}

export type FilterName =
  | "startDate"
  | "endDate"
  | "hideZero"
  | "accountingMethod"
  | "view";

const initialState: ReportFilterState = {
  filters: {
    startDate: { name: "Since", value: null, type: "reportDate" },
    endDate: { name: "Till", value: null, type: "reportDate" },
    accountingMethod: {
      name: "",
      value: null,
      type: "accounting",
    },
    view: {
      name: "View by",
      value: null,
      type: "view",
    },
    hideZero: {
      name: "Hide zero balance accounts",
      value: false,
      type: "others",
      fixed: false,
    },
  },
};

export const reportFilter = createSlice({
  name: "reportFilter",
  initialState,
  reducers: {
    setReportFilters: (state, action: PayloadAction<QueryString.ParsedQs>) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        const value = payload[el] === "true" ? true : payload[el];
        const newState = {
          ...initialState.filters?.[el as FilterName],
          value,
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
  },
});

export const { setReportFilters } = reportFilter.actions;

export default reportFilter.reducer;
