import React from "react";

export const ProductAndServices = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_3720_5848)">
        <path
          d="M8 8.06812V14.4981"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.04376 4.8075L8.00001 8.0675L13.9563 4.8075"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.74 11.4275L8.24 14.4387C8.16641 14.479 8.08388 14.5001 8 14.5001C7.91612 14.5001 7.83359 14.479 7.76 14.4387L2.26 11.4275C2.18147 11.3845 2.11591 11.3213 2.07017 11.2443C2.02444 11.1673 2.0002 11.0795 2 10.99V5.01124C2.0002 4.92172 2.02444 4.8339 2.07017 4.75694C2.11591 4.67998 2.18147 4.61672 2.26 4.57374L7.76 1.56249C7.83359 1.52223 7.91612 1.50113 8 1.50113C8.08388 1.50113 8.16641 1.52223 8.24 1.56249L13.74 4.57374C13.8185 4.61672 13.8841 4.67998 13.9298 4.75694C13.9756 4.8339 13.9998 4.92172 14 5.01124V10.9887C14 11.0785 13.9759 11.1666 13.9301 11.2438C13.8844 11.321 13.8187 11.3844 13.74 11.4275Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.09747 3.01938L11 6.25V9.5"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3720_5848">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
