import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { BundleDropdown } from "components/icons/BundleDropdown";
import { Pin } from "components/icons/Chat/Pin";
import { Seperator } from "components/icons/Chat/Seperator";
import { WaringCircle } from "components/icons/Chat/WaringCircle";
import { FolderIcon } from "components/icons/FolderIcon";
import Headset from "components/icons/Headset";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { Search } from "components/icons/Search";
import { SquareHalf } from "components/icons/SquareHalf";
import { ChatTicketModal } from "components/MessageBubble/ChatTicketModal";
import { ARCHIVED } from "constants/task";
import { TASK_STATE_TO_COLOR } from "dictionaries";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import PrivateChatHeaderIcon from "static/images/PrivateChatHeaderLock.svg";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import {
  setChannelInfoSliderActive,
  setMediaSliderActive,
} from "store/slices/channelInfoSlider";
import { setChannelPendingActionsActive } from "store/slices/channelPendingActions";
import { setChannelPinnedItemsActive } from "store/slices/channelPinnedItems";
import { removeToOpenChatUrl, setChannelTaskId } from "store/slices/chat";
import {
  collapseDashboardSidebar,
  expandDashboardSidebar,
} from "store/slices/dashboard";
import {
  closeMessageSearch,
  setMessageSearchActive,
} from "store/slices/messageSearch";
import { RootState } from "store/store";
import {
  useChannelPreviewInfo,
  useChannelStateContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { openLink } from "utils/openLink";

const TaskLink = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>() => {
  const { channel } =
    useChannelStateContext<StreamChatGenerics>("ChannelHeader");
  const { displayTitle } = useChannelPreviewInfo({
    channel,
  });
  const { data: channelTask, isLoading } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { tasks } = channelTask || {};
  const isBundledTask = tasks?.some(({ is_bundle }) => is_bundle);
  const task = tasks?.[0];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      dispatch(setChannelTaskId(task?.uuid || ""));
    }
  }, [dispatch, isLoading, task?.uuid]);

  if (isLoading) {
    return (
      <span className="t-mr-1.5 t-flex t-origin-center t-animate-spin">
        <LoadingIcon />
      </span>
    );
  }

  if (isBundledTask) {
    return (
      <Dropdown.Root>
        <Dropdown.Trigger asChild className="t-group t-select-none">
          <div className="t-flex t-items-center t-gap-3">
            <h2 className="t-m-0 t-mb-0.5 t-flex t-items-center t-gap-2 t-text-subtitle t-text-purple">
              {displayTitle}
            </h2>
            <Button customType="icon" size="small">
              <div>
                <BundleDropdown />
              </div>
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={4}
            align="start"
            side="bottom"
            className="t-max-h-56 t-overflow-auto t-max-w-md"
          >
            {tasks?.map(({ uuid, name, current_state, is_archived }) => (
              <ToolTip text={name} side="right" key={uuid}>
                <div>
                  <Dropdown.Item
                    textValue={name}
                    key={uuid}
                    onSelect={() => openLink(`/filings/${uuid}`)}
                  >
                    <div className="t-flex t-gap-4 t-mb-2 t-max-w-sm">
                      <div className="t-whitespace-normal t-break-words">
                        {name}
                      </div>

                      <div className="t-ml-auto">
                        <Badge
                          color={
                            TASK_STATE_TO_COLOR[
                              is_archived
                                ? ARCHIVED
                                : current_state?.type || "Not Started"
                            ]
                          }
                          size="small"
                        >
                          {is_archived
                            ? "Archived"
                            : current_state?.name || "Not Started"}
                        </Badge>
                      </div>
                    </div>
                  </Dropdown.Item>
                </div>
              </ToolTip>
            ))}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    );
  }

  return (
    <ConditionalLink to={task?.uuid ? `/filings/${task?.uuid}` : ""}>
      <h2
        className={classNames("t-m-0 t-text-body t-text-purple t-font-medium", {
          "hover:t-underline": Boolean(task?.uuid),
        })}
      >
        {displayTitle}
      </h2>
    </ConditionalLink>
  );
};

export const ChannelHeader = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>({
  isPrivateChannel,
  setPrivateChannel,
  backButton,
  actionsAvailable = true,
  hideHeader = false,
}: {
  isPrivateChannel: boolean;
  setPrivateChannel: () => void;
  backButton?: React.ReactNode;
  actionsAvailable?: boolean;
  hideHeader?: boolean;
}) => {
  const { channel } =
    useChannelStateContext<StreamChatGenerics>("ChannelHeader");
  const { isCpa, isCustomer } = useRoleBasedView();
  const dispatch = useDispatch();
  const isSidebarExpanded = useSelector(
    (state: RootState) => state.dashboard.dashboardSidebarExpand
  );

  const isMessageSearchActive = useSelector(
    (store: RootState) => store.messageSearch.isActive
  );
  // const { openMobileNav } = useChatContext<StreamChatGenerics>("ChannelHeader");

  const { isOpen } = useSelector((state: RootState) => state.chat);
  const isChannelSliderOpen = useSelector(
    (store: RootState) => store.channelInfoSlider.isActive
  );

  const isMediaSliderActive = useSelector(
    (store: RootState) => store.channelInfoSlider.isMediaSliderActive
  );

  const isChannelPendingActionSliderOpen = useSelector(
    (store: RootState) => store.channelPendingActions.isActive
  );

  const isChannelPinnedItemsSliderOpen = useSelector(
    (store: RootState) => store.channelPinnedItems.isActive
  );

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { tasks, company_group: companyGroup, entity } = channelTask || {};
  const task = tasks?.[0];
  const isTask = task?.uuid ? true : false;

  const groupName = channel?.data?.group_name as string;
  const season = channel?.data?.season as string;

  const entityLink = isCustomer ? `/entities` : `/crm/${companyGroup?.uuid}`;

  const documentLink = isCustomer
    ? `/documents`
    : `/crm/${companyGroup?.uuid}/documents/${channelTask?.company_group?.documents_group_id}`;

  const isBundledTask = tasks?.some(({ is_bundle }) => is_bundle);

  const onSearchClick = () => {
    if (isMessageSearchActive) {
      dispatch(expandDashboardSidebar());
      return dispatch(closeMessageSearch());
    }

    if (isChannelSliderOpen) {
      dispatch(
        setChannelInfoSliderActive({
          isActive: false,
        })
      );
    }

    if (isChannelPendingActionSliderOpen) {
      dispatch(
        setChannelPendingActionsActive({
          isActive: false,
        })
      );
    }

    if (isChannelPinnedItemsSliderOpen) {
      dispatch(
        setChannelPinnedItemsActive({
          isActive: false,
        })
      );
    }

    dispatch(collapseDashboardSidebar());
    return dispatch(
      setMessageSearchActive({ setSideBarToExpandOnClose: isSidebarExpanded })
    );
  };

  const onSliderButtonClick = () => {
    if (isChannelSliderOpen) {
      dispatch(expandDashboardSidebar());
      return dispatch(
        setChannelInfoSliderActive({
          isActive: false,
        })
      );
    }

    if (isMessageSearchActive) {
      dispatch(closeMessageSearch());
    }

    if (isChannelPendingActionSliderOpen) {
      dispatch(
        setChannelPendingActionsActive({
          isActive: false,
        })
      );
    }

    if (isChannelPinnedItemsSliderOpen) {
      dispatch(
        setChannelPinnedItemsActive({
          isActive: false,
        })
      );
    }

    dispatch(collapseDashboardSidebar());
    return dispatch(
      setChannelInfoSliderActive({
        isActive: true,
        setSideBarToExpandOnClose: isSidebarExpanded,
      })
    );
  };

  const onPendingActionSliderClick = () => {
    if (isChannelPendingActionSliderOpen) {
      dispatch(expandDashboardSidebar());
      return dispatch(
        setChannelPendingActionsActive({
          isActive: false,
        })
      );
    }

    if (isMessageSearchActive) {
      dispatch(closeMessageSearch());
    }

    if (isMediaSliderActive) {
      dispatch(setMediaSliderActive(false));
    }

    if (isChannelSliderOpen) {
      dispatch(
        setChannelInfoSliderActive({
          isActive: false,
        })
      );
    }

    if (isChannelPinnedItemsSliderOpen) {
      dispatch(
        setChannelPinnedItemsActive({
          isActive: false,
        })
      );
    }

    dispatch(collapseDashboardSidebar());
    return dispatch(
      setChannelPendingActionsActive({
        isActive: true,
        setSideBarToExpandOnClose: isSidebarExpanded,
      })
    );
  };

  const onPinnedItemsSliderClick = () => {
    if (isChannelPinnedItemsSliderOpen) {
      dispatch(expandDashboardSidebar());
      return dispatch(
        setChannelPinnedItemsActive({
          isActive: false,
        })
      );
    }

    if (isMessageSearchActive) {
      dispatch(closeMessageSearch());
    }

    if (isMediaSliderActive) {
      dispatch(setMediaSliderActive(false));
    }

    if (isChannelSliderOpen) {
      dispatch(
        setChannelInfoSliderActive({
          isActive: false,
        })
      );
    }

    if (isChannelPendingActionSliderOpen) {
      dispatch(
        setChannelPendingActionsActive({
          isActive: false,
        })
      );
    }

    dispatch(collapseDashboardSidebar());
    return dispatch(
      setChannelPinnedItemsActive({
        isActive: true,
        setSideBarToExpandOnClose: isSidebarExpanded,
      })
    );
  };

  const taskStateType = task?.is_archived
    ? ARCHIVED
    : task?.current_state?.type || "Not Started";

  const taskState = task?.is_archived
    ? "Archived"
    : task?.current_state?.name || "Not Started";

  const pendingActionCount: number = channel?.data
    ?.pending_action_items_count as number;
  const pinnedMessageCount = channel?.state?.pinnedMessages?.length || 0;

  if (hideHeader) {
    return null;
  }

  return (
    <>
      <div className="gap t-flex t-gap-2 t-border-0 t-border-b t-border-solid t-border-b-neutral-10 t-bg-white t-px-4 t-pl-4 t-pr-3 t-py-2 t-leading-[normal]">
        <div className="t-flex t-flex-col t-gap-3 t-justify-center">
          <div className="t-flex t-gap-2.5 t-items-center">
            {isOpen && !backButton && (
              <button
                className="all:unset -t-mt-[3px]"
                onClick={(e) => {
                  dispatch(removeToOpenChatUrl());
                }}
              >
                <ArrowLeft size="16px" />
              </button>
            )}
            {backButton}
            <TaskLink />
            {!isCustomer && (
              <Switch
                checked={isPrivateChannel}
                onCheckedChange={setPrivateChannel}
                size="small"
              />
            )}
          </div>
          {(entity ||
            (!isCustomer && groupName) ||
            (isTask && season) ||
            (isTask && !isBundledTask) ||
            (isCpa && companyGroup?.assigned_agent?.name)) && (
            <span className="t-flex t-flex-wrap t-items-center t-gap-2">
              <ConditionalLink
                to={entityLink}
                className="t-flex t-text-body-sm t-text-text-30 after:t-ml-2 hover:t-text-purple hover:!t-underline"
              >
                {!isCustomer && groupName && (
                  <>
                    <span className="t-flex t-items-end t-text-body-sm ">
                      {groupName}
                    </span>
                    {groupName && entity && <span>&nbsp;-&nbsp;</span>}
                  </>
                )}
                {entity && (
                  <>
                    <ReactCountryFlag
                      style={{
                        width: "14px",
                        height: "10px",
                        marginRight: "4px",
                        marginTop: "3px",
                      }}
                      svg
                      countryCode={entity.code_alpha_2}
                    />
                    <span className="t-max-w-[120px] t-truncate">
                      {entity.name}
                    </span>
                  </>
                )}
              </ConditionalLink>

              {isTask && season && (
                <>
                  <span className="-t-translate-y-0.5">
                    <Seperator />
                  </span>
                  <span className="t-text-body-sm t-text-neutral">
                    {season}
                  </span>
                </>
              )}

              {isTask && !isBundledTask && (
                <>
                  <span className="-t-translate-y-0.5">
                    <Seperator />
                  </span>

                  <Badge
                    color={TASK_STATE_TO_COLOR[taskStateType]}
                    size="small"
                  >
                    {taskState}
                  </Badge>
                </>
              )}
              {isCpa && companyGroup?.assigned_agent?.name && (
                <>
                  <span className="-t-translate-y-0.5">
                    <Seperator />
                  </span>
                  <Headset color="grey" />
                  <div className="t-text-body-sm t-text-text-30">
                    {companyGroup?.assigned_agent?.name}
                  </div>
                </>
              )}
            </span>
          )}
        </div>

        {actionsAvailable && (
          <div className="t-flex t-flex-col t-gap-1 t-ml-auto">
            <div className="t-flex t-gap-1">
              <Button
                size="small"
                customType="ghost_icon"
                onClick={onSearchClick}
              >
                <Search />
              </Button>
              <ConditionalLink
                className="t-flex t-items-center"
                to={documentLink}
              >
                <Button size="small" customType="ghost_icon">
                  <FolderIcon />
                </Button>
              </ConditionalLink>
              <Button
                size="small"
                customType="ghost_icon"
                onClick={onSliderButtonClick}
              >
                <span className="t-text-text-30">
                  <SquareHalf />
                </span>
              </Button>
            </div>

            <div className="t-flex t-gap-3 t-justify-end">
              {Boolean(pendingActionCount) && (
                <button
                  className="all:unset hover:!t-bg-neutral-0 t-p-1 t-rounded"
                  onClick={onPendingActionSliderClick}
                >
                  <span className="t-text-body-sm t-text-text-30 t-flex t-gap-2 t-items-center">
                    <span className="t-text-red">
                      <WaringCircle />
                    </span>
                    <>{pendingActionCount > 99 ? "99+" : pendingActionCount}</>
                  </span>
                </button>
              )}
              {Boolean(pinnedMessageCount) && (
                <button
                  onClick={onPinnedItemsSliderClick}
                  className="all:unset hover:!t-bg-neutral-0 t-p-1 t-rounded"
                >
                  <span className="t-text-body-sm t-text-text-30 t-flex t-gap-2 t-items-center">
                    <span className="t-text-neutral-40">
                      <Pin />
                    </span>
                    {pinnedMessageCount! > 99 ? "99+" : pinnedMessageCount}
                  </span>
                </button>
              )}
            </div>

            <div />
          </div>
        )}
      </div>
      {isPrivateChannel && (
        <div className="t-flex t-items-center t-justify-center t-bg-dark_green-30 t-p-1.5">
          <img
            className="t-h-3.5"
            src={PrivateChatHeaderIcon}
            alt="private chat icon"
          />
          <span className="t-text-subtext-sm t-font-semibold t-text-dark_green-90">
            &nbsp; This is a private chat
          </span>
        </div>
      )}
      <ChatTicketModal />
    </>
  );
};
