import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { EditBankAccount } from "components/Connections/EditBankAccount";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { LockSecure } from "components/icons/LockSecure";
import { useBankConnect } from "hooks/useBankConnect";
import { useModal } from "hooks/useModal";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import { useParams } from "react-router-dom";
import BankCircular from "static/images/BankCircular.svg";
import {
  AutofillComponentProps,
  BankAutofill,
} from "types/Models/bankAutofill";
import { AddFilingManualBank } from "./AddFilingManualBank";

export const AddBankAccount = ({
  isOpenAdd,
  onClose,
  updateTaskDetails,
  entityId,
  groupId,
  onComplete,
}: AutofillComponentProps & {
  initialValue?: BankAutofill;
  updateTaskDetails: (value: BankAutofill) => void;
}) => {
  const { onConnect } = useBankConnect();
  const {
    isOpen: showAddManualBankModal,
    close: closeAddManualBankModal,
    open: openAddManualBankModal,
  } = useModal();
  const { taskId } = useParams<{ taskId: string }>();

  const openAddManualBank = () => {
    onClose();
    openAddManualBankModal();
  };

  const handleOnBankConnect = ({ connectionId }: { connectionId: string }) => {
    onConnect({
      connectionId,
      entityIdInArg: entityId,
      invokedFrom: `/filings/${taskId}`,
    });
    onComplete?.();
  };

  return (
    <>
      <Modal.Root open={isOpenAdd} onOpenChange={onClose}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Add a Bank Account</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <ConnectionsModal
              onConnect={handleOnBankConnect}
              close={onClose}
              entityIdFromParent={entityId}
              showAddManualBank={false}
              onComplete={onComplete}
            />
            <div className="t-mt-3">
              <AccountCard
                logo={
                  <img
                    src={BankCircular}
                    className="t-rounded-full t-w-8 t-h-8 "
                    alt=""
                  />
                }
                title="Add bank/card"
                description={
                  <>
                    Manually enter bank account or
                    <br />
                    credit card details.
                  </>
                }
                CTA={
                  groupId && (
                    <Button size="small" onClick={openAddManualBank}>
                      Add details <ArrowRight color="currentColor" />
                    </Button>
                  )
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-items-center t-gap-3">
              <div>
                <LockSecure />
              </div>
              <p className="t-m-0 t-text-text-30 t-text-body-sm">
                {/* TODO: Change to soc 2 once we have it  */}
                Inkle connects your account securely in compliance with industry
                standards. Inkle will only have read-only access to your
                transactions.
              </p>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <AddFilingManualBank
        isOpenEdit={showAddManualBankModal}
        isOpenAdd={false}
        onClose={closeAddManualBankModal}
        onSuccess={updateTaskDetails}
        entityId={entityId}
        groupId={groupId}
        autofillKey={""}
      />
      <EditBankAccount />
    </>
  );
};
