import { ReConnect } from "components/Connections/ReConnect";
import { AddManualBank } from "components/Connections/AddManualBank";
import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { EditBankAccount } from "components/Connections/EditBankAccount";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { LockSecure } from "components/icons/LockSecure";
import { LinkBankAccount } from "components/LinkBankAccount";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import BankCircular from "static/images/BankCircular.svg";
import Plaid from "static/images/Plaid.svg";
import {
  Connection,
  useGetAllConnectionsQuery,
} from "store/apis/booksConnections";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { openAddMaualBankModal } from "store/slices/connections";
import { Banks, ConnectionProvider, ErrorItem } from "types/Models/banks";
import { pluralize } from "utils/pluralize";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { motion } from "framer-motion";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import ToolTip from "components/design/toolTip";
import { DataSourceView } from "./DataSourceView/DataSourceView";
import * as RECONCILLATION_STATUS from "constants/reconcilliationStatus";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Info } from "components/icons/Info";
import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import StripeImage from "static/images/Stripe.svg";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useRoleBasedView } from "hooks/useRoleBasedView";

dayjs.extend(RelativeTime);

type BankConnection = {
  connection_provider: ConnectionProvider;
  uuid: string;
  name: string;
  direct_base_connection_id: string;
  description: string;
  entity_item_id: string;
  logo_url: string;
  last_successful_transaction_update: string;
  connection_type: "PLAID" | "DIRECT" | "MANUAL";
  is_connection_available: boolean;
  bank_accounts: {
    account_number: string;
    name: string;
    available_balance: number;
    uuid: string;
    last_reconcilation_status: "COMPLETED" | "ONGOING" | "PENDING";
    opening_balance: number | null;
    opening_balance_date: string | null;
    balance_matches: boolean;
  }[];
};

const AccountCardsSection = ({ children }: { children: ReactNode }) => {
  return (
    <motion.div
      layout
      className="t-grid t-grid-cols-[repeat(auto-fill,minmax(300px,1fr))] t-gap-x-6 t-gap-y-14"
    >
      {children}
    </motion.div>
  );
};

const Revenue = ({
  stripeConnectedBank,
  stripeConnection,
}: {
  stripeConnection: Connection;
  stripeConnectedBank?: BankConnection;
}) => {
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCpa } = useRoleBasedView();

  const {
    onConnect,
    isLoading: isConnectBankLoading,
    originalArgs,
  } = useBankConnect();

  if (stripeConnectedBank) {
    const {
      disconnectedStatus,
      hasLongerName,
      isReconciliationRequired,
      reconciliationTag,
      reconcilliationRequiredStatus,
      updatedAt,
    } = getConnectionInfoItem({ connection: stripeConnectedBank });

    return (
      <>
        <p className="t-m-0 t-text-subtitle">Revenue</p>

        <AccountCardsSection>
          <button
            key={stripeConnectedBank.uuid}
            className="all:unset"
            onClick={() =>
              history.push(`${url}/${stripeConnectedBank.uuid}${search}`)
            }
          >
            <ConnectionCard
              status={reconcilliationRequiredStatus || disconnectedStatus}
              logoUrl={stripeConnectedBank.logo_url}
              disconnected={
                !stripeConnectedBank.is_connection_available ||
                isReconciliationRequired
              }
              name={
                <div
                  className={classNames("t-flex t-gap-1 t-items-center", {
                    "t-flex-col": hasLongerName,
                  })}
                >
                  {stripeConnectedBank.connection_type === "PLAID" && (
                    <span
                      className={classNames({
                        "t-order-1": hasLongerName,
                        "t-order-2": !hasLongerName,
                      })}
                    >
                      <Tag
                        size="small"
                        rounded
                        icon={false}
                        tagType="light_grey"
                      >
                        Via plaid
                      </Tag>
                    </span>
                  )}
                  {stripeConnectedBank.connection_type === "MANUAL" && (
                    <span
                      className={classNames({
                        "t-order-1": hasLongerName,
                        "t-order-2": !hasLongerName,
                      })}
                    >
                      <Tag
                        size="small"
                        icon={false}
                        rounded
                        tagType="light_grey"
                      >
                        Manually added
                      </Tag>
                    </span>
                  )}
                  <span
                    className={classNames({
                      "t-order-2": hasLongerName,
                      "t-order-1": !hasLongerName,
                    })}
                  >
                    {stripeConnectedBank.name}
                  </span>
                </div>
              }
              description={`${pluralize(
                stripeConnectedBank.bank_accounts?.length || 0,
                "account",
                "accounts"
              )} ${
                stripeConnectedBank.is_connection_available
                  ? "connected"
                  : "disconnected"
              }`}
              updatedAt={reconciliationTag || updatedAt}
              total={stripeConnectedBank.bank_accounts?.reduce(
                (acc, account) => acc + account.available_balance,
                0
              )}
              CTA={
                stripeConnectedBank.is_connection_available ? (
                  <Button size="small">View</Button>
                ) : (
                  <ReConnect
                    isLoading={
                      isConnectBankLoading &&
                      originalArgs?.connectionId ===
                        stripeConnectedBank.direct_base_connection_id
                    }
                    onConnect={onConnect}
                    connection={stripeConnectedBank}
                  />
                )
              }
            />
          </button>
        </AccountCardsSection>
      </>
    );
  }

  return (
    <>
      <p className="t-m-0 t-text-subtitle">Revenue</p>
      <AccountCardsSection>
        <button
          key={stripeConnection.uuid}
          className="all:unset"
          disabled={
            isConnectBankLoading &&
            originalArgs?.connectionId === stripeConnection.uuid
          }
          onClick={() =>
            onConnect({
              connectionId: stripeConnection.uuid,
              invokedFrom: isCpa
                ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                : `/books/data-sources?entity=${entityId}`,
            })
          }
        >
          <ConnectionCard
            logo={<img src={StripeImage} alt="Stripe" />}
            name="Stripe"
            description={
              stripeConnection.description ||
              "Direct API integration for fetching revenue data."
            }
            CTA={
              <Button
                isLoading={
                  isConnectBankLoading &&
                  originalArgs?.connectionId === stripeConnection.uuid
                }
                disabled={
                  isConnectBankLoading &&
                  originalArgs?.connectionId === stripeConnection.uuid
                }
                size="small"
                onClick={() =>
                  onConnect({
                    connectionId: stripeConnection.uuid,
                    invokedFrom: isCpa
                      ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                      : `/books/data-sources?entity=${entityId}`,
                  })
                }
              >
                Connect
              </Button>
            }
          />
        </button>
      </AccountCardsSection>
    </>
  );
};

export const AccountCard = ({
  logo,
  description,
  title,
  CTA,
}: {
  logo: ReactNode;
  description: ReactNode;
  title: string;
  CTA: ReactNode;
}) => {
  return (
    <div className="t-border t-border-solid t-border-neutral-0 t-rounded t-p-3 t-flex t-justify-between t-items-center t-w-full">
      <div className="t-flex t-gap-3 t-items-center">
        {logo}
        <div>
          <p className="t-mb-1 t-text-body-lg">{title}</p>
          <p className="t-m-0 t-text-body-sm t-text-text-30">{description}</p>
        </div>
      </div>

      {CTA}
    </div>
  );
};

const getConnectionInfoItem = ({
  connection,
}: {
  connection: BankConnection;
}) => {
  const isReconciliationRequired = connection.bank_accounts?.some(
    (acc) =>
      !acc.balance_matches &&
      acc.last_reconcilation_status !== RECONCILLATION_STATUS.COMPLETED
  );

  const reconcilliationRequiredStatus = isReconciliationRequired
    ? "Reconciliation required"
    : "";

  const disconnectedStatus = !connection.is_connection_available
    ? "Accounts disconnected"
    : "";

  const reconciliationTag = isReconciliationRequired ? (
    connection.is_connection_available && (
      <Tag tagType="red" icon={false}>
        Reconciliation required
      </Tag>
    )
  ) : (
    <></>
  );

  const hasLongerName = connection.name.length > 12;

  const updatedAt = connection.last_successful_transaction_update ? (
    connection.is_connection_available && (
      <span className="t-text-body-sm t-text-dark_green-50">
        Updated {dayjs(connection.last_successful_transaction_update).fromNow()}
      </span>
    )
  ) : (
    <></>
  );

  return {
    isReconciliationRequired,
    reconcilliationRequiredStatus,
    disconnectedStatus,
    reconciliationTag,
    hasLongerName,
    updatedAt,
  };
};

export const ConnectionCard = ({
  logoUrl,
  disconnected,
  name,
  description,
  updatedAt,
  total,
  CTA,
  status,
  logo,
}: {
  disconnected?: boolean;
  logo?: ReactNode;
  logoUrl?: string;
  name: ReactNode;
  description: string;
  updatedAt?: ReactNode;
  total?: number;
  CTA: ReactNode;
  status?: string;
}) => {
  return (
    <motion.div
      layout
      className="t-pb-5 t-flex t-flex-col t-flex-1 t-justify-center t-items-center t-px-9 t-pt-14 hover:t-shadow-[0px_1px_32px_4px_#1F0C5C14] t-shadow-[0px_1px_8px_-1px_#1F0C5C14] t-rounded-lg t-relative t-overflow-visible t-min-h-56 t-bg-surface t-h-full t-transition-shadow"
    >
      <div className="t-absolute t-top-0 t-left-1/2 -t-translate-y-1/2 -t-translate-x-1/2 t-p-4 t-shadow-[0px_0.83px_8px_0px_#1F0C5C0A] t-bg-white t-rounded-md">
        <div className="t-relative">
          {logoUrl ? (
            <img
              src={logoUrl}
              className="t-rounded-full t-w-10 t-h-10"
              alt=""
            />
          ) : (
            logo || (
              <img
                src={BankCircular}
                className="t-rounded-full t-w-10 t-h-10"
                alt=""
              />
            )
          )}
        </div>

        {disconnected && (
          <ToolTip text={status || "Accounts disconnected"}>
            <span className="t-border-red-10 t-border-solid t-border-[3px] t-rounded-full t-w-4 t-h-4 t-overflow-hidden t-absolute t-bottom-4 t-right-4">
              <span className="t-w-4 t-h-4 t-bg-red t-block" />
            </span>
          </ToolTip>
        )}

        {updatedAt && !disconnected && (
          <ToolTip text={status || "Accounts connected"}>
            <span className="t-border-green-10 t-border-solid t-border-[3px] t-rounded-full t-w-4 t-h-4 t-overflow-hidden t-absolute t-bottom-4 t-right-4">
              <span className="t-w-4 t-h-4 t-bg-dark_green t-block" />
            </span>
          </ToolTip>
        )}
      </div>
      <span className="t-text-subtitle-sm t-text-text-60">{name}</span>

      {(total || total === 0) && (
        <span className="t-text-h4 t-pt-1">
          <AmountSuperScript amount={total} />
        </span>
      )}

      {description && (
        <span className="t-text-body t-text-text-30 t-text-center t-pt-2">
          {description}
        </span>
      )}

      {updatedAt && <span className="t-pt-2">{updatedAt}</span>}

      <div className="t-mt-auto t-pt-4">{CTA}</div>
    </motion.div>
  );
};

export const DataSourcesList = () => {
  usePageTitle("Data Sources");
  const { url, path } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    open: openConnectionModal,
    close: closeConnectionModal,
    isOpen: isConnectionModalOpen,
  } = useModal();

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isCpa } = useRoleBasedView();

  const {
    connections,
    stripeConnection,
    isLoading: connectionsLoading,
    isSuccess: connectionsLoaded,
  } = useGetAllConnections({
    groupId: groupId!,
    entityId: entityId!,
  });

  const {
    data: plaidConnections,
    isLoading: bankAccountsLoading,
    isSuccess: banksAccountsLoaded,
  } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const bankAccounts: Record<string, Banks[]> | undefined =
    plaidConnections?.accounts.reduce((acc, obj) => {
      const name = obj.bank_brand.name;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(obj);
      return acc;
    }, {} as Record<string, Banks[]>);

  const bankItems: Record<string, ErrorItem[]> | undefined =
    plaidConnections?.items.reduce((acc, obj) => {
      const name = obj.bank_brand.name;

      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push(obj);
      return acc;
    }, {} as Record<string, ErrorItem[]>);

  const allBankConnections =
    bankAccounts && bankItems
      ? Object.entries(bankAccounts).map(([bank, banks]) => ({
          connection_provider: banks[0].bank_brand.connection_provider,
          uuid: bankItems[bank][0].uuid,
          name: bank,
          direct_base_connection_id: bankItems[bank][0].bank_brand.uuid,
          description: "",
          entity_item_id: bankItems[bank][0].uuid,
          logo_url: banks[0].bank_brand.logo,
          last_successful_transaction_update:
            banks[0].last_successful_transaction_update,
          connection_type: banks[0].account.connection_type,
          is_connection_available: bankItems[bank].every(
            (a) => a.connection_available && !a.in_error_state
          ),
          update_available: bankItems[bank].some((a) => a.update_required),
          bank_accounts: banks
            .map((bank) => bank.account)
            .map((account) => ({
              account_number: account.mask ? `•••• ${account.mask}` : "",
              name: account.nickname,
              available_balance: account.current_balance,
              uuid: account.uuid,
              last_reconcilation_status: account.last_reconcilation_status,
              opening_balance: account.opening_balance,
              opening_balance_date: account.opening_balance_date,
              balance_matches: account.balance_matches,
            })),
        }))
      : [];

  const stripeConnectedBank = allBankConnections.find(
    ({ connection_provider }) => connection_provider === "STRIPE"
  );

  const bankConnections = allBankConnections.filter(
    ({ name, connection_provider }) => connection_provider !== "STRIPE"
  );

  const nonConnectedConnections = connections?.filter(
    (c) => !c.bank_accounts || c.bank_accounts?.length === 0
  );

  const { accounts: totalAccounts = 0, current_balance: totalBalance = 0 } =
    plaidConnections?.aggregate || {};

  const {
    onConnect,
    isLoading: isConnectBankLoading,
    originalArgs,
  } = useBankConnect();

  return (
    <Async.Root
      isSuccess={connectionsLoaded && banksAccountsLoaded}
      isLoading={connectionsLoading || bankAccountsLoading}
      isEmpty={false}
    >
      <Async.Success>
        <div className="t-flex t-flex-col t-gap-14 t-pb-6">
          <div className="t-flex t-flex-col t-gap-5">
            <div className="t-flex t-items-center t-justify-center t-py-5 t-text-center t-flex-col t-gap-1 t-bg-[url('/src/static/images/TotalbalanceUI.svg')] t-bg-no-repeat t-bg-cover t-rounded-lg t-w-full t-overflow-hidden t-bg-center t-border t-border-solid t-border-blue-10">
              <div className="t-text-h5 t-text-text-black">
                {<AmountSuperScript amount={totalBalance} />}
              </div>
              <div className="t-text-body t-text-text-60 t-flex t-gap-1">
                <span>
                  Total cash balance of{" "}
                  {pluralize(totalAccounts, "account", "accounts")}
                </span>
                <ToolTip text="This balance does not include credit card balances.">
                  <span className="t-text-text-30">
                    <Info />
                  </span>
                </ToolTip>
              </div>
            </div>
            <p className="t-m-0 t-text-subtitle t-flex t-justify-between">
              <span>Banking Institution</span>
              <Button
                onClick={openConnectionModal}
                customType="primary"
                size="small"
              >
                Connect
              </Button>
            </p>
          </div>

          <AccountCardsSection>
            {bankConnections?.map((connection) => {
              const {
                disconnectedStatus,
                hasLongerName,
                isReconciliationRequired,
                reconciliationTag,
                reconcilliationRequiredStatus,
                updatedAt,
              } = getConnectionInfoItem({ connection });

              return (
                <button
                  key={connection.uuid}
                  className="all:unset"
                  onClick={() =>
                    history.push(`${url}/${connection.uuid}${search}`)
                  }
                >
                  <ConnectionCard
                    status={reconcilliationRequiredStatus || disconnectedStatus}
                    logoUrl={connection.logo_url}
                    disconnected={
                      !connection.is_connection_available ||
                      isReconciliationRequired
                    }
                    name={
                      <div
                        className={classNames("t-flex t-gap-1 t-items-center", {
                          "t-flex-col": hasLongerName,
                        })}
                      >
                        {connection.connection_type === "PLAID" && (
                          <span
                            className={classNames({
                              "t-order-1": hasLongerName,
                              "t-order-2": !hasLongerName,
                            })}
                          >
                            <Tag
                              size="small"
                              rounded
                              icon={false}
                              tagType="light_grey"
                            >
                              Via plaid
                            </Tag>
                          </span>
                        )}
                        {connection.connection_type === "MANUAL" && (
                          <span
                            className={classNames({
                              "t-order-1": hasLongerName,
                              "t-order-2": !hasLongerName,
                            })}
                          >
                            <Tag
                              size="small"
                              icon={false}
                              rounded
                              tagType="light_grey"
                            >
                              Manually added
                            </Tag>
                          </span>
                        )}
                        <span
                          className={classNames({
                            "t-order-2": hasLongerName,
                            "t-order-1": !hasLongerName,
                          })}
                        >
                          {connection.name}
                        </span>
                      </div>
                    }
                    description={`${pluralize(
                      connection.bank_accounts?.length || 0,
                      "account",
                      "accounts"
                    )} ${
                      connection.is_connection_available
                        ? "connected"
                        : "disconnected"
                    }`}
                    updatedAt={reconciliationTag || updatedAt}
                    total={connection.bank_accounts?.reduce(
                      (acc, account) => acc + account.available_balance,
                      0
                    )}
                    CTA={
                      <div className="t-flex t-flex-col t-gap-1 t-justify-center">
                        {connection.is_connection_available &&
                        !connection.update_available ? (
                          <Button size="small">View</Button>
                        ) : (
                          <div className="t-flex t-flex-col t-gap-4 t-justify-center">
                            {connection.update_available && (
                              <span className="t-text-red t-text-body-sm">
                                Update required
                              </span>
                            )}
                            <ReConnect
                              type={
                                connection.update_available
                                  ? "UPDATE"
                                  : "RECONNECT"
                              }
                              isLoading={
                                isConnectBankLoading &&
                                originalArgs?.connectionId ===
                                  connection.direct_base_connection_id
                              }
                              onConnect={onConnect}
                              connection={connection}
                            />
                          </div>
                        )}
                      </div>
                    }
                  />
                </button>
              );
            })}

            {nonConnectedConnections?.map((connection) => (
              <button
                key={connection.uuid}
                className="all:unset"
                onClick={() =>
                  onConnect({
                    connectionId: connection.uuid,
                    invokedFrom: isCpa
                      ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                      : `/books/data-sources?entity=${entityId}`,
                  })
                }
              >
                <ConnectionCard
                  logoUrl={connection.logo_url}
                  name={connection.name}
                  description={
                    connection.description ||
                    "Direct API integration for fetching transactions data"
                  }
                  CTA={
                    <Button
                      isLoading={
                        isConnectBankLoading &&
                        originalArgs?.connectionId === connection.uuid
                      }
                      size="small"
                    >
                      Connect
                    </Button>
                  }
                />
              </button>
            ))}

            {groupId && (
              <LinkBankAccount groupId={groupId} entityId={entityId}>
                {({ ready, connectPlaid }) => (
                  <button
                    className="all:unset"
                    disabled={!ready}
                    onClick={(e) => {
                      if (document.body) {
                        document.body.style.pointerEvents = "auto";
                      }
                      connectPlaid(e);
                    }}
                  >
                    <ConnectionCard
                      logoUrl={Plaid}
                      name="Other banking institutions"
                      description="Connect other banks securely via Plaid"
                      CTA={
                        <Button size="small" disabled={!ready}>
                          Connect
                        </Button>
                      }
                    />
                  </button>
                )}
              </LinkBankAccount>
            )}

            <button
              className="all:unset"
              onClick={() => dispatch(openAddMaualBankModal())}
            >
              <ConnectionCard
                name="Add bank/card"
                description="Manually enter bank account or credit card details."
                CTA={<Button size="small">Add details</Button>}
              />
            </button>
          </AccountCardsSection>
          {stripeConnection && (
            <Revenue
              stripeConnection={stripeConnection}
              stripeConnectedBank={stripeConnectedBank}
            />
          )}
        </div>
        <Modal.Root
          open={isConnectionModalOpen}
          onOpenChange={closeConnectionModal}
        >
          <Modal.Content size="large">
            <Modal.Header>
              <Modal.Title>Connect</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <ConnectionsModal
                onConnect={onConnect}
                close={closeConnectionModal}
                onComplete={closeConnectionModal}
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-items-center t-gap-3">
                <div>
                  <LockSecure />
                </div>
                <p className="t-m-0 t-text-text-30 t-text-body-sm">
                  {/* TODO: Change to soc 2 once we have it  */}
                  Inkle connects your account securely in compliance with
                  industry standards. Inkle will only have read-only access to
                  your transactions.
                </p>
              </div>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Root>
        <AddManualBank />
        <EditBankAccount />

        <Switch>
          <Route path={`${path}/:dataSourceId`}>
            {({ match }) => {
              const connection = allBankConnections.find(
                (bank) => bank.uuid === match?.params.dataSourceId
              );
              if (!connection) {
                return null;
              }

              return (
                <DataSourceView
                  connection={connection}
                  onClose={() => history.push(`${url}${search}`)}
                />
              );
            }}
          </Route>
        </Switch>
      </Async.Success>
    </Async.Root>
  );
};
