import { Button } from "components/DesignSystem/Button/Button";
import { AddShareholder } from "components/Entity/Shareholders/AddShareholder";
import ShareHolderDelete from "components/Entity/Shareholders/ShareHolderDelete";
import { ShareholderType } from "components/fileTax/ShareholderAutofill";
import { Cross } from "components/icons/Cross";
import { useModal } from "hooks/useModal";
import GrayPencil from "static/images/GrayPencil.svg";
import { Shareholder } from "types/Models/Shareholders";

export const ShareholderDetailsCard = ({
  shareholder,
  entityId,
}: {
  shareholder: Shareholder;
  entityId: string;
}) => {
  const shareholderData = [
    { label: "Name", value: shareholder.name },
    {
      label: "Shareholder Type",
      value: shareholder.shareholder_type
        ? ShareholderType[
            shareholder.shareholder_type as keyof typeof ShareholderType
          ]
        : "-",
    },
    { label: "TIN", value: shareholder.tin },
    { label: "Citizenship", value: shareholder.country?.name },
    {
      label: "Ownership Stake",
      value: shareholder.ownership_stake
        ? shareholder.ownership_stake + "%"
        : "-",
    },
    { label: "Address", value: shareholder.address?.autofill_string },
  ];
  const deleteModal = useModal();
  const editModal = useModal();

  return (
    <div className="t-mb-3">
      <div className="t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg">
        <div className="t-flex t-justify-between">
          <div className="t-grid t-gap-x-20 t-gap-y-2 t-grid-cols-2 t-w-4/5">
            {shareholderData?.map(({ value, label }) => {
              return (
                <div className="t-space-y-1" key={label}>
                  <div className="t-text-text-30 t-text-body-sm">{label}</div>
                  <div className="t-text-text-100 t-w-full t-overflow-x-hidden t-text-subtext t-truncate t-whitespace-nowrap t-text-ellipsis">
                    {value || "-"}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="t-flex t-gap-2">
            <Button
              onClick={editModal.open}
              customType="transparent"
              size="small"
              type="button"
            >
              <img src={GrayPencil} className="t-h-4 t-m-1" alt="GrayPencil" />
            </Button>
            <Button
              onClick={deleteModal.open}
              customType="transparent"
              size="small"
              type="button"
            >
              <span className="t-text-text-30">
                <Cross />
              </span>
            </Button>
          </div>
        </div>
      </div>
      <ShareHolderDelete shareholderId={shareholder.uuid} {...deleteModal} />
      <AddShareholder
        {...editModal}
        shareholder={shareholder}
        entityId={entityId}
      />
    </div>
  );
};
