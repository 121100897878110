import { NotificationNudge } from "NotificationNudge";
import { ChatPreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { OnboardingWidget } from "components/OnboardingWidget/OnboardingWidget";
import { AddOpenItem } from "components/OpenItem/AddOpenItem";
import { ClarifyModal } from "components/OpenItem/ClarifyModal";
import ProductUpdatesModal from "components/ProductUpdates/ProductUpdatesModal";
import { RequestDocumentModal } from "components/RequestDocumentModal/RequestDocumentModal";
import { SlackConnectedModal } from "components/Slack/SlackConnectedModal";
import { AddRequestInfo } from "components/Transaction/AddRequestInfo";
import CardAdd from "components/billing/CardAdd";
import { MeetingWithTranq } from "components/chat/MeetingWithTranq";
import { BalancingPaymentReviewAndPay } from "components/chat/ReviewAndBalancePayment/BalancingPaymentReviewAndPay";
import FreeSubscriptionModal from "components/tasks/task7004/FreeSubscriptionModal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useHistory, useRouteMatch } from "react-router-dom";
import { closeOnboardingWidget } from "store/slices/onboardingWidget";
import {
  closeCardAddModal,
  closeFreeSubscription,
} from "store/slices/subscriptionModal";
import { DocumentReview } from "components/chat/DocumentReview/DocumentReview";
import { TransactionHiddenByFilterConfirmation } from "components/TransactionHiddenByFilterConfirmation/TransactionHiddenByFilterConfirmation";
import { PlanBaseSubscriptionModal } from "components/SubscriptionModal/PlanBaseSubscriptionModal";
import { useShowNotificationsNudgeQuery } from "store/apis/notifiaction";
import { useEffect } from "react";
import { setNotificationNudgeModal } from "store/slices/notification";

export const GlobalModals = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isCustomer } = useRoleBasedView();
  const { url } = useRouteMatch();

  const { isOpenFreeSubscription, isOpenCardAddModal } = useAppSelector(
    (state) => state.subscriptionModal
  );
  const { isOpen: isOnboardingWidgetOpen, product } = useAppSelector(
    (state) => state.onboardingWidget
  );

  const { showDocumentReviewModal } = useAppSelector(
    (state) => state.reviewAndBalancePayment
  );

  const {
    data: notificationsNudge,
    isFetching,
    isSuccess,
  } = useShowNotificationsNudgeQuery();

  const closeFreeSubscriptionModal = () => {
    dispatch(closeFreeSubscription());
    history.push(url);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setNotificationNudgeModal(notificationsNudge));
    }
  }, [isSuccess]);

  return (
    <>
      <FreeSubscriptionModal
        openSubscription={isOpenFreeSubscription}
        closeSubscription={closeFreeSubscriptionModal}
      />
      <NotificationNudge />
      {isOpenCardAddModal && (
        <CardAdd
          openAddCard={isOpenCardAddModal}
          closeAddCard={() => dispatch(closeCardAddModal())}
        />
      )}

      <PlanBaseSubscriptionModal />

      <OnboardingWidget
        open={isOnboardingWidgetOpen}
        onClose={() => dispatch(closeOnboardingWidget())}
        currentTab={product}
      />

      {isCustomer && <ProductUpdatesModal />}
      {showDocumentReviewModal && <DocumentReview />}
      <AddRequestInfo />
      <RequestDocumentModal />
      <ChatPreviewModal />
      <AddOpenItem />
      <ClarifyModal />
      <MeetingWithTranq />
      <BalancingPaymentReviewAndPay />
      <SlackConnectedModal />
      <TransactionHiddenByFilterConfirmation />
    </>
  );
};
