// Not a compound component as the logic is interlinked
//
// Entry points for payments:
// - Task (Invoice gets created when you pay)
// - Subscription (Supported)
// - Revive Subscription (No support)
// - Add credit (No support)
//  - Create invoice internally and pays the invoice
// - Pay single/multiple invoice (Partial support added, has to be tested)
//   - Ticket
//   - Invoice pay from billing
// - Cart (Supported)
//  - Cart Subscriptions and one time invoice

// Features
// - Add cards
// - use saved cards

import * as Accordion from "@radix-ui/react-accordion";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { profileData } from "apis/profileData";
import classNames from "classnames";
import { AddCardModal } from "components/billing/AddCardModal";
import PaymentBillingAddress from "components/billing/PaymentBillingAddress";
import PracticePaymentBillingAddress from "components/billing/PracticePaymentBillingAddress";
import SavedCards from "components/billing/SavedCards";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { BareInput, Label } from "components/DesignSystem/TextInput/TextInput";
import { ArrowRight } from "components/icons/ArrowRight";
import { CaretRight } from "components/icons/CaretRight";
import { AddPaymentMethod } from "components/PracticeBilling/MyBilling/AddPaymentMethod";
import { CARD_ACCORDION } from "constants/billing";
import { US } from "constants/countryCodes";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { BankLogos, BILLING_CYCLE } from "dictionaries";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { MouseEvent, ReactNode, useContext, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { NumericFormat } from "react-number-format";
import PoweredByStripe from "static/images/PoweredByStripe.svg";
import { useGetPracticeAutofillQuery } from "store/apis/practiceAutofill";
import { useGetPaymentMethodsQuery } from "store/apis/practiceBilling";
import {
  billingApis,
  useGetAllSavedCardsQuery,
  useGetBrexPointsQuery,
} from "store/apis/billing";
import { Cart } from "store/apis/products";
import { useGetCreditsQuery } from "store/apis/refrral";
import { useCreateSubscriptionMutation } from "store/apis/subscriptions";
import { BillingInvoice } from "types/Models/billing";
import { Subscription } from "types/Models/subscription";
import { Task } from "types/Models/task";
import { ArrayElement } from "types/utils/ArrayElement";
import { BackendError } from "types/utils/error";
import {
  CheckoutModalPropsContext,
  CheckoutModalPropsContextType,
} from "./CheckoutModalPropsContext";
import {
  getCreditUsageForBulkInvoices,
  getCreditUsageForCart,
  getCreditUsageForTask,
} from "./creditUtils";
import { PaymentButton } from "./PaymentButton";
import { useBillingAddress } from "./useBillingAddress";
import { InkleCreditCoin } from "components/icons/InkleCreditCoin";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { BrexPointLogo } from "components/icons/BrexPointLogo";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { formatNumber } from "utils/formatNumber";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useBankConnect } from "hooks/useBankConnect";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useDispatch } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

export const useSavedCards = ({
  serviceTeamId,
  groupId,
  entityId,
}: {
  serviceTeamId?: string;
  groupId?: string;
  entityId?: string;
}) => {
  const { data: practiceSavedCards = [], ...practiceStates } =
    useGetPaymentMethodsQuery(
      { serviceTeamId: serviceTeamId! },
      { skip: !serviceTeamId }
    );

  const { data: customersavedCards = [], ...customerStates } =
    useGetAllSavedCardsQuery(
      { groupId: groupId!, entityId: entityId! },
      { skip: !groupId || !entityId || Boolean(serviceTeamId) }
    );

  const isLoading = practiceStates.isLoading || customerStates.isLoading;
  const isSuccess = practiceStates.isSuccess && customerStates.isSuccess;
  const data = serviceTeamId ? practiceSavedCards : customersavedCards;

  return { isLoading, isSuccess, data };
};

const Step = ({
  setStep,
  title,
  stepNumber,
  active,
}: {
  setStep: (step: "DETAILS" | "PAYMENT") => void;
  title: ReactNode;
  stepNumber: number;
  active: boolean;
}) => {
  return (
    <button
      onClick={() => setStep("DETAILS")}
      className={classNames("all:unset p-0 t-flex t-items-center", {
        "t-text-text-black": active,
        "t-text-neutral": !active,
      })}
    >
      <div
        className={classNames(
          "t-mr-2 t-flex t-max-h-[22px] !t-max-w-[22px] t-justify-center t-items-center t-rounded-lg t-p-2 t-text-body-sm ",
          {
            "t-bg-purple t-text-surface": active,
            "t-bg-neutral-10 t-text-text-black": !active,
          }
        )}
      >
        {stepNumber}
      </div>
      <span>{title}</span>
    </button>
  );
};

const PaymentHeader = ({
  title,
  setStep,
  step,
  isScheduledPayment,
}: {
  title: string;
  step: "DETAILS" | "PAYMENT";
  setStep: (step: "DETAILS" | "PAYMENT") => void;
  onClose: () => void;
  isScheduledPayment?: boolean;
}) => {
  return (
    <div className="t-w-full t-text-body-sm">
      <div className="t-flex t-w-full t-items-center t-justify-between">
        <div className="t-w-full">
          <div className="t-flex t-justify-between t-items-center">
            <div className="t-text-h6 t-font-semibold">{title}</div>
            <Modal.Close />
          </div>
          {!isScheduledPayment && (
            <div className="t-mt-2 t-flex t-items-center">
              <Step
                title="Payment method"
                setStep={setStep}
                stepNumber={1}
                active={step === "DETAILS"}
              />

              <ArrowRight />
              <Step
                active={step === "PAYMENT"}
                title="Review & pay"
                setStep={setStep}
                stepNumber={2}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectBillingEntity = () => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const group = useCurrentGroupContext();

  const entityOptions = group.entities
    .filter((g) => g.country_code === US)
    .map(({ name, uuid, country, country_code }) => ({
      label: (
        <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
          <ReactCountryFlag countryCode={country_code} svg title={country} />
          <span className="t-truncate t-max-w-36">{name}</span>
        </div>
      ),
      value: uuid,
    }));

  if (
    (propsContext?.type === "invoices" ||
      propsContext?.type === "credits" ||
      propsContext?.type === "subscription") &&
    !propsContext.isEntityProvided
  ) {
    const currentEntity = entityOptions.find(
      (entity) => entity.value === propsContext.entityId
    );
    return (
      <Combobox
        label="Billing entity"
        menuPortalTarget={document.body}
        options={entityOptions}
        // @ts-ignore
        onChange={(value) => {
          if (value) {
            if (value instanceof Array) {
              return null;
            }

            propsContext.setEntityId(value.value);
          }
        }}
        value={currentEntity || null}
      />
    );
  }

  return null;
};

const AddCredits = () => {
  const parentPropsContext = useContext(CheckoutModalPropsContext);
  // @ts-ignore
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    {
      groupId: parentPropsContext?.groupId!,
    },
    { skip: !parentPropsContext?.groupId }
  );

  if (parentPropsContext?.type === "credits") {
    const { setCreditsToBeAdded, creditsToBeAdded } = parentPropsContext;

    const onChange = (value: number) => {
      console.log(value);
      setCreditsToBeAdded?.(value);
    };

    return (
      <div className="t-w-full t-flex t-justify-between t-items-center t-bg-surface-purple t-border t-border-solid t-border-purple-10 t-p-3 t-rounded">
        <div>
          <Label>Add credits</Label>

          <NumericFormat
            defaultValue={creditsToBeAdded}
            onClick={(event: MouseEvent<HTMLInputElement>) => {
              // @ts-ignore
              if (event.target.value?.includes?.("0.00")) {
                // @ts-ignore
                event.target?.select();
              }
            }}
            customInput={BareInput}
            onValueChange={({ floatValue }) => {
              if (floatValue) {
                onChange(floatValue);
              }
            }}
            onChange={() => {}}
          />
        </div>
        <div className="t-flex t-items-center t-gap-1">
          <InkleCreditCoin />
          <div className="t-text-body t-text-text-30">
            {total_credits} Inkle Credits
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const UseCredits = () => {
  const parentPropsContext = useContext(CheckoutModalPropsContext);
  const dispatch = useDispatch();

  // @ts-ignore
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    {
      groupId: parentPropsContext?.groupId!,
    },
    { skip: !parentPropsContext?.groupId }
  );

  const { data: brexPoints, isFetching } = useGetBrexPointsQuery(
    {
      entityId: parentPropsContext?.entityId!,
    },
    {
      skip: !parentPropsContext?.entityId,
    }
  );

  const group = useCurrentGroupContext();
  const entityId = parentPropsContext?.entityId;

  const { connections } = useGetAllConnections({
    groupId: group.uuid!,
    entityId: entityId!,
  });

  const { onConnectPopup, isLoading: isConnecting } = useBankConnect({
    onConnectSuccess: async () => {
      dispatch(billingApis.util.invalidateTags(["CONNECTION"]));
    },
  });

  const onBrexConnect = async () => {
    const brexConnection = connections?.find(
      (c) => c.connection_provider === "BREX"
    );

    if (parentPropsContext?.entityId && brexConnection) {
      onConnectPopup({
        connectionId: brexConnection?.uuid,
        entityId: parentPropsContext?.entityId,
      });
    }
  };

  const showCredits = Boolean(total_credits) && total_credits! > 1;
  const showBrexPoints =
    brexPoints?.connection && brexPoints.connection.connection_available;

  if (parentPropsContext?.type === "subscription") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-flex-col t-gap-1">
          <p className="t-text-subtext t-font-semibold t-text-text-100 t-m-0 t-leading-none">
            Credits and Points
          </p>
          <div className="t-flex t-gap-1 t-items-center">
            <span className="t-text-text-30 t-text-body-sm">
              You cannot use credits and points for subscription payments.
            </span>
          </div>
        </div>
        <div className="t-flex t-flex-col t-gap-3 t-border-solid t-border-neutral-0 t-rounded t-p-3 t-bg-surface-lighter-grey t-border t-relative">
          <div className="t-flex t-items-start t-gap-2">
            <div className="t-mt-0.5">
              <Checkbox name="Credit" />
            </div>

            <label htmlFor="Credit" className="t-cursor-pointer">
              <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                <span className="t-flex t-gap-1 t-text-text-60">
                  <InkleCreditCoin />
                  {formatNumber(Number(total_credits || 0))} Inkle Credits
                </span>

                <span className="t-block t-text-body-sm">
                  (Worth <AmountSuperScript amount={total_credits!} />)
                </span>
              </div>
            </label>
          </div>

          {brexPoints?.connection &&
            brexPoints.connection.connection_available && (
              <div className="t-flex t-items-start t-gap-2">
                <div className="t-mt-0.5">
                  <Checkbox name="BrexPoint" />
                </div>

                <label htmlFor="BrexPoint" className="t-cursor-pointer">
                  <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                    <span className="t-flex t-gap-1 t-text-text-60">
                      <BrexPointLogo />
                      {formatNumber(Number(brexPoints.connection.balance))} Brex
                      Points
                    </span>

                    <span className="t-block t-text-body-sm">
                      (Worth{" "}
                      <AmountSuperScript
                        amount={brexPoints.connection.available_usd}
                      />
                      )
                    </span>
                  </div>
                </label>
              </div>
            )}

          <div className="t-absolute t-inset-0 t-bg-white t-opacity-70 t-z-10 t-rounded t-cursor-not-allowed" />
        </div>
      </div>
    );
  }

  if (
    parentPropsContext?.type === "cart" ||
    parentPropsContext?.type === "invoices" ||
    parentPropsContext?.type === "task"
  ) {
    const { useCredits, setUseCredits, useBrexPoints, setUseBrexPoints } =
      parentPropsContext;

    return (
      <div className="t-flex t-flex-col t-gap-2">
        <p className="t-text-subtext t-font-semibold t-text-text-100 t-m-0 t-leading-none">
          Credits and Points
        </p>
        {(showBrexPoints || showCredits) && (
          <div className="t-flex t-flex-col t-gap-3 t-border-solid t-border-neutral-10 t-rounded t-p-3 t-bg-surface-lighter-grey t-border">
            {showCredits && (
              <div className="t-flex t-items-start t-gap-2">
                <div className="t-mt-0.5">
                  <Checkbox
                    name="Credit"
                    checked={useCredits}
                    onChange={(e) => setUseCredits(e.target.checked)}
                  />
                </div>

                <label htmlFor="Credit" className="t-cursor-pointer">
                  <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                    <span className="t-flex t-gap-1 t-text-text-60">
                      <InkleCreditCoin />
                      {formatNumber(Number(total_credits))} Inkle Credits
                    </span>

                    <span className="t-block t-text-body-sm">
                      (Worth <AmountSuperScript amount={total_credits!} />)
                    </span>
                  </div>
                </label>
              </div>
            )}

            {brexPoints?.connection &&
              brexPoints.connection.connection_available && (
                <div className="t-flex t-items-start t-gap-2">
                  <div className="t-mt-0.5">
                    <Checkbox
                      name="BrexPoint"
                      checked={useBrexPoints}
                      onChange={(e) => setUseBrexPoints(e.target.checked)}
                    />
                  </div>

                  <label htmlFor="BrexPoint" className="t-cursor-pointer">
                    <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                      <span className="t-flex t-gap-1 t-text-text-60">
                        <BrexPointLogo />
                        {formatNumber(
                          Number(brexPoints.connection.balance)
                        )}{" "}
                        Brex Points
                      </span>

                      <span className="t-block t-text-body-sm">
                        (Worth{" "}
                        <AmountSuperScript
                          amount={brexPoints.connection.available_usd}
                        />
                        )
                      </span>
                    </div>
                  </label>
                </div>
              )}
          </div>
        )}

        {brexPoints && !brexPoints?.connection && (
          <div className="t-flex t-gap-1.5 t-text-body-sm t-text-text-30 t-items-center">
            <BrexPointLogo />
            <p className="t-m-0">Pay using Brex points.</p>
            <Button
              isLoading={isConnecting || isFetching}
              disabled={isConnecting || isFetching}
              onClick={onBrexConnect}
              customType="link"
            >
              <span className="t-text-body-sm">Connect Brex account</span>
            </Button>
          </div>
        )}

        {brexPoints &&
          brexPoints?.connection &&
          !brexPoints?.connection?.connection_available && (
            <div className="t-flex t-gap-1.5 t-text-body-sm t-text-text-30 t-items-center">
              <BrexPointLogo />
              <p className="t-m-0">Pay using Brex points.</p>
              <Button
                isLoading={isConnecting || isFetching}
                disabled={isConnecting || isFetching}
                onClick={onBrexConnect}
                customType="link"
              >
                <span className="t-text-body-sm">Reconnect Brex account</span>
              </Button>
            </div>
          )}
      </div>
    );
  }

  return null;
};

const PaymentMethods = () => {
  const [showAddCard, setShowAddCard] = useState(false);
  const propsContext = useContext(CheckoutModalPropsContext);
  const serviceTeamId = useServiceTeamId();

  const isPayerFCA = propsContext?.payerUserType === "FCA";

  const { data: savedCards } = useSavedCards({
    serviceTeamId,
    groupId: propsContext?.groupId,
    entityId: propsContext?.entityId,
  });

  const defaultCard = savedCards?.find(
    ({ is_default_card }) => is_default_card
  );

  if (isPayerFCA && showAddCard) {
    return (
      <AddPaymentMethod
        isOpen={showAddCard}
        close={() => setShowAddCard(false)}
      />
    );
  }

  if (showAddCard && propsContext?.entityId) {
    return (
      <AddCardModal
        entityId={propsContext?.entityId}
        show
        closeModal={() => setShowAddCard(false)}
        cardsAdded={savedCards.length > 0}
        ispaymentFlow
      />
    );
  }

  if (
    (propsContext?.type === "subscription" || propsContext?.type === "cart") &&
    defaultCard
  ) {
    return (
      <div className="t-flex t-gap-3 t-flex-col">
        <div className="t-flex t-flex-col gap-2">
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-text-subtext t-font-semibold t-text-text-100 t-leading-none">
              Confirm Card
            </div>

            <div className="t-text-body-sm t-text-text-30">
              <span>
                The default card will be used for all future auto-payments.
              </span>
            </div>
          </div>

          <div className="t-flex t-items-center t-justify-between t-rounded t-border t-border-solid t-border-neutral-10">
            <span className="t-flex !t-flex-row t-items-center t-gap-3 t-p-3 t-w-full">
              <img
                alt="banklogo"
                src={
                  BankLogos.hasOwnProperty(defaultCard?.card.brand)
                    ? // @ts-ignore
                      BankLogos[defaultCard.card.brand]
                    : BankLogos["default"]
                }
                className="t-h-8 t-w-12"
              />

              <span className="t-flex t-flex-col t-w-full">
                <span className="t-flex t-w-full t-items-center">
                  <span className="t-pr-2 t-text-body t-text-text-30">
                    {defaultCard.card.brand[0].toUpperCase() +
                      defaultCard.card.brand.substring(1)}
                  </span>

                  <span className="t-pr-5 t-text-body t-text-text-30">
                    **** **** **** {defaultCard.card.last_four}
                  </span>
                </span>
                <span className="t-text-body-sm t-text-text-30">
                  Expiry {defaultCard.card.exp_month}/
                  {defaultCard.card.exp_year}
                </span>
              </span>

              {defaultCard.is_default_card && (
                <span className="t-ml-auto">
                  <Tag tagType="purple" icon={false} size="small">
                    Default
                  </Tag>
                </span>
              )}
            </span>
          </div>
          <ConditionalLink to="/billing/paymentmethod">
            <button
              className="all:unset t-cursor-pointer t-text-subtext-sm t-text-purple"
              type="button"
            >
              Update default card
            </button>
          </ConditionalLink>
        </div>
      </div>
    );
  }

  if (
    (propsContext?.type === "subscription" || propsContext?.type === "cart") &&
    !defaultCard
  ) {
    return (
      <div>
        <div className="t-flex t-w-full t-justify-between t-items-center">
          <div className="t-text-subtext t-text-text-100">Payment Methods</div>
          <Button
            size="small"
            onClick={() => setShowAddCard(true)}
            disabled={!propsContext.entityId}
          >
            Add card
          </Button>
        </div>
      </div>
    );
  }

  if (
    propsContext?.type === "cart" ||
    propsContext?.type === "invoices" ||
    propsContext?.type === "credits" ||
    propsContext?.type === "task"
  ) {
    const { paymentMethodId, setPaymentMethodId } = propsContext;
    const disabled = isPayerFCA ? false : !propsContext.entityId;

    return (
      <div className="t-flex t-flex-col t-gap-3">
        <div className="t-flex t-w-full t-justify-between t-items-center">
          <div className="t-text-subtext t-text-text-100">Payment Methods</div>
          <Button
            size="small"
            onClick={() => setShowAddCard(true)}
            disabled={disabled}
          >
            Add card
          </Button>
        </div>
        <div
          className={classNames(
            "t-border t-p-3 t-border-solid t-rounded t-border-neutral-10"
          )}
        >
          <Accordion.Root
            type="single"
            collapsible
            defaultValue={CARD_ACCORDION}
          >
            <Accordion.Item value={CARD_ACCORDION}>
              <Accordion.Trigger
                className={classNames(
                  "all:unset t-group t-w-full t-flex t-items-center t-justify-between"
                )}
              >
                <div className="t-text-subtext t-text-text-30">Saved cards</div>
                <div
                  className={classNames(
                    "group-data-state-open:-t-rotate-90 group-data-state-closed:t-rotate-90"
                  )}
                >
                  <CaretRight />
                </div>
              </Accordion.Trigger>
              <Accordion.Content>
                {savedCards?.length > 0 && (
                  <SavedCards
                    savedCards={savedCards}
                    selectedCard={paymentMethodId}
                    setSelectedCard={setPaymentMethodId}
                  />
                )}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </div>
    );
  }

  return null;
};

const EditPaymentDetails = ({
  isScheduledPayment,
}: {
  isScheduledPayment?: boolean;
}) => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const isPayerFCA = propsContext?.payerUserType === "FCA";

  if (isPayerFCA) {
    return (
      <div className="t-flex t-flex-col t-gap-4">
        <PaymentMethods />
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <SelectBillingEntity />
      {isScheduledPayment && <BillingAddress />}

      <AddCredits />

      <UseCredits />
      <hr className="t-m-0 t-text-neutral-0" />
      <PaymentMethods />
    </div>
  );
};

const CartSummaryItem = ({
  cartItem: {
    product_details: { company_entity, product_name, season },
    quantity,
    subscription,
    unit_price,
  },
}: {
  cartItem: ArrayElement<Cart["cart_items"]>;
}) => (
  <div className="t-mt-3 t-flex t-items-center t-text-body">
    <p className="t-m-0 t-text-text-30">
      <div>
        <p className="t-m-0">
          {product_name} (x {quantity})
        </p>
        <p className="t-m-0 t-flex t-gap-2 t-items-center t-text-body-sm t-text-text-30">
          {company_entity && (
            <ReactCountryFlag countryCode={company_entity?.country_code} svg />
          )}
          {company_entity?.name}{" "}
          {company_entity?.name && season && <span>|</span>}{" "}
          <span>{season}</span>
        </p>
      </div>
    </p>
    <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
      <AmountSuperScript amount={unit_price} />
      {subscription && (
        <span className="t-text-text-30">
          /{BILLING_CYCLE[subscription.billing_cycle]}
        </span>
      )}
    </div>
  </div>
);

const PriceSummary = () => {
  const propsContext = useContext(CheckoutModalPropsContext);

  if (propsContext?.type === "cart") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        {propsContext.cart.cart_items.map((item) => (
          <CartSummaryItem cartItem={item} key={item.uuid} />
        ))}
      </div>
    );
  }

  if (propsContext?.type === "invoices") {
    return (
      <div className="t-flex t-gap-1 t-flex-col">
        {propsContext.invoices.map((invoice) => (
          <div className="t-flex t-items-center t-text-body" key={invoice.uuid}>
            <p className="t-m-0 t-text-text-30 t-w-10/12">
              {invoice.short_name}
            </p>
            <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto t-text-right t-w-2/12">
              <AmountSuperScript amount={Number(invoice.amount)} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (propsContext?.type === "subscription") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center t-text-body">
          <p className="t-m-0 t-text-text-30">
            {propsContext.subscription.subscription_name}
          </p>
          <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
            <AmountSuperScript amount={propsContext.subscription.amount} />/
            {BILLING_CYCLE[propsContext.subscription.billing_cycle]}
          </div>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "task") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center t-text-body">
          <p className="t-m-0 t-text-text-30">{propsContext.task.title}</p>
          <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
            <AmountSuperScript
              amount={Number(propsContext.task.payment_amount)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "credits") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center t-text-body">
          <p className="t-m-0 t-text-text-30">Credits</p>
          <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
            <AmountSuperScript amount={propsContext.creditsToBeAdded} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const TotalSummary = () => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    { groupId: propsContext?.groupId! },
    { skip: !propsContext?.groupId }
  );

  const { data: brexPoints } = useGetBrexPointsQuery(
    {
      entityId: propsContext?.entityId!,
    },
    {
      skip: !propsContext?.entityId,
    }
  );

  const getBrexCreditFromUSD = (usd: number) => {
    if (brexPoints?.connection?.connection_available) {
      return usd * brexPoints.connection.rate;
    }

    return 0;
  };

  if (propsContext?.type === "cart") {
    const {
      useCredits,
      useBrexPoints,
      cartInvoice,
      cart: {
        order_summary: { total, discount },
      },
    } = propsContext;

    // Cart invoice won't be present if the cart only has subscriptions
    const usableCredits = cartInvoice
      ? getCreditUsageForCart(cartInvoice, total_credits || 0).usableCredits
      : 0;

    let usableBrexPoints = cartInvoice
      ? getCreditUsageForCart(
          cartInvoice,
          total_credits || 0,
          brexPoints?.connection?.available_usd
        )[useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"]
      : 0;

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body">
        {useCredits && (
          <div className="t-flex t-justify-between">
            <p className="t-m-0 t-text-text-30">
              {formatNumber(usableCredits)} Credits used
            </p>
            <p className="t-m-0">
              {<AmountSuperScript amount={-formatNumber(usableCredits)} />}
            </p>
          </div>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between">
              <p className="t-m-0 t-text-text-30">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - {<AmountSuperScript amount={usableBrexPoints} />}
              </p>
            </div>
          </div>
        )}

        {discount && (
          <div className="t-flex t-items-center">
            <p className="t-m-0 t-text-text-30">
              Coupon applied ({discount?.coupon?.coupon_code})
            </p>
            <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
              - <AmountSuperScript amount={discount.discount_value} />
            </div>
          </div>
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0">Total</p>
          <p className="t-m-0">
            <AmountSuperScript
              amount={
                total -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "task") {
    const {
      useCredits,
      useBrexPoints,
      task: { coupon: discount, payment_amount },
    } = propsContext;

    const finalPrice = discount?.discounted_price || payment_amount;

    const usablePoints = getCreditUsageForTask(
      propsContext.task,
      total_credits || 0,
      brexPoints?.connection?.available_usd
    );

    const { usableCredits } = usablePoints;

    let usableBrexPoints =
      usablePoints[
        useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"
      ];

    const discounted =
      Number(payment_amount) - Number(discount?.discounted_price || 0);

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body">
        {useCredits && (
          <div className="t-flex t-justify-between">
            <p className="t-m-0 t-text-text-30">
              {formatNumber(usableCredits)} Credits used
            </p>
            <p className="t-m-0">
              {<AmountSuperScript amount={-formatNumber(usableCredits)} />}
            </p>
          </div>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between">
              <p className="t-m-0 t-text-text-30">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - {<AmountSuperScript amount={usableBrexPoints} />}
              </p>
            </div>
          </div>
        )}

        {discount && (
          <div className="t-flex t-items-center">
            <p className="t-m-0 t-text-text-30">
              Coupon applied ({discount?.coupon_code})
            </p>
            <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
              - <AmountSuperScript amount={Number(discounted)} />
            </div>
          </div>
        )}

        {(useCredits || useBrexPoints || discount) && (
          <div className="t-border-solid t-border-b t-border-0 t-border-neutral-10" />
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0 t-text-subtext">Total</p>
          <p className="t-m-0 t-text-subtext">
            <AmountSuperScript
              amount={
                Number(finalPrice) -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "invoices") {
    const { useCredits, invoices, totalDue, useBrexPoints } = propsContext;

    const finalTotal =
      totalDue || invoices.reduce((acc, i) => acc + Number(i.amount), 0);

    const usablePoints = getCreditUsageForBulkInvoices(
      invoices,
      total_credits || 0,
      brexPoints?.connection?.available_usd,
      totalDue
    );

    const { usableCredits } = usablePoints;

    const usableBrexPoints =
      usablePoints[
        useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"
      ];

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body">
        {useCredits && (
          <>
            <div className="t-flex t-justify-between">
              <p className="t-m-0 t-text-text-30">Credits used</p>
              <p className="t-m-0">- {formatNumber(usableCredits)}</p>
            </div>
            <div className="t-border-solid t-border-b t-border-0 t-border-neutral-10" />
          </>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between">
              <p className="t-m-0 t-text-text-30">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - {<AmountSuperScript amount={usableBrexPoints} />}
              </p>
            </div>
          </div>
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0">Total</p>
          <p className="t-m-0">
            <AmountSuperScript
              amount={
                Number(finalTotal) -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "credits") {
    const { creditsToBeAdded } = propsContext;

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body">
        <div className="t-flex t-justify-between">
          <p className="t-m-0">Total</p>
          <p className="t-m-0">
            <AmountSuperScript amount={creditsToBeAdded} />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "subscription") {
    const discount = propsContext.subscription.stripe_coupon;
    const subtotal = propsContext.subscription.amount;

    const total = Boolean(
      propsContext.subscription.stripe_coupon?.discounted_amount?.toString()
    )
      ? propsContext.subscription.stripe_coupon?.discounted_amount
      : subtotal;
    const discountValue = subtotal - total!;

    return (
      <>
        {discount && discountValue > 0 && (
          <>
            <div className="t-flex t-items-center">
              <p className="t-m-0 t-text-dark_green">
                Coupon applied ({discount.coupon_code})
              </p>
              <div className="t-text-body t-font-normal t-text-text-100 t-ml-auto">
                - <AmountSuperScript amount={discountValue} />
              </div>
            </div>
            <div className="t-border-solid t-border-b t-border-0 t-border-neutral-10" />
          </>
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0">Total</p>
          <p className="t-m-0">
            <AmountSuperScript amount={total!} />/
            {BILLING_CYCLE[propsContext.subscription.billing_cycle]}
          </p>
        </div>
      </>
    );
  }

  return null;
};

const BillingAddress = () => {
  const serviceTeamId = useServiceTeamId();
  const propsContext = useContext(CheckoutModalPropsContext);
  const isPayerFCA = propsContext?.payerUserType === "FCA";
  const { groupId, entityId } = propsContext || {};

  const { billingAddresses, isLoading, billingTagId } = useBillingAddress({
    groupId,
    entityId,
  });

  const { data: autoFill = [], ...autofillState } = useGetPracticeAutofillQuery(
    {
      serviceTeamId,
      autofillKey: "addresses",
      tagId: billingTagId,
    },
    {
      skip: !serviceTeamId || !billingTagId,
    }
  );

  const loading = autofillState.isLoading || isLoading;

  if (loading || !billingTagId) {
    return (
      <div className="t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (isPayerFCA) {
    return <PracticePaymentBillingAddress defaultAddress={autoFill[0]} />;
  }

  if (entityId && !isPayerFCA) {
    return (
      <PaymentBillingAddress
        entityId={entityId}
        defaultAddress={billingAddresses[0]}
      />
    );
  }

  return null;
};

const PaymentSummary = () => {
  return (
    <div>
      <BillingAddress />
      <div className="t-flex t-flex-col t-gap-3">
        <div className="t-text-subtext t-font-semibold t-mt-6 t-text-neutral-80">
          Summary
        </div>
        <PriceSummary />

        <div className="t-border-solid t-border-b t-border-0 t-border-neutral-10" />

        <TotalSummary />
      </div>
    </div>
  );
};

const Footer = ({
  onSubscribed,
  isScheduledPayment,
}: {
  onSubscribed: () => void;
  isScheduledPayment?: boolean;
}) => {
  const { successToast, alertToast } = useToast();
  const propsContext = useContext(CheckoutModalPropsContext);
  const groupId = propsContext?.groupId;
  const { step, setStep } = propsContext || {};
  const { updateUser } = useContext(authContext);

  const { billingAddresses, billingTagId } = useBillingAddress({
    groupId,
    entityId: propsContext?.entityId,
  });

  const stripe = useStripe();
  const serviceTeamId = useServiceTeamId();

  const isPayerFCA = propsContext?.payerUserType === "FCA";

  const { data: savedCards, isLoading } = useSavedCards({
    serviceTeamId,
    groupId,
    entityId: propsContext?.entityId,
  });

  const [createSubscription, { isLoading: isLoadingCreateSubscription }] =
    useCreateSubscriptionMutation();

  let enableButton = false;

  if (!stripe) {
    return null;
  }

  if (
    propsContext?.type === "cart" ||
    propsContext?.type === "invoices" ||
    propsContext?.type === "credits" ||
    propsContext?.type === "task"
  ) {
    const { paymentMethodId } = propsContext;
    if (isPayerFCA) {
      enableButton = Boolean(paymentMethodId);
    } else {
      enableButton = Boolean(paymentMethodId) && Boolean(propsContext.entityId);
    }
  }

  if (propsContext?.type === "subscription") {
    const defaultCard = savedCards.find((c) => c.is_default_card);
    enableButton = Boolean(defaultCard) && Boolean(propsContext.entityId);
  }

  const onCreateSubscription = async () => {
    try {
      const payload = {
        subscription_uuid: (propsContext as CheckoutSubscription)?.subscription
          ?.uuid,
        scheduled_date: dayjs()
          .add(1, "year")
          .startOf("year")
          .format(YYYY_MM_DD),
      };
      await createSubscription({
        payload,
        groupId: groupId!,
        entityId: propsContext?.entityId!,
      }).unwrap();
      onSubscribed();
      const profile = await profileData();
      updateUser(profile);
      successToast({ message: "Payment scheduled successfully." });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  if (step === "DETAILS") {
    if (isScheduledPayment) {
      return (
        <Button
          customType="primary"
          block
          disabled={!enableButton || !billingAddresses?.[0]}
          onClick={onCreateSubscription}
          isLoading={isLoadingCreateSubscription}
        >
          Schedule Payment
        </Button>
      );
    }

    return (
      <Button
        customType="primary"
        block
        disabled={!enableButton}
        onClick={() => setStep?.("PAYMENT")}
        isLoading={isLoading}
      >
        Proceed
      </Button>
    );
  }

  return <PaymentButton />;
};

type CheckoutCartItem = Omit<
  ArrayElement<Cart["cart_items"]>,
  "subscription"
> & {
  subscription?: Subscription & {
    selectedTierAmount?: string;
    raStateName?: string;
    cartItemId?: string;
  };
};

export type CheckoutCart = Omit<Cart, "cart_items"> & {
  cart_items: CheckoutCartItem[];
};

type CheckoutCartModal = {
  type: "cart";
  cart: CheckoutCart;
  cartInvoice?: BillingInvoice | null;
  onCartPaid: () => void;
  lineItems?: {
    title: ReactNode;
    quantity: number;
    price: number;
    subscription?: Subscription;
  }[];
};

type CheckoutSubscription = {
  entityId?: string;
  type: "subscription";
  subscription: Subscription;
  onSubscribed: () => void;
};

type CheckoutInvoices = {
  type: "invoices";
  totalDue?: number;
  entityId?: string;
  invoices: BillingInvoice[];
  messageId?: string;
  onInvoicePaid: () => void;
};

type CheckoutTask = {
  type: "task";
  task: Task;
  messageId?: string;
  onInvoicePaid: () => void;
};

type CheckoutCredits = {
  type: "credits";
  entityId?: string;
  creditsToBeAdded?: number;
  onCreditsAdded: () => void;
};

export type CheckoutDataProps =
  | CheckoutCartModal
  | CheckoutSubscription
  | CheckoutInvoices
  | CheckoutCredits
  | CheckoutTask;

type CheckoutModalOptionProps = {
  title?: string;
  onClose: () => void;
  open: boolean;
  payerUserType?: "FCA" | "CUSTOMER";
  isScheduledPayment?: boolean;
};

type CheckoutModalProps = CheckoutDataProps & CheckoutModalOptionProps;

export const CheckoutModal = (props: CheckoutModalProps) => {
  const title = props?.title || "Make Payment";
  const { uuid } = useCurrentGroupContext();

  const [step, setStep] = useState<"DETAILS" | "PAYMENT">("DETAILS");
  const [creditsToBeAdded, setCreditsToBeAdded] = useState<number | null>(
    (props.type === "credits" ? props.creditsToBeAdded : null) || null
  );
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [useCredits, setUseCredits] = useState(false);
  const [useBrexPoints, setUseBrexPoints] = useState(false);

  let defaultEntityId: string | null | undefined;

  switch (props.type) {
    case "cart":
      defaultEntityId = props.cart.entity_id;
      break;

    case "task":
      defaultEntityId = props.task.entity.uuid;
      break;

    case "invoices":
    case "credits":
    case "subscription":
      defaultEntityId = props.entityId;
      break;

    default:
      defaultEntityId = null;
      break;
  }

  const isEntityProvided = Boolean(defaultEntityId);
  const [entityId, setEntityId] = useState(defaultEntityId);
  const serviceTeamId = useServiceTeamId();

  useEffect(() => setEntityId(defaultEntityId), [defaultEntityId]);

  const { activeChannelGroupId } = useAppSelector(
    (state) => state.reviewAndBalancePayment
  );

  const groupId = uuid || activeChannelGroupId;

  const { data: savedCards } = useSavedCards({
    serviceTeamId,
    groupId,
    entityId: entityId || undefined,
  });

  const defaultCard = savedCards.find((c) => c.is_default_card);

  useEffect(() => {
    setPaymentMethodId(defaultCard?.payment_method_id || null);
  }, [defaultCard?.payment_method_id]);

  let contextValue = null;

  const usingCredits = {
    useCredits,
    setUseCredits,
    useBrexPoints,
    setUseBrexPoints,
  };

  const canChangePaymentMethod = {
    paymentMethodId,
    setPaymentMethodId,
  };

  const stepProps = {
    step,
    setStep,
    groupId,
  };

  const canChangeEntity = {
    setEntityId,
    isEntityProvided,
  };

  if (props.type === "cart") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "task") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "subscription") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...stepProps,
      ...canChangeEntity,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "invoices") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
      ...canChangeEntity,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "credits") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      creditsToBeAdded: creditsToBeAdded || 0,
      setCreditsToBeAdded,
      ...canChangePaymentMethod,
      ...stepProps,
      ...canChangeEntity,
    } as CheckoutModalPropsContextType;
  }

  return (
    <CheckoutModalPropsContext.Provider value={{ ...contextValue! }}>
      <Elements stripe={stripePromise}>
        <Modal.Root open={props.open} onOpenChange={props.onClose}>
          <Modal.Content>
            <Modal.Header>
              <PaymentHeader
                title={title}
                setStep={setStep}
                step={step}
                onClose={() => {}}
                isScheduledPayment={props.isScheduledPayment}
              />
            </Modal.Header>
            <Modal.Body>
              {step === "DETAILS" && (
                <EditPaymentDetails
                  isScheduledPayment={props.isScheduledPayment}
                />
              )}
              {step === "PAYMENT" && <PaymentSummary />}
            </Modal.Body>

            <Modal.Footer>
              <div>
                <Footer
                  onSubscribed={(props as CheckoutSubscription)?.onSubscribed}
                  isScheduledPayment={props.isScheduledPayment}
                />
                <img
                  src={PoweredByStripe}
                  className={classNames("t-mx-auto t-mt-6 t-flex", {
                    "t-pb-4": true,
                  })}
                  alt="Powered by stripe"
                />
              </div>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Root>
      </Elements>
    </CheckoutModalPropsContext.Provider>
  );
};
