import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { journalEntrySchema } from "formValidations/JournalEntrySchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import { ReactNode, useMemo } from "react";
import { useAddLedgerEntryMutation } from "store/apis/generalLedger";
import { FileData } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { AddJournalEntryTable } from "./AddJournalEntryTable";
import { roundToFixedDecimal } from "utils/roundtoFixedDecimal";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { AccordionAnimation } from "components/AccordionAnimation";
import { AnimatePresence } from "framer-motion";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { NumericInput } from "components/NumericInput/NumericInput";

const frequencyOptions = [
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
  {
    label: "Quarterly",
    value: "QUARTERLY",
  },
  {
    label: "Annually",
    value: "YEARLY",
  },
];

const endsOnOptions = [
  {
    label: "On end date",
    value: "END_DATE",
  },
  {
    label: "After no. of entries",
    value: "NO_OF_ENTRIES",
  },
  {
    label: "Never ends",
    value: "NEVER_ENDS",
  },
];

export const RecurringJournalEntrySettings = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  const { values, setFieldValue, setValues } = useFormikContext<{
    end_date: string;
    max_recurring_count: number | null;
    transaction_date: string;
    frequency: string;
    ends_on: string;
  }>();

  let endsOnValue = values.ends_on || null;

  if (values.end_date) {
    endsOnValue ??= "END_DATE";
  }

  if (values.max_recurring_count && values.max_recurring_count >= 0) {
    endsOnValue ??= "NO_OF_ENTRIES";
  }

  if (!values.end_date && !values.max_recurring_count) {
    endsOnValue ??= "NEVER_ENDS";
  }

  return (
    <>
      <Field name="start_on">
        {({ field }: FieldProps) => {
          return (
            <DateInput
              label="Starts on"
              {...field}
              value={values.transaction_date}
              portalId={field.name}
              required
              maxDate={new Date()}
              placeholder="DD-MMM-YYYY"
              disabled
              block
            />
          );
        }}
      </Field>

      <Combobox
        required
        menuPortalTarget={document.body}
        label="Frequency"
        withForm
        block
        options={frequencyOptions}
        value={frequencyOptions.find((v) => v.value === values.frequency)}
        name="frequency"
        isDisabled={disabled}
      />

      <div className="t-w-full">
        <Combobox
          required
          menuPortalTarget={document.body}
          label="Ends"
          block
          isClearable={false}
          onChange={(option) => {
            if (option instanceof Array) {
              return;
            }

            if (option?.value === "NEVER_ENDS") {
              return setValues({
                ...values,
                end_date: "",
                max_recurring_count: null,
                ends_on: option.value,
              });
            }

            if (option?.value === "END_DATE") {
              return setValues({
                ...values,
                max_recurring_count: null,
                ends_on: option.value,
              });
            }

            if (option?.value === "NO_OF_ENTRIES") {
              return setValues({
                ...values,
                end_date: "",
                ends_on: option.value,
              });
            }
          }}
          value={endsOnOptions.find((v) => v.value === endsOnValue)}
          options={endsOnOptions}
          name="ends_on"
          isDisabled={disabled}
        />
      </div>

      {endsOnValue === "END_DATE" && (
        <Field name="end_date">
          {({ field }: FieldProps) => {
            return (
              <DateInput
                required
                label="End date"
                {...field}
                portalId={field.name}
                showErrorOnceTouched={false}
                minDate={new Date()}
                placeholder="DD-MMM-YYYY"
                block
                disabled={disabled}
              />
            );
          }}
        </Field>
      )}

      {endsOnValue === "NO_OF_ENTRIES" && (
        <NumericInput
          required
          numericProps={{
            disabled: disabled,
          }}
          label="Number of entries"
          fieldProps={{
            name: "max_recurring_count",
          }}
        />
      )}
    </>
  );
};

export type Transaction = {
  id: string;
  merchant: string;
  category: string;
  description: string;
  credit: number;
  debit: number;
  invoice: Omit<FileData, "invoice_status" | "invoice_uuid"> | null;
  uuid: string;
};

export const initialTransaction: Omit<Transaction, "id"> = {
  merchant: "",
  category: "",
  description: "",
  credit: 0,
  debit: 0,
  invoice: null,
  uuid: "",
};

export const JournalEntryFormWarrper = ({
  children,
}: {
  children: ({
    isValidTransactions,
    isAmountMatch,
  }: {
    isValidTransactions: boolean;
    isAmountMatch: boolean;
  }) => ReactNode;
}) => {
  const {
    values: { transactions },
    isValid,
    errors,
  } = useFormikContext<{
    transactions: Transaction[];
  }>();

  const noEntryData = transactions.some(
    ({ description, category }) => !Boolean(category) || !Boolean(description)
  );

  const totalDebit = transactions.reduce(
    (acc, { debit }) => acc + Number(debit),
    0
  );

  const totalCredit = transactions.reduce(
    (acc, { credit }) => acc + Number(credit),
    0
  );

  const isAmountMatch =
    roundToFixedDecimal({ numberToRound: totalDebit }) ===
    roundToFixedDecimal({ numberToRound: totalCredit });

  const isValidTransactions =
    transactions.length !== 0 && isValid && !noEntryData && isAmountMatch;

  return <>{children({ isValidTransactions, isAmountMatch })}</>;
};

export const AddJournalEntryModal = ({ close, isOpen }: ModalProps) => {
  const { alertToast, successToast } = useToast();
  const [addLedgerEntry, { isLoading: addingLedgerEntry }] =
    useAddLedgerEntryMutation();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const initialTransactions = useMemo(
    () =>
      Array.from({ length: 2 }).map((arr, i) => ({
        id: randomBytes(10).toString("hex"),
        ...initialTransaction,
      })),
    []
  );

  const initialValues = {
    transactions: initialTransactions,
    transaction_date: dayjs().format(DD_MMM_YYYY),
    ledger_entry_name: "",
    is_recurring_enabled: false,
    frequency: "MONTHLY",
    end_date: "",
    max_recurring_count: 0,
    ends_on: "NEVER_ENDS",
  };

  const onSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    const { transactions, transaction_date, ledger_entry_name } = values;
    const transactionList = transactions.map(
      ({ credit, debit, category, description, invoice, merchant }) => ({
        amount: Math.abs(Number(credit)) || Math.abs(Number(debit)) * -1,
        description,
        invoice_id: invoice?.uuid || null,
        merchant_data_id: merchant,
        transaction_category_id: category,
      })
    );

    try {
      await addLedgerEntry({
        entityId,
        groupId,
        transactions: transactionList,
        transaction_date: dayjs(transaction_date).format(YYYY_MM_DD),
        ledger_entry_name,
        is_recurring_enabled: values.is_recurring_enabled,
        max_recurring_count: values.end_date
          ? undefined
          : Number(values.max_recurring_count),
        frequency: values.frequency,
        end_date:
          values.end_date && !values.max_recurring_count
            ? dayjs(values.end_date).format(YYYY_MM_DD)
            : undefined,
      }).unwrap();

      successToast({ message: "Journal Entry has been populated!" });
      resetForm();
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange
        validationSchema={journalEntrySchema}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form className="t-m-0 t-w-full">
            <JournalEntryFormWarrper>
              {({ isValidTransactions, isAmountMatch }) => (
                <Modal.Content size="xxxl" useCustomOverlay>
                  <Modal.Header>
                    <div className="t-w-full">
                      <Modal.Title>Add Journal Entry</Modal.Title>
                      <Modal.Subtitle>
                        Please make sure the total debit amount matches the
                        total credit amount
                      </Modal.Subtitle>
                    </div>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-pb-0 t-gap-4  t-flex t-flex-col">
                    <div>
                      <div className="t-flex t-gap-6 t-justify-center t-items-center">
                        <div className="t-flex t-gap-6">
                          <Field name="transaction_date">
                            {({ field }: FieldProps) => {
                              return (
                                <DateInput
                                  label="Date"
                                  {...field}
                                  name="transaction_date"
                                  portalId="journal_entry_date"
                                  required
                                  block
                                  maxDate={new Date()}
                                  placeholder="DD-MMM-YYYY"
                                />
                              );
                            }}
                          </Field>
                          <TextInput
                            name="ledger_entry_name"
                            label="Journal entry title"
                            required
                            placeholder="#1"
                          />
                        </div>
                        <div className="t-ml-auto">
                          <SwitchField
                            name="is_recurring_enabled"
                            label="Recurring entry"
                          />
                        </div>
                      </div>

                      <AnimatePresence>
                        {values.is_recurring_enabled && (
                          <AccordionAnimation>
                            <div className="t-flex t-gap-6 t-pt-4">
                              <RecurringJournalEntrySettings />
                            </div>
                          </AccordionAnimation>
                        )}
                      </AnimatePresence>
                      <span className=" t-flex t-justify-end t-text-subtext t-text-neutral-50">
                        {values.transactions.length} entries added
                      </span>
                      <AddJournalEntryTable />
                    </div>
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button onClick={() => close()} type="reset">
                      Cancel
                    </Button>

                    <ConditionalToolTip
                      condition={
                        !isAmountMatch && (
                          <span>
                            Total debits and credits
                            <br /> should match
                          </span>
                        )
                      }
                    >
                      <span>
                        <Button
                          customType="primary"
                          type="submit"
                          onClick={submitForm}
                          disabled={!isValidTransactions || addingLedgerEntry}
                          isLoading={addingLedgerEntry}
                        >
                          Add
                        </Button>
                      </span>
                    </ConditionalToolTip>
                  </Modal.FooterButtonGroup>
                </Modal.Content>
              )}
            </JournalEntryFormWarrper>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
